import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { TopBar } from "components";
import style from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Spin, Button, Select, notification } from "antd";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import Checkbox from "antd/lib/checkbox/Checkbox";
import * as VirtualWaitingRoomService from "../../services/virtualWaitingRoom";
import { UPDATEDGET, UPDATEDPOST } from "services/common.api";
import uuid from "react-uuid";
import SSEHandler from "../../lib/SSEHandler";
import WebrtcCallHandler from "../../lib/WebrtcCallHandler";
import Lottie from "react-lottie";
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import { selectedService } from "redux/actions/userActions";
import moment, { utc } from "moment";
import store from 'store'
import {
  setStartTimeBasedOnCallBackRequest,
  setStartTimeBasedOnCallBackRequestWithDate,
} from "../../utils/common";
import PageHeader from "components/pageHeader";
import { fas } from "@fortawesome/free-solid-svg-icons";

const config = {
  BASEURL: process.env.REACT_APP_API_URL,
  paymentUrl: process.env.REACT_APP_PAYMENT_API,
  applicationid: process.env.REACT_APP_SQUARE_APPLICATION_ID,
  locationid: process.env.REACT_APP_SQUARE_LOCATION_ID
}

let callPayload: any;

const SelectVirtualRoom = () => {
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({} as any);
  const [paymentClick, setPaymentClick] = useState(false);
  const [isLoad, setLoad] = useState(false);

  const currentAppointmentInfo = useSelector(
    (state: any) => state.curentAppointment
  );

  const App_Type = useSelector(
    (state: any) => state.curentAppointment.appointmentRequestType
  );
  const [comment, setComment] = useState("");
  const [term, setTerm] = useState(false);
  const [userAgreementModal, setUserAgreementModal] = useState(false);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const history = useHistory();
  const { Option } = Select;
  const [loader, setLoader] = useState(false);
  const [familyMemberDetails, setFamilyMemberDetails] = useState({} as any);
  const [showVirtualWaitingRoom, setShowVirtualWaitingRoom] = useState(false);
  const [invocationLoading, setInvocationLoading] = useState(false);
  const [invocation, setInvocation] = useState<any>({});
  const [allServices, setAllServices] = useState([]);
  const [selectedServiceValue, setSelectedServiceValue] = useState(null);
  const [selectService, setSelectedService] = useState(null);
  const [paymentModal, setPaymentModal] = useState(false);

  console.log('Payment__-',paymentModal);
  

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require("./waiting.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const user = useSelector((state: any) => state.userReducer.user);
  console.log("USERR",user);
  
  useEffect(() => {
    setLoad(true);
    getPatientDetails();
    getServices();
    console.log(currentAppointmentInfo, "******currentAppointmentInfo");

    if (App_Type !== "familyMember") {
      setFormValues((formValues) => ({
        ...formValues,
        firstname: user.FirstName,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        lastname: user.LastName,
      }));
      setFormValues((formValues) => ({ ...formValues, email: user.Email }));
      setFormValues((formValues) => ({
        ...formValues,
        phone: user.phoneNumber,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        paymentType: currentAppointmentInfo.paymentProvider,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        insurance_number: user.Insurancesvcnum,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        insurance_provider: user.insuranceProvider,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        insurance_version_code: user.insuranceVersionCode,
      }));
    } else {
      var s =
        // currentAppointmentInfo?.familyMember?.userName.split(/(?<=^\S+)\s/);
        currentAppointmentInfo?.familyMember?.userName.split(/[ ,]+/);
      console.log(s);
      if (s) {
        setFormValues((formValues) => ({ ...formValues, firstname: s[0] }));
        setFormValues((formValues) => ({ ...formValues, lastname: s[1] }));
      }

      setFormValues((formValues) => ({
        ...formValues,
        email: familyMemberDetails?.email,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        phone: familyMemberDetails?.phoneNumber,
      }));
    }
  }, []);

  useEffect(() => {
    

    if (App_Type !== "familyMember") {
      setFormValues((formValues) => ({
        ...formValues,
        firstname: user.FirstName,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        lastname: user.LastName,
      }));
      setFormValues((formValues) => ({ ...formValues, email: user.Email }));
      setFormValues((formValues) => ({
        ...formValues,
        phone: user.phoneNumber,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        paymentType: currentAppointmentInfo.paymentProvider,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        insurance_number: user.Insurancesvcnum,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        insurance_provider: user.insuranceProvider,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        insurance_version_code: user.insuranceVersionCode,
      }));
    } else {
      var s =
        // currentAppointmentInfo?.familyMember?.userName.split(/(?<=^\S+)\s/);
        currentAppointmentInfo?.familyMember?.userName.split(/[ ,]+/);
      console.log(s);
      setFormValues((formValues) => ({ ...formValues, firstname: s[0] }));
      setFormValues((formValues) => ({ ...formValues, lastname: s[1] }));
      setFormValues((formValues) => ({
        ...formValues,
        email: familyMemberDetails?.email,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        phone: familyMemberDetails?.phoneNumber,
      }));
    }
  }, [familyMemberDetails]);


  const squarePayment = isLoad ? (
    // <PaymentForm 
    //   history={history}
    //   paymentForm={window.SqPaymentForm} 
    //   where="fromCallback"
    //   amount={amount} 
    //   user={user}
    //   appoimentDate={currentAppointmentInfo.selectedService?.selectedDate}
    //   time={selectedTime}
    //   selectslot={currentAppointmentInfo.selectedService?.selectslot}
    //   selectedService={currentAppointmentInfo.selectedService}
    // />

    <PaymentForm
      /**
       * Identifies the calling form with a verified application ID generated from
       * the Square Application Dashboard.
       */
      // applicationId="sandbox-sq0idb-xsjhqKMUXbE4V1kC7STkFw"
      // locationId="L4ZD3QVYFXK93"
      applicationId={config?.applicationid}
      locationId={config?.locationid}

      /**
        * Invoked when payment form receives the result of a tokenize generation
        * request. The result will be a valid credit card or wallet token, or an error.
        */
      cardTokenizeResponseReceived={(token, buyer) => {
        console.log("ahiii token", token?.token);
        const paymentUrl = config?.paymentUrl;

        if (token?.token) {
          setPaymentClick(true);
          fetch(paymentUrl, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              nonce: token?.token,
              amount: currentAppointmentInfo?.selectedService?.serviceCost * 100
            })
          })
            .catch(err => {
              setPaymentClick(false);
              alert('Network error: ' + err);
            })
            .then((response: any) => {
              if (!response.ok) {
                return response.text().then(errorInfo => Promise.reject(errorInfo));
              }
              return response.text();
            })
            .then((data: any) => {
              console.log(JSON.parse(data), "result of payment");
              setPaymentModal(false);
              startVirtualWaitingRoom(JSON.parse(data)?.result?.payment)
              // requestCallbackAppointment(JSON.parse(data)?.result?.payment?.id);
            })
            .catch(err => {
              console.error(err);
              setPaymentClick(false);
              notification.error({
                message: "'Payment failed to complete!",
              });
            });
        }
      }}
      /**
       * This function enable the Strong Customer Authentication (SCA) flow
       *
       * We strongly recommend use this function to verify the buyer and reduce
       * the chance of fraudulent transactions.
       */
      createVerificationDetails={() => ({
        /* omit amount key if you want to store the card details */
        amount: currentAppointmentInfo?.selectedService?.serviceCost + '',
        /* collected from the buyer */
        billingContact: {
          addressLines: [user?.Address1],
          familyName: user?.FirstName + " " + user?.LastName,
          givenName: user?.FirstName + " " + user?.LastName,
          countryCode: 'CA',
          city: user?.City,
        },
        currencyCode: 'CAD',
        intent: 'CHARGE',
      })}
    >

      <div className="form-group">
        <label htmlFor="nameOnCard">Name on card</label>
        <input type="text" className="form-control" style={{ fontWeight: 400, fontSize: 16 }} id="nameOnCard" name="nameOnCard" />
      </div>

      <CreditCard
        postalCode="false"
        callbacks={{
          submit(event) {
            console.info("submit: ", event);
            handlePaymentSubmit();
          }
        }}
        focus="cardNumber"
        buttonProps={{
          isLoading: paymentClick,
        }}
        onClick={() => {
          console.log("payment button clicked");
          handlePaymentSubmit();
        }}
      >
        Submit Payment
      </CreditCard>
    </PaymentForm>

  ) : null;


  const getServices = async () => {
    try {
      await UPDATEDGET(`privateservices`).then((response) => {
        console.log("All Services", response.data)

        if (response?.data?.errorMessage) {
          setAllServices([]);
        } else {
          setAllServices(response?.data);
        }
      });
    } catch (error) {
      setAllServices([]);
      console.log(error, "error")
    }
  }

  const getPatientDetails = async () => {
    if (currentAppointmentInfo?.familyMember?.familymemberid) {
      try {
        const data = await UPDATEDGET(
          `user/profile/${currentAppointmentInfo?.familyMember?.familymemberid}`
        );
        console.log(data.data[0][0], "Family data");
        setFamilyMemberDetails(data.data[0][0]);
      } catch (error) {
        console.log(error);
      }
    }
  };

  function handleChangeService(value) {
    
    console.log(value,)

    if(currentAppointmentInfo.paymentProvider == 'OHIP'){

    }else{
      setSelectedServiceValue(value);

      const data = allServices?.filter((item) => item.id == value);
      console.log(data[0]);
  
      setSelectedService(data[0]);
  
      const selectedData = {
        serviceId : data[0].id,
        reason: data[0].description,
        selectedDate : null,
        serviceCost: data[0].fees,
        serviceduration: 10, 
        selectedTime: null,
        selectslot:null,
        selectedServiceName : data[0].service,
      }
      dispatch(selectedService(selectedData));
    }

    

  }


  const handleCancelVirtualRoom = () => {
    console.log("handleCancelVirtualRoom cancle");
    
    setShowVirtualWaitingRoom(false);
  };

  const startVirtualWaitingRoom = (paymentId) => {
    console.log("Loggeded !!!!!!");
    const roomId = uuid();
    console.log("roomId: ", roomId);

    console.log(currentAppointmentInfo, "currentAppointmentInfo")

    let currentUserDetailsNew = user;
    currentUserDetailsNew.CompanyEmployee = [];

    console.log(currentUserDetailsNew, "currentUserDetailsNew")
    const walkinInRequest = {
      patient_id: user?.sub,
      booked_by: user.id,
      //   detail: "Virtual waiting room",
      detail: comment,
      patientDetails: {
        ...currentUserDetailsNew,
      patient_ip: localStorage.getItem('ipAddress')

      },
      appointment_id: roomId,
      payment_type: currentAppointmentInfo?.paymentProvider,
      call_type: "video",
      room_id: roomId,
      call_id: uuid(),
      created_at: new Date().getTime(),
      selected_service: currentAppointmentInfo.paymentProvider != 'OHIP' ? selectService.id : null,
      payment_id : currentAppointmentInfo.paymentProvider != 'OHIP' ? paymentId?.id : null,
      recept_number : currentAppointmentInfo.paymentProvider != 'OHIP' ? paymentId?.receipt_number : null,
      patient_ip: localStorage.getItem('ipAddress')
    };
    if (App_Type == "familyMember") {
      walkinInRequest.patientDetails = {
        ...currentUserDetailsNew,
        patient_ip: localStorage.getItem('ipAddress'),
        FirstName: currentAppointmentInfo?.familyMember?.userName,
        LastName: "",
        id: currentAppointmentInfo?.familyMember?.familymemberid,
        name: currentAppointmentInfo?.familyMember?.userName,
      };
    }

    WebrtcCallHandler.getInstance().addExtraListenerWithForcefullyAdded(
      "onUserJoined",
      function (participant) {
        callPayload = {
          type: "video",
          isSFURequired: false,
          callInfo: {
            isSFURequired: false,
            toUsers: [
              {
                userId: participant?.userData?.sub,
                name: participant?.userData?.name,
                avatar: "",
                userType: "doctor",
              },
            ],
          },
          fromUser: {
            userId: user?.sub,
            name:
              App_Type == "familyMember"
                ? currentAppointmentInfo?.familyMember?.userName
                : user?.name,
            avatar: "",
            userType: "paitent",
          },
        };

        SSEHandler.getInstance().eventEmitter.emit("ShowCallUI", callPayload);
        // dispatch({
        //   type: "SHOW_VIRTUAL_WAITING_ROOM",
        //   payload: false,
        // });
        setShowVirtualWaitingRoom(false);
      },
      false
    );

    WebrtcCallHandler.getInstance().addExtraListenerWithForcefullyAdded(
      "onConnected",
      function () {
        WebrtcCallHandler.getInstance().getMeetingHandler().startMeeting();

        VirtualWaitingRoomService.startVirtualWaitingRoom(walkinInRequest)
          .then((result) => {
            console.log("result: ", result);
            if (result) {
              let appointment = result;
              setInvocationLoading(false);
              setInvocation(appointment);
            }
          })
          .catch((error) => {
            setInvocationLoading(false);
            // dispatch({
            //   type: "SHOW_VIRTUAL_WAITING_ROOM",
            //   payload: false,
            // });
            setShowVirtualWaitingRoom(false);
          });
      },
      false
    );

    SSEHandler.getInstance().onNewCallRequest({
      roomId: roomId,
      isSFURequired: false,
      type: "video",
      paymenttype: currentAppointmentInfo?.paymentProvider
    });

    // dispatch({
    //   type: "SHOW_VIRTUAL_WAITING_ROOM",
    //   payload: true,
    // });
    setShowVirtualWaitingRoom(true);

    setInvocationLoading(true);
  };

  const handleCancel = () => {
    console.log("handleCancel called");
    
    WebrtcCallHandler.getInstance().cleanUp();
    //setIsOpenModal(false);
    if (invocation) {
      let requestObject = {
        invocationId: invocation.appointmentId,
        eventId: invocation.appointmentId,
        callId: invocation.callId,
      };
      setInvocationLoading(true);
      VirtualWaitingRoomService.cancelVirtualWaitingRoom(requestObject)
        .then((result) => {
          setInvocationLoading(false);
          // dispatch({
          //   type: "SHOW_VIRTUAL_WAITING_ROOM",
          //   payload: false,
          // });
          setShowVirtualWaitingRoom(false);
        })
        .catch((error) => {
          console.log("error: ", error);
          setInvocationLoading(false);
        });
    }
  };

  const handlePaymentSubmit = async () => {
    console.log("THIS SUBMIT CLICK");   
    setPaymentModal(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if(currentAppointmentInfo.paymentProvider == 'OHIP'){
        startVirtualWaitingRoom('test')
      }else{
        if (selectService == null) {
          notification.warning({
            message: "Please select service first.",
          });
        } else {
          // startVirtualWaitingRoom()
          setPaymentModal(true);
        }
        console.log(selectService, "selectedService ")
      } 
    } catch (error) {
      console.log(error);
      
      setPaymentModal(false);
    }

    const consentData = {
      added_by: user?.id,
      consent_page: "Select Virtual",
      consent_name: "Terms & Conditions",
      timestemp: moment().format('YYYY-MM-DD HH:mm:ss')
    };

    const Consent = await UPDATEDPOST("consent", consentData);

    console.log(Consent, "Consent");
      
   
  };

  const handleChange = (event) => {
    console.log(event);
    const name = event.target.name;
    const value = event.target.value;
    console.log(name, value);
    setFormValues((formValues) => ({ ...formValues, [name]: value }));
  };

  const handleChangeComment = (event) => {
    setComment(event.target.value);
  };

  const termsAndConditions = (event: any) => {
    console.log(event.target.checked);
    setTerm(event.target.checked);
  };

  const goToBack = () => {
    history.goBack();
  };

  const handleOk = async () => {
    console.log('handleOk called');
    setPaymentModal(false);
    console.log('Payment modal should close');
  };
  return (
    <div style={{ height: "100%" }}>
      <TopBar isBookAppointment={true} />
      {/* <div className={style.box}>
        <p className={style.pageInfo}>VirtualRoom</p>
        <h5 className={style.h5New}>Dashboard / Virtual Room</h5>
      </div> */}

      <div className="content contentSelect" style={{ height: 600 }}>
        <div className="container-fluid">
        <PageHeader
            items={[
              { label: "Dashboard", link: "/dashboard" },
              { label: "Virtual Room", link: "" },
            ]}
            pageTitle="Virtual Room"
          />
          <div className="row mt-5">
            <div className={`col-md-2 col-lg-2 ${style.div_form}`}></div>
            <div className={`col-md-8 col-lg-8 ${style.div_form}`}>
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    {/* <div className="info-widget pb-4"> */}
                    <h4 className="card-title">Personal Information</h4>
                    <div className="row mb-0">
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group card-label">
                          <label>First Name</label>
                          <input
                            disabled={true}
                            type="text"
                            className="form-control"
                            name="firstname"
                            value={formValues.firstname || ""}
                            onChange={handleChange}
                            style={{
                              cursor: "not-allowed",
                              backgroundColor: "#FAFAFA",
                              color: "lightgray",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group card-label">
                          <label>Last Name</label>
                          <input
                            disabled={true}
                            type="text"
                            className="form-control"
                            name="lastname"
                            value={formValues.lastname || ""}
                            onChange={handleChange}
                            style={{
                              cursor: "not-allowed",
                              backgroundColor: "#FAFAFA",
                              color: "lightgray",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group card-label">
                          <label>Email</label>
                          <input
                            disabled={
                              App_Type != "familyMember" ? true : false
                            }
                            className="form-control"
                            type="email"
                            name="email"
                            value={formValues.email || ""}
                            onChange={handleChange}
                            style={{
                              cursor:
                                App_Type == "familyMember"
                                  ? "default"
                                  : "not-allowed",
                              backgroundColor:
                                App_Type == "familyMember"
                                  ? "#ffffff"
                                  : "#FAFAFA",
                              color:
                                App_Type == "familyMember"
                                  ? "#000000"
                                  : "lightgray",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div
                          className="form-group card-label"
                          style={{ marginBottom: "5px" }}
                        >
                          <label>Phone</label>
                          <input
                            className="form-control"
                            type="text"
                            name="phone"
                            value={formValues.phone || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <h4 className="card-title">Problem Description</h4>

                    <div className="form-group card-label">
                      <textarea
                        onChange={handleChangeComment}
                        className="form-control"
                      />
                    </div>

                    {currentAppointmentInfo?.paymentProvider != 'OHIP' ? (
                      <> 
                    <h4 className="card-title">Select Service *</h4>
                    <Select
                      showSearch
                      placeholder="Select Service"
                      optionFilterProp="children"
                      onChange={handleChangeService}
                      value={selectedServiceValue}
                      style={{
                        fontSize: 15,
                        width: '100%',
                        borderWidth: 1,
                        borderColor: "rgba(0,0,0,0.)",
                        borderRadius: 5,
                        marginRight: 15,
                        marginBottom: 25,
                        whiteSpace: "pre-wrap"
                      }}
                    >

                      {allServices?.map((itemm, index) => (
                        <>
                          {itemm.service != null ? (
                            <Option key={index} value={itemm.id}>
                              <span style={{ whiteSpace: "pre-wrap" }}>
                                {itemm.service} - ${itemm?.fees}
                              </span>
                            </Option>
                          ) : null}
                        </>
                      ))}
                    </Select>
                    </>
                    ) : null}

                    <div className={`${style.divLine}`} />
                    {/* <h4 className="card-title">Payment Method</h4> */}
                    <div className="payment-widget info-widget p-0 m-0">
                      <div className="terms-accept">
                        <div>
                          <Checkbox
                            onChange={termsAndConditions}
                            style={{ marginTop: 4 }}
                          >
                            I have read and accept{" "}
                          </Checkbox>
                          <span>
                            <a
                              target="_blank"
                              href="https://onhealthalliance.com/terms-of-use/"
                              // onClick={() => setUserAgreementModal(true)}
                              className="pt-1"
                            >
                              Terms &amp; Conditions
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="submit-section mt-4 row"
                      style={{ marginTop: 20, marginLeft: 6 }}
                    >
                      <div className={style.btnContainer}>
                        <div className={style.btnBox1}>
                          {loader ? (
                            <Spin />
                          ) : (
                            <button
                              type="submit"
                              className={`btn btn-primary ${style.submitBtn}`}
                              disabled={!term}
                            >
                              Schedule
                            </button>
                          )}
                        </div>
                        <div className={style.btnBox2}>
                          <button
                            type="button"
                            className={`btn btn-danger ${style.btnBg2} mx-3`}
                            onClick={() => goToBack()}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className={`col-md-2 col-lg-2 ${style.div_form}`}></div>
          </div>

          <Modal
            title="Payment"
            centered
            visible={paymentModal}
            onOk={handleOk}
            onCancel={() => setPaymentModal(false)}
            footer={null}
          >
            {squarePayment}
          </Modal>

          <Modal
            centered
            visible={userAgreementModal}
            width={1000}
            onOk={() => setUserAgreementModal(false)}
            onCancel={() => setUserAgreementModal(false)}
            footer={null}
          >
            <div className="pt-4">
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                <Viewer
                  fileUrl="https://cf2c9230-6450-4364-9252-7d656ea6986d.filesusr.com/ugd/911847_05eee2ae00b84bd9a94262e36b211932.pdf"
                  plugins={[defaultLayoutPluginInstance]}
                />
              </Worker>
            </div>
          </Modal>

          <Modal
            visible={showVirtualWaitingRoom}
            title="Virtual Waiting Room"
            closable={true}
            className={`${style.newClass} newAllModalCss`}
            width={"100vw"}
            footer={null}
            maskClosable={false}
            onCancel={handleCancelVirtualRoom}
          >
            <div className="row">
              <div className={`${style.col_flex} col-md-12`}>
                <div className={style.div_flex_waiting}>
                  <div>
                    <label style={{ marginTop: 35 }}>
                      Please wait untill docotor will takeup the call...
                    </label>
                  </div>

                  <div style={{ width: 100 }}>
                    <Lottie
                      options={defaultOptions}
                      height={100}
                      width={100}
                      speed={1}
                      loop
                      controls
                      autoplay
                      isClickToPauseDisabled={true}
                    />
                  </div>
                </div>

                <div className="text-center" style={{ width: "65%" }}>
                  <img
                    style={{ width: "100%" }}
                    alt="Virtual Waiting Room"
                    src="../Images/virtualWaitingRoom.png"
                  />
                </div>
                <div className="text-center mt-2">
                  <Button
                    type="primary"
                    size="large"
                    danger={true}
                    onClick={handleCancel}
                    loading={invocationLoading}
                    disabled={invocationLoading}
                    className={style.btnCancel}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </Modal>

        </div>
      </div>
    </div>
  );
};

export default SelectVirtualRoom;