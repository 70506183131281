import style from "./style.module.css";
import { Link, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadCurrentAccount,
  authentication,
  setCognitoUserData,
  setUserToken,
  setUser,
  selectPaymentProvider,
  selectAppointmentType,
  bookAppointmentDoctor,
  selectedService,
  loadWhichVersion,
  callbackAppointment,
  setProblem,
  setMeetingEndData,
  setCallID,
  setEndModal,
} from "../../redux/actions/userActions";
import Mfa from "../mfa/index";
import { Modal, Spinner } from "react-bootstrap";
import { Form, Input, Button, Checkbox, notification, Spin } from "antd";
import * as AuthService from "../../services/auth";
import store from "store";
// import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import WebrtcCallHandler from "lib/WebrtcCallHandler";
import SSEHandler from "lib/SSEHandler";
import ReCAPTCHA from "react-google-recaptcha";
import {
  UPDATEDGET,
  UPDATEDPOST,
  UPDATED_OPEN_GET,
  UPDATED_OPEN_POST,
} from "../../services/common.api";
import moment from "moment";

const currentConfig = Auth.configure();

const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [resendlnk, setresendlnk] = useState(false);
  const [email, setEmail] = useState("");
  const [form] = Form.useForm();
  const [noteModal, setNoteModal] = useState(false);
  const [isCaptchaValid, setCaptchaValid] = useState(false);
  const [securityPinModal, setSecuritypinModal] = useState(false);
  const [inputType, setInputType] = useState("");
  const [loginUser, setData] = useState({} as any);
  const [userAuthData, setUserAuthData] = useState({} as any);
  const user = useSelector((state: any) => state.userReducer);
  const userAgent = navigator.userAgent;

  
  useEffect(() => {
    logout();
    // loadCaptchaEnginge(6, 'white', 'black', 'upper');
    console.log(user, "YTYTYTYTYTYTYYTYTY");
    fetchIPAddress()
  }, []);

  

  const logout = () => {
    store.set("userauth", false);
    AuthService.logout();
    dispatch(authentication(false));
    WebrtcCallHandler.getInstance().cleanUp();
    SSEHandler.getInstance().cleanUp();
    dispatch(setUser([]));
    store.set("userauth", false);
    store.remove("userid");
    store.remove("name");
    store.remove("email");
    store.remove("user");
    store.clearAll();
  };

  // const signIn = async (values: any) => {
  //   if (validateCaptcha((values.captcha).toUpperCase()) == true) {
  //     setLoader(true);
  //     try {
  //       setEmail(values.email);

  //       let data = {
  //         email : values.email,
  //         password : values.password
  //       }
  //       const user = await POST("user/login", data);
  //       console.log(user, "pppppp------0 ");

  //       // const user = await Auth.signIn(values.email, values.password)
  //       // console.log(user, "ppppp")

  //       // AuthService.GetAuthenticationToken().then((res) => {
  //       //   console.log(res, "token res")
  //       //   store.set('auth_token_refresh', res?.idToken?.jwtToken);
  //       //   store.set('auth_token_exp', res?.idToken?.payload?.exp);
  //       //   store.set("email",values.email)

  //       // })
  //       // if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
  //       //   dispatch(setCognitoUserData(user));
  //       //   history.push("/complete-new-password");
  //       // } else {
  //       //   if (user.username) {
  //       //     console.log( "RRRRR")
  //       //     dispatch(loadCurrentAccount());
  //       //     dispatch(authentication(true));
  //       //     setLoader(false);
  //       //     store.set('userauth',true);
  //       //     history.push("/dashboard");
  //       //     setNoteModal(true);
  //       //   }
  //       // }
  //     } catch (error) {
  //       console.log("error: ", error);
  //       store.set('userauth',false);
  //       setLoader(false);
  //       if (error == 'UserNotConfirmedException: User is not confirmed.') {
  //         setresendlnk(true)
  //         notification.error({
  //           message: "Error",
  //           duration: 3,
  //           description: "Account was not verified, Please check your email and verify the link"
  //         })
  //       } else {
  //         notification.error({
  //           message: "Error",
  //           duration: 2,
  //           description: "Invalid Username or Password",
  //           onClick: () => {
  //             console.log("Notification Clicked!");
  //           },
  //         });
  //       }
  //     }
  //   }
  //   else {
  //     notification.error({
  //       message: "Error",
  //       duration: 2,
  //       description: "Captcha Does Not Match",
  //       onClick: () => {
  //         console.log("Notification Clicked!");
  //       },
  //     });
  //     form.resetFields(["captcha"]);
  //   }
  // };

  const handleRecaptchaChange = (value) => {
    if (value != null && value != "") {
      setCaptchaValid(true);
    } else {
      setCaptchaValid(false);
    }
    console.log("Captcha value:", value);
  };

  const fetchIPAddress = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      console.log(data, "DATTTTTT")
      localStorage.setItem('ipAddress', data.ip)
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  };
 

  const signIn = async (values: any) => {
    console.log(values, "values");

    let name;
        if (inputType == "Mobile") {
          name = values.email + "@onrx.ca";
        } else {
          name = values.email;
        }

        setLoader(true);

        // For amplify login code start
        
        await Auth.signIn(name, values.password).then(async (user: any) => {
          console.log(user, "Amplify Login Info");

          if(user.challengeName === "SMS_MFA") {
            console.log('user?.Session',user?.Session);
            await store.set("email", values.email);
            
            // await store.set("sessionToken",user?.Session);
            await history.push("/mfa");  
            dispatch(setUser([user]));
          }

          else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            // dispatch(setCognitoUserData(user));
            history.push("/complete-new-password");
          }
          else {     

            // if(user?.attributes?.["custom:userType"] != "7") {
            //   setLoader(false);
            //   notification.error({
            //     message: "Error",
            //     duration: 3,
            //     description:
            //       "Access restricted to patients only. Please log in with your patient account.",
            //   });
            // } else {

            console.log(user, "===========================")

            let rawLogs = {
              "userIdentity": {
                "loggedinuser": `${name}`,
                "role":`PATIENT`,
                "createdate":new Date().toISOString()
              },
              "action": "ACCESS",
              "source": "Login Component",
               
              "userAgent": userAgent,
              "ipAddress": localStorage.getItem('ipAddress'),
              "apiendpoint": `Cognito Login`,
              "requestParameters": {},
              "responsestatus": `${user}`
            }
        
        
            let logbody = {
              // message: `User - ${userData?.sub} - Fetch Private Insuarance - Add Family Member Component`,
              message: rawLogs,
              logGroupName: "ONRx-Patient-Portal",
              logStreamName: `User - ${user?.sub} - Login Component`,
            }

            console.log(logbody, "logbodylogbodylogbodylogbodylogbody")


              await store.set("sub", user?.attributes?.sub);
              setUserAuthData(user);
              console.log(user, "---------------");
              if (user?.attributes?.sub) {
                const userData = await UPDATED_OPEN_GET(
                  `user/issecure/${user?.attributes?.sub}`
                );
    
                console.log(userData?.data[0], "userData userData userData 11111");
                if (userData?.data[0]?.isSecure == 1) {
                  setSecuritypinModal(true);
                  setData(userData?.data[0]);
                } else {
                  await store.set(
                    "auth_token_refresh",
                    user?.signInUserSession?.idToken?.jwtToken
                  );
                  await store.set(
                    "refresh_token",
                    user?.signInUserSession?.refreshToken?.token
                  );
                  await store.set(
                    "auth_token_exp",
                    user?.signInUserSession?.idToken?.payload?.exp
                  );
                  await store.set(
                    "accessToken",
                    user?.signInUserSession?.accessToken?.jwtToken
                  );
    
                  const currentTime = new Date();
                  const formattedTime = currentTime.toISOString();
                  localStorage.setItem(
                    "current_time",
                    moment(formattedTime).format("YYYY-MM-DD HH:mm:ss")
                  );
    
                  dispatch(loadCurrentAccount());
                  dispatch(authentication(true));
                  await store.set("userauth", true);
                  store.remove("tempPassword");
    
                  setSecuritypinModal(false);
                  await history.push("/dashboard");
                }

                logEvents(logbody);
                
                setLoader(false);
                setNoteModal(true);
              }

              // }

            }

        }).catch((e: any) => {

          console.log(e, "Amplify Login Error", e.__type, e.message)

          if(e.message == "User is not confirmed.") {
              store.set("userauth", false);
              store.remove("tempPassword");
              setLoader(false);
              setresendlnk(true);
              setEmail(values.email)
              // form.resetFields(["captcha"]);
              // loadCaptchaEnginge(6, 'white', 'black', 'upper');
              notification.error({
                message: "Error",
                duration: 3,
                description:
                  "Account was not verified, Please check your email and verify the link",
              });
          }

          else if (e.message == "Incorrect username or password.") {
            store.set("userauth", false);
            store.remove("tempPassword");
            setLoader(false);
            // form.resetFields(["captcha"]);
            // loadCaptchaEnginge(6, 'white', 'black', 'upper');
            notification.error({
              message: "Error",
              duration: 2,
              description: "Invalid Username or Password",
              onClick: () => {
                console.log("Notification Clicked!");
              },
            });
          }

          else if (e.message == "callback.newPasswordRequired is not a function") {
              // dispatch(setCognitoUserData(user));
              history.push("/complete-new-password");
          } else {
            notification.info({
              message: `Something went wrong.`,
              description: 'Please try again',
            })
          }
          
          
        });



    // if (isCaptchaValid == true) {
      
      // try {
      //   if (inputType == "Mobile") {
      //     let name = values.email + "@onrx.ca";
      //     setEmail(name);
      //   } else {
      //     setEmail(values.email);
      //   }

      //   let data = {
      //     email:
      //       inputType == "Mobile" ? values.email + "@onrx.ca" : values.email,
      //     password: values.password,
      //     withMobile: inputType == "Mobile" ? true : false,
      //   };
      //   const user = await UPDATEDPOST("user/login", data);
      //   console.log(user, "pppppp------0 ");

      //   store.set("email", values.email);
      //   store.set("tempPassword", values.password);

      //   if (
      //     user?.data?.err === "callback.newPasswordRequired is not a function"
      //   ) {
      //     // dispatch(setCognitoUserData(user));
      //     history.push("/complete-new-password");
      //   } else if (user?.data?.err == "Incorrect username or password.") {
      //     store.set("userauth", false);
      //     store.remove("tempPassword");
      //     setLoader(false);
      //     // form.resetFields(["captcha"]);
      //     // loadCaptchaEnginge(6, 'white', 'black', 'upper');
      //     notification.error({
      //       message: "Error",
      //       duration: 2,
      //       description: "Invalid Username or Password",
      //       onClick: () => {
      //         console.log("Notification Clicked!");
      //       },
      //     });
      //   } else if (user?.data?.err == "User is not confirmed.") {
      //     store.set("userauth", false);
      //     store.remove("tempPassword");
      //     setLoader(false);
      //     setresendlnk(true);
      //     // form.resetFields(["captcha"]);
      //     // loadCaptchaEnginge(6, 'white', 'black', 'upper');
      //     notification.error({
      //       message: "Error",
      //       duration: 3,
      //       description:
      //         "Account was not verified, Please check your email and verify the link",
      //     });
      //   } 
      //   else if(user?.data?.messageCode == "SMS_MFA") {
          
      //     console.log(user?.data?.sessionToken, "user?.data?.sessionTokenuser?.data?.sessionToken")

      //     await store.set("sessionToken",user?.data?.sessionToken);
      //     await history.push("/mfa");
      //   }
      //   else {
      //     await store.set("sub", user?.data?.result?.idToken?.payload?.sub);
      //     setUserAuthData(user);
      //     console.log(user?.data, "---------------");
      //     if (user?.data?.result?.idToken?.payload?.sub) {
      //       const userData = await UPDATED_OPEN_GET(
      //         `user/issecure/${user?.data?.result?.idToken?.payload?.sub}`
      //       );

      //       console.log(userData?.data[0], "userData userData userData 11111");
      //       if (userData?.data[0]?.isSecure == 1) {
      //         setSecuritypinModal(true);
      //         setData(userData?.data[0]);
      //       } else {
      //         await store.set(
      //           "auth_token_refresh",
      //           user?.data?.result?.idToken?.jwtToken
      //         );
      //         await store.set(
      //           "refresh_token",
      //           user?.data?.result?.refreshToken?.token
      //         );
      //         await store.set(
      //           "auth_token_exp",
      //           user?.data?.result?.idToken?.payload?.exp
      //         );
      //         await store.set(
      //           "accessToken",
      //           user?.data?.result?.accessToken?.jwtToken
      //         );

      //         const currentTime = new Date();
      //         const formattedTime = currentTime.toISOString();
      //         localStorage.setItem(
      //           "current_time",
      //           moment(formattedTime).format("YYYY-MM-DD HH:mm:ss")
      //         );

      //         dispatch(loadCurrentAccount());
      //         dispatch(authentication(true));
      //         await store.set("userauth", true);
      //         store.remove("tempPassword");

      //         setSecuritypinModal(false);
      //         // await history.push("/dashboard");
      //         // await history.push("/mfa");
      //       }
      //       setLoader(false);
      //         await history.push("/dashboard");
      //       setNoteModal(true);
      //     }
      //   }

      //   let name;
      //   if (inputType == "Mobile") {
      //     name = values.email + "@onrx.ca";
      //   } else {
      //     name = values.email;
      //   }

      //   // For amplify login code start
      //   // const userr = await Auth.signIn(name, values.password);
      //   // console.log(userr, "login with ampifyyfyfyfy");

      //   // if(userr.preferredMFA === "SMS_MFA" || userr.challengeName === "SMS_MFA") {
      //   //   await history.push("/mfa");
        
      //   //   dispatch(setUser([userr]));
      //   // }

      //   // For amplify login code end
      // } catch (err) {
      //   console.log("error: ", err);
      //   store.set("userauth", false);
      //   setLoader(false);
      // }

    // } else {
    //   notification.error({
    //     message: "Error",
    //     duration: 2,
    //     description: "Captcha Does Not Check",
    //     onClick: () => {
    //       console.log("Notification Clicked!");
    //     },
    //   });
    //   // form.resetFields(["captcha"]);
    // }
  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
  };
  /* eslint-enable no-template-curly-in-string */
  const resendLink = async (event) => {
    event.preventDefault();
    console.log("Resend Link:", email);
    await resendConfirmationCode();
  };
  async function resendConfirmationCode() {
    try {
      await Auth.resendSignUp(email);
      notification.success({
        message: "Sent",
        duration: 4,
        description:
          "An email with link was sent, Please check your email and verify the link",
      });
      console.log("code resent successfully");
    } catch (err) {
      notification.error({
        message: "Error",
        duration: 4,
        description: "There was a problem",
      });
      console.log("error resending code: ", err);
    }
  }

  const validateMobileOrEmail = (rule, value, callback) => {
    const mobileRegex = /^\d{10}$/; // Regex pattern for a 10-digit mobile number
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (value && !mobileRegex.test(value) && !emailRegex.test(value)) {
      callback("Please enter a valid mobile number or email address");
    } else {
      callback();
    }

    if (mobileRegex.test(value)) {
      console.log("mobile regex value");
      setInputType("Mobile");
    } else if (emailRegex.test(value)) {
      console.log("email regex value");
      setInputType("Email");
    } else {
      // setInputType('');
    }
  };

  const confirmSecurityPin = async (values: any) => {
    setLoader(true);
    console.log(loginUser, "confirmSecurityPin", values);

    let body = {
      id: loginUser?.id,
      security_pin: values?.securitypin,
    };

    const user = await UPDATED_OPEN_POST("securitypin", body);
    console.log(user?.data?.body, "useruser");

    if (user?.data?.statusCode == 200) {
      await Promise.all([
        store.set(
          "auth_token_refresh",
          userAuthData?.data?.result?.idToken?.jwtToken
        ),
        store.set(
          "refresh_token",
          userAuthData?.data?.result?.refreshToken?.token
        ),
        store.set(
          "auth_token_exp",
          userAuthData?.data?.result?.idToken?.payload?.exp
        ),
        store.set(
          "accessToken",
          userAuthData?.data?.result?.accessToken?.jwtToken
        ),
      ]);

      const currentTime = new Date();
      const formattedTime = currentTime.toISOString();
      localStorage.setItem(
        "current_time",
        moment(formattedTime).format("YYYY-MM-DD HH:mm:ss")
      );

      dispatch(loadCurrentAccount());
      dispatch(authentication(true));
      await store.set("userauth", true);
      store.remove("tempPassword");

      setLoader(false);
      await history.push("/dashboard");
    } else {
      setLoader(false);
      notification.error({
        message: "Error",
        duration: 3,
        description: "PIN does not match",
      });
    }
  };

  const logEvents = async (data) => {
    console.log(data, "LOG EVENTTT")
    try {
      let result = await UPDATEDPOST(`log`, data)
    } catch(e: any) {
      console.log(e, "ERRORRR")
    }
  }

  return (
    <>
      {securityPinModal == false ? (
        <>
          <Form
            form={form}
            name="basic"
            onFinish={signIn}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            validateMessages={validateMessages}
          >
            <div className={style.main_container}>
              <div className={style.row}>
                <div className={style.col}>
                  <div className={style.div_form}>
                    {/* <div className={style.main_form}> */}
                      <img
                        src="Images/logo.png"
                        className={style.imgFluid}
                        alt="Doccure Login"
                      />
                      <h1
                        className="mt-4 mb-5"
                        style={{ color: "var(--primary-color)" }}
                      >
                        Sign in to Continue in ONRx
                      </h1>
                      <div style={{ width: "60%", minWidth: "300px" }}>
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              required: true,
                              // type: "email",
                              // message: "Please enter your email!",
                            },
                            { validator: validateMobileOrEmail },
                          ]}
                        >
                          <Input
                            className={style.inputStyle}
                            size="large"
                            placeholder="Email or Mobile"
                          />
                        </Form.Item>
                      </div>
                      <div style={{ width: "60%", minWidth: "300px" }}>
                        <Form.Item
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Please input your password!",
                            },
                          ]}
                        >
                          <Input.Password
                            className={style.inputStyle}
                            size="large"
                            placeholder="Password"
                          />
                        </Form.Item>
                      </div>

                      {/* <div
                        className="mb-3 "
                        style={{ width: "60%", minWidth: "300px" }}
                      >
                        <ReCAPTCHA
                          sitekey="6Lf2XYEmAAAAANA_AIl5HcdQfkAi1GSj2RCwXUK2"
                          onChange={handleRecaptchaChange}
                        />
                      </div> */}

                      {!loader ? (
                        <button
                          className={`${style.btnGreen} mt-1`}
                          type="submit"
                        >
                          Login
                        </button>
                      ) : (
                        <button
                        className={`${style.btnGreen} mt-1`}
                          type="submit"
                        >
                          <Spinner
                            animation="border"
                            role="status"
                            style={{ color: "white", width: 25, height: 25 }}
                          />
                        </button>
                      )}

                      <div
                        className="mt-2"
                        style={{
                          width: "60%",
                          fontSize: "16px",
                          color: "var(--primary-color)",
                        }}
                      >
                        <Link
                          to="/forgot-password"
                          className={style.forgotLink}
                        >
                          Forgot Password ?
                        </Link>
                      </div>

                      {resendlnk ? (
                        <div className="alert alert-primary text-center">
                          <p>
                            {" "}
                            An email with link was sent, Please check your email
                            and verify your account{" "}
                          </p>
                          <button
                            onClick={resendLink}
                            className="btn btn-sm btn-primary"
                          >
                            Resend Link?
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className={`text-center ${style.dontHave}`}>
                        Don’t Have an Account ?{" "}
                        <Link
                          to="/register"
                          style={{
                            color: "var(--primary-color)",
                            textDecoration: "underline",
                          }}
                        >
                          Signup Now
                        </Link>
                      </div>
                    {/* </div> */}

                      <div
                        style={{
                          marginTop: "30px",
                          textAlign: "center",
                          borderTop: "1px solid lightgrey",
                          fontSize: "14px",
                          color: "darkgrey",
                        }}
                      >
                        The ONRx platform and it's services are not meant for
                        medical emergency, If you have a medical emergency call{" "}
                        <a style={{ color: "var(--primary-color)" }}>
                          911 immediately
                        </a>{" "}
                        or visit the emergency room of the nearest hospital.
                      </div>
                      <div className={`text-center  ${style.copy}`}>
                        © 2024 ONRx. All rights reserved.
                      </div>
                    </div>
                </div>
                <div className={style.col} style={{ padding: "20px" }}>
                  <div className={style.imgStyle}></div>
                </div>
              </div>
            </div>

            {/* <div className={style.div_container}>
            <div className={style.div_content}> */}
            {/* <div className={style.div_image}>
                <div> */}
            {/* <p className="p_name">Welcome</p> */}
            {/* <p className={style.p_name}>Sign in to Continue in ONRx</p>
                </div>
                <div className={style.div_round}>
                  <img
                    src="Images/logo.png"
                    className={style.imgFluid}
                    alt="Doccure Login"
                  />
                </div>
              </div> */}

            {/* <div className={style.div_form}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      // type: "email",

                      // message: "Please enter your email!",
                    },
                    { validator: validateMobileOrEmail }
                  ]}
                >
                  <Input size="large" placeholder="Email or Mobile" />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password size="large" placeholder="Password" />
                </Form.Item>

                <div className="mb-3">
                  <ReCAPTCHA sitekey="6Lf2XYEmAAAAANA_AIl5HcdQfkAi1GSj2RCwXUK2" onChange={handleRecaptchaChange} />
                </div>

                {!loader ? (
                  <button
                    className={`${style.btnDashboard} btn btn-primary btn-block`}
                    type="submit"
                  >
                    Login
                  </button>
                ) : (
                  <button className="btn btn-primary btn-block" type="submit">
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ color: "white", width: 25, height: 25 }}
                    />
                  </button>
                )}

                <div className="text-right mt-2">
                  <Link to="/forgot-password" className="forgot-link">
                    Forgot Password ?
                  </Link>
                </div>

                {resendlnk ? (
                  <div className="alert alert-primary text-center">
                    <p> An email with link was sent, Please check your email and verify your account </p>
                    <button onClick={resendLink} className="btn btn-sm btn-primary">
                      Ressend Link?
                    </button>
                  </div>) : ''}
                <div className={`text-center ${style.dontHave}`}>
                  Don’t Have an Account ?
                  <Link to="/register" style={{ color: "#1a6ab1" }}>
                    {" "}
                    Signup Now
                  </Link>
                </div>
                <div style={{ marginTop: "10px", textAlign: "center", paddingTop: "10px", borderTop: "1px solid lightgrey", fontSize: "10px", color: "darkgrey" }}>
                  The ONRx platform and it's services are not meant for medical emergency, If you have a medical emergency call <a style={{ color: "#1a6ab1" }} >911 immediately</a>  or visit the emergency room of the nearest hospital.
                </div>
                <div className={`text-center  ${style.copy}`}>© 2023 ONRx. All rights reserved.</div>

              </div>
              <div className={style.div_img}>
              <img
                    src="Images/login_back.png"
                    alt="Doccure Login"
                  />
              </div>
            </div> */}

            {/* {resendlnk ? (
            <div className="alert alert-primary text-center">
              <p> An email with link was sent, Please check your email and verify your account </p>
              <button onClick ={resendLink} className="btn btn-sm btn-primary">
                Ressend Link?
              </button>
            </div>):''}
        <div className={`text-center ${style.dontHave}`}>
          Don’t Have an Account ?
          <Link to="/register" style={{ color: "#1a6ab1" }}>
            {" "}
            Signup Now
          </Link>
        </div>
        <div style={{ marginTop: "10px" }}>
          The ONRx platform and it's services are not meant for medical emergency,<br></br>If you have a medical emergency call 911 immediately or visit the emergency <br></br>room of the nearest hospital.
        </div>
        <div className={`text-center  ${style.copy}`}>© 2023 ONRx. All rights reserved.</div> */}
            {/* </div> */}
          </Form>
        </>
      ) : (
        <>
          <Form
            form={form}
            name="basic"
            onFinish={confirmSecurityPin}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <div className={style.main_container}>
              <div className={style.row}>
                <div className={style.col}>
                  <div className={style.div_form}>
                    <div className={style.main_form}>
                      <img
                        src="Images/logo.png"
                        className={style.imgFluid}
                        alt="Doccure Login"
                      />
                      <h1
                        className="mt-4 mb-5"
                        style={{ color: "var(--primary-color)" }}
                      >
                        Sign in to Continue in ONRx
                      </h1>
                      <div style={{ width: "60%", minWidth: "300px" }}>
                        <Form.Item
                          name="securitypin"
                          rules={[
                            {
                              required: true,
                              pattern: /^\d{6}$/,
                              message: "Please enter a 6-digit security pin.",
                            },
                          ]}
                        >
                          <Input.Password
                            className={style.inputStyle}
                            size="large"
                            placeholder="secret pin"
                          />
                        </Form.Item>
                      </div>

                      {!loader ? (
                        <button
                          className={`${style.btnGreen} mt-1`}
                          type="submit"
                        >
                          Confirm
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          <Spinner
                            animation="border"
                            role="status"
                            style={{ color: "white", width: 25, height: 25 }}
                          />
                        </button>
                      )}

                      <div
                        className="mt-2"
                        style={{
                          width: "60%",
                          fontSize: "16px",
                          color: "var(--primary-color)",
                        }}
                      >
                        <Link
                          to={{
                            pathname: "/forgot-securitypin",
                            state: { email: email },
                          }}
                          className={style.forgotLink}
                        >
                          Forgot SecurityPin ?
                        </Link>
                      </div>

                      {resendlnk ? (
                        <div className="alert alert-primary text-center">
                          <p>
                            {" "}
                            An email with link was sent, Please check your email
                            and verify your account{" "}
                          </p>
                          <button
                            onClick={resendLink}
                            className="btn btn-sm btn-primary"
                          >
                            Ressend Link?
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className={`text-center ${style.dontHave}`}>
                        Don’t Have an Account ?{" "}
                        <Link
                          to="/register"
                          style={{
                            color: "var(--primary-color)",
                            textDecoration: "underline",
                          }}
                        >
                          Signup Now
                        </Link>
                      </div>
                    </div>

                    <div style={{ padding: "50px 50px" }}>
                      <div
                        style={{
                          marginTop: "30px",
                          textAlign: "center",
                          borderTop: "1px solid lightgrey",
                          fontSize: "14px",
                          color: "darkgrey",
                        }}
                      >
                        The ONRx platform and it's services are not meant for
                        medical emergency, If you have a medical emergency call{" "}
                        <a style={{ color: "var(--primary-color)" }}>
                          911 immediately
                        </a>{" "}
                        or visit the emergency room of the nearest hospital.
                      </div>
                      <div className={`text-center  ${style.copy}`}>
                        © 2024 ONRx. All rights reserved.
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`${style.col} ${style.imageCol}`}
                  style={{ padding: "20px" }}
                >
                  <div className={style.imgStyle}></div>
                </div>
              </div>
            </div>
          </Form>
          {/* <Form
            form={form}
            name="basic"
            onFinish={confirmSecurityPin}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <div className={style.div_container}>
              <div className={style.div_content}>
                <div className={style.div_image}>
                  <div>
                    <p className={style.p_name}>Sign in to Continue in ONRx</p>
                  </div>
                  <div className={style.div_round}>
                    <img
                      src="Images/logo.png"
                      className={style.imgFluid}
                      alt="Doccure Login"
                    />
                  </div>
                </div>

                <div className={style.div_form}>
                  <Form.Item
                    name="securitypin"
                    rules={[
                      {
                        required: true,
                        pattern: /^\d{6}$/,
                        message: "Please enter a 6-digit security pin.",
                      },
                    ]}
                  >
                    <Input.Password size="large" placeholder="secret pin" />
                  </Form.Item>

                  {!loader ? (
                    <button
                      className={`${style.btnDashboard} btn btn-primary btn-block`}
                      type="submit"
                    >
                      Confirm
                    </button>
                  ) : (
                    <button className="btn btn-primary btn-block" type="submit">
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: "white", width: 25, height: 25 }}
                      />
                    </button>
                  )}

                  <div className="text-right mt-2">
                    <Link
                      to={{
                        pathname: "/forgot-securitypin",
                        state: { email: email },
                      }}
                      className="forgot-link"
                    >
                      Forgot SecurityPin ?
                    </Link>
                  </div>

                  {resendlnk ? (
                    <div className="alert alert-primary text-center">
                      <p>
                        {" "}
                        An email with link was sent, Please check your email and
                        verify your account{" "}
                      </p>
                      <button
                        onClick={resendLink}
                        className="btn btn-sm btn-primary"
                      >
                        Ressend Link?
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className={`text-center ${style.dontHave}`}>
                    Don’t Have an Account ?
                    <Link to="/register" style={{ color: "#1a6ab1" }}>
                      {" "}
                      Signup Now
                    </Link>
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      textAlign: "center",
                      paddingTop: "10px",
                      borderTop: "1px solid lightgrey",
                      fontSize: "10px",
                      color: "darkgrey",
                    }}
                  >
                    The ONRx platform and it's services are not meant for
                    medical emergency, If you have a medical emergency call{" "}
                    <a style={{ color: "#1a6ab1" }}>911 immediately</a> or visit
                    the emergency room of the nearest hospital.
                  </div>
                  <div className={`text-center  ${style.copy}`}>
                    © 2023 ONRx. All rights reserved.
                  </div>
                </div>
              </div>
            </div>
          </Form> */}
        </>
      )}
    </>
  );
};

export default Login;
