import { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Modal,
  Button,
  Spin,
  notification,
  Upload
} from "antd";
import _ from "lodash";
import { EnvironmentFilled } from '@ant-design/icons'
import { useDispatch, useSelector } from "react-redux";
import { UPDATEDGET, UPDATEDPOST } from "services/common.api";
import pharmacyListStaticArray from './pharmacyList.json'
import { InboxOutlined } from "@material-ui/icons";
import { v4 as uuid } from "uuid";
import { s3Upload } from "../../services/s3fileUpload/index";
import style from "./style.module.scss"
import { isMobile, isTablet, isBrowser } from 'react-device-detect';


const { Dragger } = Upload;
const AddPrescription = ({
  open,
  close,
  title,
  getpriscriptionList,
  selectedPatient
}) => {
  let user = JSON.parse(localStorage.getItem("user"));
  const { Option } = Select;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [btnLoader, setBtnLoader] = useState(false);
  const [formValues, setFormValues] = useState({} as any);
  const [pharmacyList, setPharmacyList] = useState([]);
  const [selFamilyMember, setSelFamilyMember] = useState(null);
  const [selPharmacyDetail, setSelPharmacyDetail] = useState({} as any);
  const [selPharmacy, setSelPharmacy] = useState(null);
  const [familyMembers, setFamilyMembers] = useState([]);

  const [uploadedFilePath, setUploadedFilePath] = useState(null);
  const userAgent = navigator.userAgent;

  const props = {
    name: "file",
    multiple: false,
    // accept: "application/pdf","application/vnd.openxmlformats-officedocument.wordprocessingm","application/msword",
    accept: ".pdf",
    beforeUpload: async (file) => {
      console.log(file, "selected file");
      setUploadedFilePath(file);
      return false;
    },
  };

  const logEvents = async (data) => {
    console.log(data, "LOG EVENTTT")
    try {
      let result = await UPDATEDPOST(`log`, data)
    } catch(e) {
      console.log(e, "ERRORRR")
    }
  }

  useEffect(() => {
    getPharmacyList();
    getFamilyMember();
    setSelPharmacy(null);
    setSelPharmacyDetail([]);
    setSelFamilyMember(selectedPatient)
  }, [open]);

  const onFinish = async () => {
    if (uploadedFilePath != null) {
      setBtnLoader(true);
      const data = {
        userId: user?.id,
        type: uploadedFilePath.type,
        fileName: `prescriptions/${uuid()}_` + uploadedFilePath.name
      };

      try {
        console.log("try ma avyu", data)
        const uploadedDocument = await s3Upload(data.fileName, uploadedFilePath);
        console.log("Upload ma avyu", uploadedDocument)

        if (uploadedDocument) {
          console.log(uploadedDocument, "uploadedDocument");

          try {
            
            let body = {
              "patientId": selFamilyMember,
              "appointmentId": null,
              "accreditationNumber": selPharmacyDetail.accreditationNumber,
              "pharmacyAddress": selPharmacyDetail.address,
              "pharmacyFax": selPharmacyDetail.fax,
              "pharmacyName": selPharmacyDetail.name,
              "pharmacyPhone": selPharmacyDetail.phone,
              "postalCode": selPharmacyDetail.postalcode,
              "companyID": selPharmacyDetail.id,
              "schedule_time": null,
              "status": 1,
              "precription_document": uploadedDocument
            } as any
            console.log("objectttt", body)
            UPDATEDPOST('prescriptions', body).then((data) => {

              let rawLogs = {
                "userIdentity": {
                  "loggedinuser": `${user?.Email}`,
                  "role":`${user?.role}`,
                  "createdate":new Date().toISOString()
                },
                "action": "INSERT",
                "source": "Add Prescription Component",
                 
                "userAgent": userAgent,
                "ipAddress": localStorage.getItem('ipAddress'),
                "apiendpoint": "prescriptions",
                "requestParameters": body,
                "responsestatus": data?.status
              }

              console.log(data, "prescriptions created");
              setBtnLoader(false);

              let logBody = {
                // message: `User - ${user?.sub} - Add Prescription - Add Prescription Component`,
                message: rawLogs,
                logGroupName: "ONRx-Patient-Portal",
                logStreamName: `User - ${user?.cognitoid} - Add Prescription Component`,
              }
          
              logEvents(logBody);

              // if(userType == 'familyMember'){
              //   getpriscriptionList(familyMemeberData?.familymemberid);
              // }else{
              //   getpriscriptionList(user?.id);
              // }

              getpriscriptionList(selectedPatient);

              close();
            })
          } catch (error) {
            console.log('error: ', error)
            setBtnLoader(false);

          }


        }
      } catch (err) {
        setBtnLoader(false);
        console.log("err: ", err);
      }
    } else {
      notification.error({
        message: "Please Upload File First.",
      });
    }


    // setValuesForFirstForm(values);
    // setBtnLoader(true);
  }

  // const onChange = async (values) => {
  //   console.log(values,"valuessss");
  //   const name = values?.target?.name;
  //   const value = values?.target?.value;
  //   setFormValues((formValues) => ({ ...formValues, [name]: value }));
  // }

  const getFamilyMember = async () => {
  
    try {
      // const {
      //   data: {
      //     body: { FamilyMembers },
      //   },
      // } = await UPDATEDGET(`userfamilymembers?id=${user?.id}`);

      // console.log(FamilyMembers, "FamilyMembers");

      // if (FamilyMembers) {
      //   setFamilyMembers(FamilyMembers);
      // } else {
      //   setFamilyMembers([]);
      // }

      const response = await UPDATEDGET(`userfamilymembers?id=${user?.id}`);
      const { data } = response;
      const { body } = data;
      const { FamilyMembers } = body;

      let rawLogs = {
        "userIdentity": {
          "loggedinuser": `${user?.Email}`,
          "role":`${user?.role}`,
          "createdate":new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "Add Prescription Component",
         
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `userfamilymembers?id=${user?.id}`,
        "requestParameters": {},
        "responsestatus": response?.status
      }

      let logBody = {
        // message: `User - ${user?.sub} - Get Family Member - Add Prescription Component`,
        message: rawLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${user?.cognitoid} - Add Prescription Component`,
      }
  
      logEvents(logBody);

      console.log(data.statusCode, "DATTAAA")

      console.log(FamilyMembers, "FamilyMembers");
      setFamilyMembers(FamilyMembers ?? []);

    } catch (err) {
      console.log("err: ", err);
    }

  };

  const getPharmacyList = async () => {

    


    try {
      console.log("pharmacyListStaticArray static ", pharmacyListStaticArray.pharmacyListStaticArray)

      const pharmacyListReponse = await UPDATEDGET(`company`)
      console.log(pharmacyListReponse, 'response response')
      let tempArray = [];

      let rawLogs = {
        "userIdentity": {
          "loggedinuser": `${user?.Email}`,
          "role":`${user?.role}`,
          "createdate":new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "Add Prescription Component",
         
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": "company",
        "requestParameters": {},
        "responsestatus": pharmacyListReponse?.status
      }

      let logBody = {
        // message: `User - ${user?.sub} - Get Pharmacy List - Add Prescription Component`,
        message: rawLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${user?.cognitoid} - Add Prescription Component`,
      }
  
      logEvents(logBody);

      if (pharmacyListReponse?.data.length > 0) {

        pharmacyListReponse?.data?.forEach((element) => {
          if (element.type == "PHARMACY") {
            tempArray.push(element)
          }
        })
        const arr3 = tempArray.concat(pharmacyListStaticArray.pharmacyListStaticArray)
        setPharmacyList(arr3)
      } else {
        setPharmacyList(pharmacyListStaticArray.pharmacyListStaticArray)
      }
    } catch (error) {
      notification.error({
        message: error.message,
      })
    }
  }

  const onChange = async (values) => {
    const indexNumber = values.split('-')
    let index
    if (indexNumber.length) {
      index = indexNumber[indexNumber.length - 1]
    }
    const pharmacyData = pharmacyList[index]
    if (pharmacyData) {
      console.log("=======", pharmacyData)
      setSelPharmacy(pharmacyData.name);
      setSelPharmacyDetail(pharmacyData);
    }
  }

  return (
    <>
      <Modal
        title={title}
        visible={open}
        footer={null}
        onOk={close}
        onCancel={close}
        width={1100}
        centered>

        <Form
          layout="vertical"
          name="basic"
          form={form}
        >
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-xs-12 col-lg-12">

                  <label>Select Patient</label>

                  <Select
                    showSearch
                    placeholder="Select Patient"
                    optionFilterProp="children"
                    size="large"
                    style={{ width: "100%" }}
                    defaultActiveFirstOption
                    onChange={(e) => {
                      console.log(e);
                      setSelFamilyMember(e)
                    }}
                    // onChange={handleChangeFamilyMemeber}
                    value={selFamilyMember}
                  >
                    <Option
                      value={user?.id}
                      key='own'
                    >
                      {user?.FirstName} {user?.LastName}
                    </Option>
                    {familyMembers ? (
                      <>
                        {familyMembers?.length != 0 ? (
                          <>
                            {familyMembers?.map((itemm, index) => (
                              <>
                                {itemm.userName != null ? (
                                  <Option
                                    key={itemm?.familymemberid}
                                    value={itemm?.familymemberid}
                                  >
                                    {itemm?.userName}
                                  </Option>
                                ) : null}
                              </>
                            ))}
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </Select>
                </div>

                <div className="col-sm-12 col-md-12 col-xs-12 col-lg-12 mt-4">
                  <label>Select pharmacy</label>
                  <Select
                    showSearch
                    placeholder="Select a pharmacy"
                    optionFilterProp="children"
                    size="large"
                    style={{ width: "100%" }}
                    onChange={(value) => onChange(value)}
                    value={selPharmacy}
                    notFoundContent={<Spin size="small" />}
                  >
                    {pharmacyList.length
                      ? pharmacyList.map((pharamcy, index) => (
                        <Option key={index} value={`${pharamcy?.accreditationNumber}-${index}`}>
                          {pharamcy?.name}
                          <p>{pharamcy?.address}</p>
                        </Option>
                      ))
                      : ''}
                  </Select>
                </div>

                <div className="col-sm-12 col-md-12 col-xs-12 col-lg-12 mt-4">
                  <Dragger
                    {...props}
                    style={{ width: "100%", padding: 20, marginBottom: 20 }}
                    showUploadList={false}
                  >
                    {/* {" "}
                    <p className="ant-upload-drag-icon">
                      {" "}
                      <InboxOutlined />{" "}
                    </p>{" "}
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>{" "}
                    <p className="ant-upload-hint">
                      {" "}
                      Support for a .pdf file
                    </p>{" "} */}


                    {" "}
                    <p className="mt-1">
                      {" "}
                      <img
                        src="Images/cloud-upload.png"
                        alt="Upload icon"
                      />
                    </p>{" "}
                    <p
                      className="mt-2 "
                      style={{ fontSize: "16px" }}
                    >
                      Drag and Drop file Here
                    </p>{" "}
                    <Button
                      type="primary"
                      className={`${style.btnBrowse} mt-2`}
                    >
                      Browse Files
                    </Button>
                  </Dragger>

                  <label>{uploadedFilePath?.name}</label>
                </div>


              </div>
            </div>
          </div>

          <div
            className="row ml-1 mr-1 border-top"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="pt-4 pr-3">
              <Form.Item>
                <Button
                  style={{ width: 155, height: "auto" }}
                  htmlType="submit"
                  className="btn btn-primary px-5"
                  loading={btnLoader}
                  onClick={onFinish}
                >
                  {/* {_.isEmpty(editFamilyMember) ? "Submit" : "Edit"} */}
                  Submit
                </Button>
              </Form.Item>
            </div>
            <div className="pt-4 pr-3">
              <Form.Item>
                <Button
                  style={{ width: 155, height: "auto" }}
                  htmlType="button"
                  onClick={close}
                  className="btn btn-light px-5"
                >
                  Cancel
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form >
      </Modal >
    </>
  );
};

export default AddPrescription;
