import React, { useState } from "react";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import { Checkbox, Modal, notification, Spin, Form as Form1, Tabs } from "antd";
import { UPDATED_OPEN_POST, UPDATEDPOST } from "../../services/common.api";
import * as AuthService from "../../services/auth";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setProfileComplate, setUserToken } from "redux/actions/userActions";
import SweetAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import PrivacyPolicy from "components/privacyPolicy";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import style from "./style.module.scss";

const Register = (pros) => {
  const dispatch = useDispatch();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [loaderO, setLoaderO] = useState(false);
  const [hideAlert, setHideAlert] = useState(false);
  const [visible, setVisibility] = useState(true);
  const [visibleConfirm, setVisibleConfirm] = useState(true);
  const [term, setTerm] = useState(false);
  const [mobile, setMobileOnly] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);
  const [serviceAgreement, setServiceAgreement] = useState(false);
  const [privacyModel, setPrivacyModel] = useState(false);
  const [userAgreementModal, setUserAgreementModal] = useState(false);
  const [serviceAgreementModel, setServiceAgreementModel] = useState(false);
  const [usrexists, setusrexists] = useState(false);
  const [otpScreen, setOtpScreenn] = useState(false);
  const [otpMatch, setOtpMatch] = useState(false);
  const [user, setUser] = useState({} as any);
  const [form1] = Form1.useForm();
  const [activeTab, SetactiveTab] = useState('1');

  // const onKeyChange = (key) => {
  //   console.log(mobile," mobile only")
  //   SetactiveTab(key)
  //   if(key == 2){
  //     setMobileOnly(true)
  //     console.log(mobile," mobile only if")
  //   }
  //   if(key == 1){
  //     setMobileOnly(false)
  //     console.log(mobile," mobile only else")
  //   }
  // }

  const registerUser = async (values) => {
    console.log(values, "register user entered values",mobile);

    try {
      setLoader(true);

      let data = {
        username: mobile === true ? values.email + "@onrx.ca" : values.email,
        password: values.newPasword,
        phone_number: mobile === false ?  "+1" +  values.phone : "+1" +  values.email,
        name: values.firstname + " " + values.lastname,
        gender: values.gender,
        role: "PATIENT"
      }

      console.log(data, "data data data")

      console.log(data, "data tatattatatata");
      const user = await UPDATED_OPEN_POST("createuser", data);
      console.log(user.data, "datatatatatat 11");

      if(user.data == 'An account with the given email already exists.'){
        if(mobile == true){
          notification.error({
            message: 'An account with the given mobile already exists.',
            duration: 2,
  
          });
        }else{
          notification.error({
            message: user.data,
            duration: 2,
  
          });
        }

      }

      if (user?.data?.userSub) {
        console.log("An account with the given email already exists. An account with the given email already exists.")
        const data = {
          Type: "PATIENT",
          FirstName: values.firstname,
          LastName: values.lastname,
          Email: mobile === true ? values.email + "@onrx.ca" : values.email,
          Phone: mobile === false ?  values.phone : values.email,
          createDate: moment().utc(),
          cognitoid: user?.data?.userSub,
          gender: values.gender,
          Active : mobile == true ? 0 : 1
        };
        console.log(data, "dataaaaaaaa");

        const response: any = await UPDATED_OPEN_POST("user/patient", data);
        console.log(response, "res user body");
        console.log(response?.data?.body?.user.id, "response?.data?.body?.user");

        setUser(response?.data?.body?.user)

        if (response?.data?.body?.user && mobile == true) {

          console.log("ifff maa avyu")

          const data = {
            id: response?.data?.body?.user?.id,
            phoneNumber: "+1" + values.email,
          }

          const res = await UPDATED_OPEN_POST("sendverificationcode", data);

          setOtpScreenn(true)

          console.log(res, "res resres of twilio code send")

          if (res.data.statusCode == 200) {
            notification.success({
              message: "OTP send to register mobile number Successfull",
              duration: 2,
              description: "OTP send to register mobile number Successfull",
            });
          }
        }
        dispatch(setProfileComplate("false"));

        notification.success({
          message: "Registration Successfull",
          duration: 2,
          description: "Registration Successfull",
          onClick: () => {
            console.log("Notification Clicked!");
          },
        });
        setHideAlert(true);

        const consentData = {
          added_by: response?.data?.body?.user.id,
          consent_page: "Register",
          consent_name: "privacyPolicy, agreement, serviceProviderAgreement",
          timestemp: moment().format('YYYY-MM-DD HH:mm:ss')
        };
    
        const Consent = await UPDATEDPOST("consent", consentData);
    
        console.log(Consent, "Consent");
    

      }



      setLoader(false);

      
      


    } catch (error) {
      console.log(error);
      if (error.message == 'An account with the given email already exists.') {
        setusrexists(true)
        notification.error({
          message: error.message,
          duration: 2,
          onClick: () => {
            console.log("Notification Clicked!");
          },
        });
      }
      setLoader(false);
    }
  };

  const emailRegex = /^[^\s@]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const mobileRegex = /^\d{10}$/;
  const emailRegexIn = /^[^\s@]+@onrx\.ca$/;

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Required";
    } else  if (value && !mobileRegex.test(value) && !emailRegex.test(value)) {
      error = 'Please enter a valid mobile number or email address';
    }
    else if(emailRegexIn.test(value)){
      error = "Email with domain onrx.ca not allowed !";
    }

    if (mobileRegex.test(value)) {
      console.log("mobile regex value")
      setMobileOnly(true)
    } else if (emailRegex.test(value)) {
      console.log("email regex value")
      setMobileOnly(false)
    }

    return error;
  }

  const handleBack = async () => {
    setHideAlert(false);
    history.push("/");
  };

  const onCancel = () => {
    setHideAlert(false);
  };

  const privacyPolicy = (event: any) => {
    console.log(event.target.checked);
    setTerm(event.target.checked);
  };

  const agreement = (event: any) => {
    console.log(event.target.checked);
    setUserAgreement(event.target.checked);
  };

  const serviceProviderAgreement = (event: any) => {
    console.log(event.target.checked);
    setServiceAgreement(event.target.checked);
  };

  const privacyModelClose = () => {
    setPrivacyModel(false);
  };
  const resendOtp = async () => {
    console.log("resendOtpresendOtpresendOtp",user)

    const data = {
      id: user?.id,
      phoneNumber: "+1" + user.phoneNumber,
    }

    const res = await UPDATED_OPEN_POST("sendverificationcode", data);

    setOtpScreenn(true)

    console.log(res, "res resres of twilio code send")

    if (res.data.statusCode == 200) {
      notification.success({
        message: "OTP resend Successfull",
        duration: 2,
        description: "OTP resend Successfull",
      });
    }
  };
  const handleOtp = async (value) => {
    setLoaderO(true)
    console.log("otp click button", value)

    let data = {
      id: user?.id,
      otp: value.otp
    }

    const res = await UPDATED_OPEN_POST("verifyotp", data);

    console.log(res, " code ssended verify")

    if (res.data.statusCode == 200) {

      setOtpMatch(true)
      notification.success({
        message: "OTP Matched Successfull",
        duration: 2,
        description: "OTP Matched Successfull",
      });
      setLoaderO(false)
    } else {
      notification.error({
        message: "OTP Did not Match!",
        duration: 2,
        description: "OTP Did not Match!",
      });
    }
    setLoaderO(false)

  };

  const phoneRegExp = /^(\+?[1-9]{1,4}[ \-]*)?(\([0-9]{2,3}\)[ \-]*)?([0-9]{2,4}[ \-]*)?[0-9]{3,4}[ \-]*[0-9]{3,4}$/;


  return (
    <div>

      {otpScreen == true ? <>

        {otpMatch == true ? (
          <SweetAlert
            success
            title="Signup successful!"
            onConfirm={handleBack}
            onCancel={onCancel}
            confirmBtnText="Go Login"
          >
            Please check your Email and Validate your Account
          </SweetAlert>
        ) : null}
        <Form1
          form={form1}
          onFinish={handleOtp}
          layout="vertical"
        >

          <div className="row pt-5 justify-content-center">
            <div className="col-sm-8 ">
              <div className="form-group required">
                <Form1.Item
                  label="Enter Otp here"
                  name="otp"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter your OTP!",
                    },
                  ]}
                >
                  <input
                    autoComplete="off"
                    type="text"
                    className={`${style.inputbox}`}
                    name="FirstName"
                  />
                </Form1.Item>
              </div>
            </div>
          </div>

          {loaderO == true ? <>

            <div className="row d-flex justify-content-center">
              <div className="col-sm-2">
                <Spin />
              </div>
            </div>

          </> : <>
            <div className="row d-flex justify-content-center">
              <div className="col-sm-4">
                <button type="submit" className="btn">
                  CONFIRM
                </button>
              </div>
            </div>
          </>}


          <div className="row d-flex justify-content-center">
            <div className="col-sm-3">
              <a href="#" onClick={resendOtp}>resend Otp</a>
            </div>
          </div>


        </Form1>

      </> : <>

        {/* <Tabs
          onChange={onKeyChange}
          activeKey={activeTab}
          className={`vb-tabs-bold mt-4`}
        >
          <Tabs.TabPane tab="Sign Up with Email" key="1">

          </Tabs.TabPane>
          <Tabs.TabPane tab="Sign Up with Mobile" key="2">

          </Tabs.TabPane>

        </Tabs> */}

        <Formik
          enableReinitialize={true}
          initialValues={{
            firstname: "",
            lastname: "",
            email: "",
            newPasword: "",
            confirmPassword: "",
            phone: "",
            gender: "",
          }}
          validationSchema={Yup.object().shape({
            firstname: Yup.string().required("Please Enter First Name !"),
            lastname: Yup.string().required("Please Enter Last Name !"),
            email: mobile
              ? Yup.string()
              : Yup.string().required("Please Enter Valid Email Or Mobile!"),
            // otp: otpScreen
            // ? Yup.string()
            // : Yup.string().required("Please Enter Valid OTP!"),
            phone: !mobile ?  Yup.string()
              .required("Please Enter Valid Mobile Number")
              .matches(phoneRegExp, "Mobile Number is Not Valid")
              .min(10, "Please Enter Valid Mobile Number")
              .max(10, "Please Enter Valid Mobile Number") : Yup.string(),
            gender: Yup.string().required("Please Select Gender!"),
            newPasword: Yup.string()
              .required("Please Enter password !")
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Please choose a stronger password. Try a mix of letters, numbers, and symbols"
              ),
            confirmPassword: Yup.string().oneOf(
              [Yup.ref("newPasword"), null],
              "Passwords must match"
            )
          })}
          onSubmit={(formData, { setStatus, setSubmitting }) => {
            console.log("formData: ", formData);
            registerUser(formData);
          }}
        >
          {({
            errors,
            status,
            touched,
            isSubmitting,
            setFieldValue,
            values,
            handleChange,
            handleBlur,
          }) => {
            return (
              <>
                {hideAlert == true ? (
                  <SweetAlert
                    success
                    title="Signup successful!"
                    onConfirm={handleBack}
                    onCancel={onCancel}
                    confirmBtnText="Go Login"
                  >
                    Please check your Email and Validate your Account
                  </SweetAlert>
                ) : null}

                <Form>
                  <div className="row mt-2">
                    <div className="col-sm-6">
                      <div className={`form-group required  ${style.inputbox2}`} >
                        <Field
                          name="firstname"
                          type="text"
                          placeholder="First Name *"
                          autoComplete="off"
                          className={
                            "form-control" +
                            (errors.firstname && touched.firstname
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="firstname"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                    <div className={`form-group required  ${style.inputbox2}`} >
                        <Field
                          name="lastname"
                          type="text"
                          placeholder="Last Name *"
                          autoComplete="off"
                          className={
                            "form-control" +
                            (errors.lastname && touched.lastname
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="lastname"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>

                  <div className={`form-group required  ${style.inputbox2}`} >
                      <Field
                        name="email"
                        type="text"
                        placeholder="Email Or Phone *"
                        autoComplete="off"
                        validate={validateEmail}
                        className={
                          "form-control" +
                          (errors.email && touched.email ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                

                  {mobile == false ? <>
                    <div className={`form-group required  ${style.inputbox2}`} >
                    <Field
                      name="phone"
                      type="text"
                      autoComplete="off"
                      placeholder="Mobile *"
                      className={
                        "form-control" +
                        (errors.phone && touched.phone ? " is-invalid" : "")
                      }
                    />

                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="invalid-feedback"
                    />

                    {/* <PhoneInput
                    country={"ca"}
                    value="phone"
                  // onChange={(phone) => handleChange(phone)}
                  /> */}

                  </div>
                    </> : null}


                    <div className={`form-group required  ${style.inputbox2}`} >
                    <div style={{ position: "relative" }}>
                      <Field
                        name="newPasword"
                        type={visible ? "password" : "text"}
                        autoComplete="off"
                        placeholder="New Pasword *"
                        className={
                          "form-control" +
                          (errors.newPasword && touched.newPasword
                            ? " is-invalid"
                            : "")
                        }
                      />
                      {!visible ?
                        <FontAwesomeIcon className={(errors.newPasword && touched.newPasword ? "validMainEye" : "mainEye")} icon={faEye} onClick={() => setVisibility(!visible)} /> :
                        <FontAwesomeIcon className={(errors.newPasword && touched.newPasword ? "validMainEye" : "mainEye")} icon={faEyeSlash} onClick={() => setVisibility(!visible)} />}

                      <ErrorMessage
                        name="newPasword"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>

                  <div className={`form-group required  ${style.inputbox2}`} >
                    <div style={{ position: "relative" }}>
                      <Field
                        name="confirmPassword"
                        type={visibleConfirm ? "password" : "text"}
                        autoComplete="off"
                        placeholder="Confirm Password *"
                        
                        className={
                          "form-control" +
                          (errors.confirmPassword && touched.confirmPassword
                            ? " is-invalid"
                            : "")
                        }
                      />
                      {!visibleConfirm ?
                        <FontAwesomeIcon className={(errors.confirmPassword && touched.confirmPassword ? "validMainConfirmEye" : "mainConfirmEye")} icon={faEye} onClick={() => setVisibleConfirm(!visibleConfirm)} /> :
                        <FontAwesomeIcon className={(errors.confirmPassword && touched.confirmPassword ? "validMainConfirmEye" : "mainConfirmEye")} icon={faEyeSlash} onClick={() => setVisibleConfirm(!visibleConfirm)} />}

                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>

                  <div className={`form-group required  ${style.inputbox2}`} >
                    <Field
                      as="select"
                      name="gender"
                      className={
                        "form-control" +
                        (errors.gender && touched.gender ? " is-invalid" : "")
                      }
                    >
                      <option> Select gender </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </Field>
                    <ErrorMessage
                      name="gender"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div>
                    <Checkbox onChange={privacyPolicy}>
                      {/* <span className="Info" style={{display: "block"}}>I understand and agree with the <a style={{color: "#1a6ab1"}} hCref="https://www.onhealthalliance.com/privacy">Privacy & Policy</a></span>  */}
                      {/* <span className="Info" style={{ display: "block" ,fontSize:'14px'}}>I understand and agree with the <a style={{ color: 'var(--primary-color)',fontWeight:'500' }} onClick={() => setPrivacyModel(true)}>Privacy & Policy</a></span> */}
                      <span className="Info" style={{ display: "block" ,fontSize:'14px'}}>I understand and agree with the <a style={{ color: 'var(--primary-color)',fontWeight:'500' }} target="_blank"  href="https://onhealthalliance.com/privacy/">Privacy & Policy</a></span>
                    </Checkbox>
                    {/* <ErrorMessage
                  name="privacy"
                  component="div"
                  className="invalid-feedback"
                /> */}
                  </div>
                  <div>
                    <Checkbox className="pr-2" onChange={agreement}>
                      {/* <span className="Info" style={{ display: "block" ,fontSize:'14px' }}>I understand and agree with the <a style={{ color: 'var(--primary-color)',fontWeight:'500' }} onClick={() => setUserAgreementModal(true)}>User Agreement</a></span> */}
                      <span className="Info" style={{ display: "block" ,fontSize:'14px' }}>I understand and agree with the <a style={{ color: 'var(--primary-color)',fontWeight:'500' }} target="_blank" href="https://onhealthalliance.com/terms-of-use/">User Agreement</a></span>
                    </Checkbox>
                    {/* <ErrorMessage
                  name="userAgreement"
                  component="div"
                  className="invalid-feedback"
                /> */}
                  </div>
                  <div>
                    <Checkbox className="pr-2" onChange={serviceProviderAgreement}>
                      <span className="Info" style={{ display: "block",fontSize:'14px' }}>I understand and agree with the <a style={{ color: 'var(--primary-color)',fontWeight:'500' }} onClick={() => setServiceAgreementModel(true)}>Service Provider Agreement</a></span>
                    </Checkbox>
                    {/* <ErrorMessage
                  name="serviceAgreement"
                  component="div"
                  className="invalid-feedback"
                /> */}
                  </div>

                  <div className="form-group text-center mb-0 m-t-15 pb-3">
                    <div >
                      {loader ? (
                        <Spin />
                      ) : (
                        <button
                          type="submit"
                          // disabled={isSubmitting}
                          className={`${style.btnGreen}`}
                          style={{
                            width: "100%",
                            height: '40px',
                            fontSize: 14,
                          }}
                          disabled={!term || !userAgreement || !serviceAgreement}
                        >
                          Continue
                        </button>
                      )}
                    </div>
                    {usrexists ? (
                      <div className="alert alert-primary text-center">
                        <p> An account with this email already exists, please log in with your email! </p>

                      </div>) : ''}
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </>}



      {privacyModel ? (
        <PrivacyPolicy
          title="Privacy Policy"
          open={privacyModel}
          close={privacyModelClose}
        />
      ) : null}


      <Modal
        centered
        visible={userAgreementModal}
        width={1000}
        onOk={() => setUserAgreementModal(false)}
        onCancel={() => setUserAgreementModal(false)}
        footer={null}
      >
        <div className="pt-4">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <Viewer
              fileUrl='https://cf2c9230-6450-4364-9252-7d656ea6986d.filesusr.com/ugd/911847_05eee2ae00b84bd9a94262e36b211932.pdf'
              plugins={[
                defaultLayoutPluginInstance,
              ]}
            />
          </Worker>
        </div>
      </Modal>

      <Modal
        centered
        visible={serviceAgreementModel}
        width={1000}
        onOk={() => setServiceAgreementModel(false)}
        onCancel={() => setServiceAgreementModel(false)}
        footer={null}
      >
        <div className="pt-4">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <Viewer
              fileUrl='https://cf2c9230-6450-4364-9252-7d656ea6986d.filesusr.com/ugd/911847_3ae83e80f76447f6a43ce649bef5d3e6.pdf'
              plugins={[
                defaultLayoutPluginInstance,
              ]}
            />
          </Worker>
        </div>
      </Modal>


    </div>
  );
};

export default Register;
