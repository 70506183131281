/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/button-has-type */
/* eslint-disable no-var */
/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/self-closing-comp */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useEffect } from "react";
// import { Helmet } from "react-helmet";
import {
  notification,
  Typography,
  Select,
  Drawer,
  Modal,
  Tag,
  Button,
  Tabs,
  Row,
  Col,
} from "antd";
import { useDispatch, connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
// import menuData from "../../services/menu";
import style from "./style.module.scss";
import { UPDATEDGET, UPDATEDPOST } from "../../services/common.api";
import { GRADIENTS } from "../../constants/events.constant";
import { TopBar } from "components";
import DashboardSidebar from "pages/dashboard/sidebar/sidebar";
import { faEquals } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './App.css'
import { AppointmentRequestType, setSelectedFamilyMember } from "redux/actions/userActions";
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import * as subscriptions from '../../graphql/subscriptions';
import { MessageOutlined } from "@ant-design/icons";
import { RINGER } from "pages/dashboard/img";
import { s3GetPrivate } from "../../services/s3fileUpload/index";
import crypto from 'crypto';
// const mapStateToProps = (state) => ({
//   menuData: state.menuData,
// });
const { Title } = Typography;

const ViewEventWorkShops = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const [userInfo, setUserInfo] = useState(user.CompanyEmployee[0]);
  const [eventWorkShopList, setEventWorkShopList] = useState([]);
  const [eventModalVisible, setEventModalVisisble] = useState(false);
  const [particluarEvent, setParticularEvent] = useState({});
  const [noEvent, setNoEvent] = useState(false);
  const [futureEvents, setFutureEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const { TabPane } = Tabs;
  const [subscription, setSubscription] = useState(null)
  const userData = useSelector((state) => state.userReducer.user);

  const [userAgent, setUserAgent] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent;
    console.log('YSYSYSYSYSY',userAgent);
    setUserAgent(userAgent);
    }, []);

  // const audio = new Audio('https://onrx-dev-portal.s3.ca-central-1.amazonaws.com/message.mp3');
  // audio.crossOrigin="anonymous"

  const playAudio = () => {
    const audio = new Audio();
    // audio.crossOrigin = "anonymous";
    audio.src = 'https://www.soundjay.com/phone/sounds/telephone-ring-01a.mp3';
    audio.play()
      .catch(error => {
        // Handle any playback errors
        console.log(error,"audio play error");
      });
  }


  // useMemo(() => {
  //   // getGroupPlan()
  //   dispatch({
  //     type: "menu/SET_STATE",
  //     payload: {
  //       menuData: menuData.getClinicMenu(),
  //     },
  //   });
  // });

  useEffect(() => {
    if(user?.id){
      const subscribeToNotifications = async () => {
        if (subscription && subscription._cleanup !== undefined) {
          // If a subscription already exists, no need to create a new one
          return;
        }
  
        const newSubscription = API.graphql(graphqlOperation(subscriptions.subscribeToNewNotification, { userId: user?.id + "" })).subscribe({
          next: (notificationData) => {
            const newNotification = notificationData.value.data.subscribeToNewNotification;
            console.log('New notification received:', newNotification);
  
            console.log(localStorage.getItem("chatHead"),"localStorage.getItem",newNotification?.conversationId);
            if(parseInt(newNotification?.conversationId) == parseInt(localStorage.getItem("chatHead"))){}else{
              notification.open({
                message: 'New Message',
                description: newNotification?.message,
                duration: 5, 
                icon: <MessageOutlined style={{ color: '#1890ff' }} />,
                placement: 'topRight',
              });
              // audio.play();
              playAudio()
            }
  
          },
          error: (error) => {
            console.log(user)
            console.error('Error subscribing to new notification:', error);
          },
        });
  
        setSubscription(newSubscription);
      };
  
      subscribeToNotifications();
  
      return () => {
        if (subscription) {
          subscription?.unsubscribe();
          setSubscription(null); 
        }
      };
    }
   
  }, [subscription,user?.id]); 



  const generate = (id, gradientColor) => {
    if (gradientColor) {
      return gradientColor;
    } else {
      if (id >= GRADIENTS.length - 1) {
        const randomValue = crypto.randomBytes(1).readUInt8(0) % GRADIENTS.length;
        return GRADIENTS[randomValue];
      } else {
        return GRADIENTS[id];
      }
    }
  };

  const renderBanner = (index, item) => {
    if (item.event_type === "workshop") {
      return (
        <div
          id={"grad-" + index}
          className={`${style.setheight} d-flex py-3 border-bottom`}
          style={{
            background: generate(index, item.gradientColor),
          }}
        >
          <div
            className={`${style.author} col-xs-2 col-sm-3 align-self-center`}
          >
            <div className={`${style.profileImage} d-flex`}>
              <img
                async
                src={
                  item.facilitatorImage || "/assets/images/user_placeholder.jpg"
                }
              />
            </div>
            <div className={`${style.facilitorname} `}>
              {item.facilitatorName}
            </div>
          </div>
          <div className={`${style.event_name}align-self-center `}>
            {item.name.toUpperCase()}
          </div>
        </div>
      );
    } else {
      if (item.banner_image) {
        return (
          <div
            style={{
              backgroundImage: `url(${process.env.REACT_APP_ASSET_URL}/${item.banner_image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
            className={`${style.setheight} d-flex py-3 border-bottom   justify-content-center`}
          >
            <div
              className={`${style.event_name} ${style.transbox}align-self-center `}
            >
              {item.name.toUpperCase()}
            </div>
          </div>
        );
      } else {
        return (
          <div
            id={"grad-" + index}
            className={`${style.setheight} d-flex py-3 border-bottom   justify-content-center`}
            style={{
              background: generate(index, item.gradientColor),
            }}
          >
            <div className={`${style.event_name} align-self-center`}>
              {item.name.toUpperCase()}
            </div>
          </div>
        );
      }
    }
  };

  const linkStartWith = (link) => {
    let newUrl = window.decodeURIComponent(link);
    newUrl = newUrl.trim().replace(/\s/g, "");

    if (/^(:\/\/)/.test(newUrl)) {
      return `http${newUrl}`;
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
      return `http://${newUrl}`;
    }

    return newUrl;
  };

  const showIsRegistered = (item, index) => {
    const now = moment(new Date(item.date_time));
    const end = moment(new Date());
    const endTime = moment((item.end_time)).utc().format('hh:mm A');
    // const endTime = moment(item.end_time);
    const duration = moment.duration(now.diff(end));
    const hours = duration.asHours();
    const startTime = moment((item.start_time)).utc().format('hh:mm A');;
    // const startTime = moment(new Date(item.start_time));
    const isJoinable = end.isBetween(
      startTime.subtract(30, "minutes"),
      endTime
    );
    const joiningLink = linkStartWith(item?.access_link);

    return (
      <React.Fragment>
        {isJoinable ? (
          <a
            href={joiningLink}
            onClick={(event) => {
              event.preventDefault();
            }}
            target="_blank"
            className="btn register_btn mr-3 w-100"
          >
            Join Now
          </a>
        ) : (
          <a
            id={index}
            style={{ color: "white" }}
            className="btn btn-dark mr-3 register_btn w-100 disabled"
          >
            Registered
          </a>
        )}
      </React.Fragment>
    );
  };
  const callback = (key) => {
    if (key == 1) {
      setEventWorkShopList(futureEvents);
    }
    if (key == 2) {
      setEventWorkShopList(pastEvents);
    }
  };

  const renderRegisterButton = (item, index) => {
    if (item.event_type === "event") {
      const startTime = moment(new Date(item.start_time));
      const startDateTime = moment(new Date(item.date_time));
      const currentDateTime = moment(new Date());

      const startDate = moment(item.date_time).format("YYYY-MM-DD");
      const end = moment(new Date());
      const endTime = moment(item.end_time);
      const isJoinable = end.isBetween(
        startTime.subtract(30, "minutes"),
        endTime
      );
      const joiningLink = linkStartWith(item?.access_link);

      if (isJoinable && startDate == moment().format("YYYY-MM-DD")) {
        return (
          <div className={`${style.wrapper} ${style.button}`}>
            <a
              href={joiningLink}
              target="_blank"
              className={`${style.joinNow} btn register_btn mr-3 w-100`}
            >
              Join Now
            </a>
          </div>
        );
      } else if (currentDateTime.isBefore(startDateTime)) {
        return (
          <button id={index} className="btn register_btn mr-3 w-100" disabled>
            Yet to start
          </button>
        );
      } else {
        return (
          <button id={index} className="btn register_btn mr-3 w-100" disabled>
            Closed
          </button>
        );
      }
    } else {
      return null;
    }
  };

  const renderEventFooter = (index, item) => {
    if (item.event_type === "event") {
      return (
        <div className="card-footer">
          <div className="d-flex">
            {item.isRegistered ? (
              showIsRegistered(item, index)
            ) : (
              <React.Fragment>
                {renderRegisterButton(item, index)}
                {/* <RegistrationConfirmation
                  id={item._id}
                  confirm={() => this.registerForEvent(item._id, index, item.accessLink)}
                ></RegistrationConfirmation> */}
              </React.Fragment>
            )}
            {/* {item.isRegistered ? (
              <i
                id={"icon-" + item._id}
                className="mdi mdi-bookmark mdi-24px d-flex bookmark"
              ></i>
            ) : (
              <React.Fragment>
                {item.wishlisted ? (
                  <i
                    id={"icon-" + item._id}
                    style={{ color: "blue" }}
                    className="mdi mdi-bookmark mdi-24px d-flex bookmark"
                  ></i>
                ) : (
                  <i
                    id={"icon-" + item._id}
                    className="mdi mdi-bookmark mdi-24px d-flex bookmark cursor_pointer"
                    // onClick={() => this.changeIconColor('icon-' + item._id, item._id)}
                  ></i>
                )}
              </React.Fragment>
            )} */}
          </div>
        </div>
      );
    } else {
      return (
        <div className="card-footer">
          <a
            className="btn register_btn mr-3 w-100"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              registerEvent(item.id);
            }}
          >
            Register
          </a>
        </div>
      );
    }
  };

  const logEvents = async (data) => {
    console.log(data)
    try {
      let result = await UPDATEDPOST(`log`, data)
    } catch(e) {
      console.log(e, "ERRORRR")
    }
  }

  // API Calls
  const getAllEventAndWorkShops = async () => {
    

    try {
      const futureEvent = [];
      const pastEvent = [];
      const eventData = await UPDATEDGET(`events`);
      let rawLogs = {
        "userIdentity": {
        "loggedinuser": `${userData?.Email}`,
        "role":`${userData?.role}`,
        "createdate":new Date().toISOString()
        },
        "action": "ACCESS",
        "source": " View Event Work Shops Page",
         
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `events`,
        "requestParameters": {
          
        },
        "responsestatus": `${eventData?.status}`,
        // "response": JSON.stringify(response?.data)
      } 
  
      let logbody = {
        // message: `User - ${userData?.sub} - Fetch Events - View Event Work  Page`,
        message: rawLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - View Event Work Shops Page`,
      }
      logEvents(logbody);
      const {
        data: { body },
      } = eventData;
      console.log(eventData, "eventData in get api ")
      console.log(body, "eventData in get api ")
      const events =
        body.length &&
        body.filter((item) => {
          return item.event_type === "event";
        });

      events &&
        events.length &&
        events.forEach((event) => {
          // const compareDatee = moment(event.date_time).format("DD-MM-YYYY");
          const compareDatee = moment(event.date_time,"YYYY-MM-DDTHH:mm:ss.SSSS").format("DD-MM-YYYY HH:mm");
          const compareTodayDate = moment().format("DD-MM-YYYY HH:mm");

          const todayDate = moment(compareTodayDate, "DD-MM-YYYY HH:mm");
          const pastDate = moment(compareDatee, "DD-MM-YYYY HH:mm");
          const dDiff = pastDate.diff(todayDate);

          if (dDiff >= 0) {
            console.log('Date is not past');
            futureEvent.push(event);
          } else {
            pastEvent.push(event);
          }
          // if (
          //   moment(moment(event.date_time).format("YYYY-MM-DD")).isAfter(
          //     moment().format("YYYY-MM-DD")
          //   )
          // ) {
          //   futureEvent.push(event);
          // } else {
          //   pastEvent.push(event);
          // }
        });
      setFutureEvents(futureEvent);
      setPastEvents(pastEvent);

      setEventWorkShopList(futureEvent);
    } catch (error) {
      console.log(error);
    }
  };

  const registerEvent = async (eventId) => {
    try {
      const requestBody = {
        user_id: user.id,
        created_by: user.id,
        status: true,
        event_id: eventId,
        type: "registration",
      };
      await UPDATEDPOST(`eventregistrations`, requestBody).then((data) => {
        if (data.data.statusCode !== 200) {
          notification.error({
            message: data.data.body,
          });
        } else {
          notification.success({
            message: "Registered Successfully",
          });
        }
      });
    } catch (error) {
      notification.error({
        message: "Something went wrong please try again",
      });
    }
  };

  const allowedUserToAttendEvent = (item) => {
    if (item.event_access === "public") {
      return true;
    }

    if (
      item.event_access.toLowerCase().toString() ===
      user.role.toLowerCase().toString()
    ) {
      return true;
    }

    if (item.event_access == "clinic" && item.clinic_id == userInfo.CompanyID) {
      return true;
    }
  };

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const handleOk = () => {
    setEventModalVisisble(false);
    setParticularEvent({});
  };

  const handleCancel = () => {
    setEventModalVisisble(false);
    setParticularEvent({});
  };
  const handleLinkClick = async (file) => {
   console.log("anchar tag ma click thayu",file)
   const url = await s3GetPrivate(file)

   const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
       if (newWindow) newWindow.opener = null
  };
  useEffect(() => {
    dispatch(setSelectedFamilyMember({}));
    dispatch(AppointmentRequestType("Own"));

    getAllEventAndWorkShops();
  }, []);

  return (
    <div className="pb-5">
      <TopBar />
      {/* <div className="box">
        <p className="pageInfo">Home / Event</p>
        <h5 className="h5New">Event</h5>
      </div> */}

      <div className="box">
        <div className={`${style.toggebtn} d-block d-xl-none`}>
          <i>
            <FontAwesomeIcon
              className="mr-1"
              icon={faEquals}
              onClick={showDrawer}
            />
          </i>
        </div>
        <div className="d-block d-xl-none">
          <p className="pageInfoM">Event</p>
          <h5 className="h5NewM"> Dashboard / Event</h5>
        </div>
        <div className="d-none d-xl-block">
          <p className="pageInfo">Event</p>
          <h5 className="h5New">
            {" "}
            <Link style={{ color: "white" }} to="/dashboard">
              Dashboard
            </Link>{" "}
            / Event
          </h5>
        </div>
      </div>
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div
              className={`col-lg-4 col-xl-2 col-sm-12 d-none d-xl-block theiaStickySidebar `}
              style={{ height: "81vh" }}
            >
              <DashboardSidebar />
            </div>

            <Drawer
              closable={false}
              width={300}
              placement="left"
              onClose={onClose}
              visible={visible}
              style={{ paddingTop: 0 }}
            >
              <div className="widget-profile pro-widget-content pt-0 pb-4">
                <div className="profile-info-widget">
                  <img src="../Images/logo.png" height="50" alt="" />
                </div>
              </div>
              <DashboardSidebar />
            </Drawer>
            <div className="col-md-12 col-lg-8 col-xl-10">
              <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Upcoming Event" key="1">
                  <Row>
                    <Col span={30}>
                      <div className="col-12">
                        <div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="">
                                <div
                                  className={`${style.dashboard_eventcard} card`}
                                >
                                  <div className="card-body">
                                    <h3
                                      className="mb-3"
                                      style={{ textAlign: "center" }}
                                    >
                                      Upcoming Events
                                    </h3>
                                    <div className={`${style.groupofcard} row`}>
                                      {eventWorkShopList?.length
                                        ? eventWorkShopList.map(
                                          (item, index) => {
                                            if (
                                              allowedUserToAttendEvent(item)
                                            ) {
                                              return (
                                                <React.Fragment key={index}>
                                                  <div className="col-md-6 col-sm-12 col-lg-4 mb-4">
                                                    <div className="card eventcard">
                                                      {renderBanner(
                                                        index,
                                                        item
                                                      )}
                                                      <div
                                                        className={
                                                          style.datetimebox
                                                        }
                                                      >
                                                        <div
                                                          className={
                                                            style.date
                                                          }
                                                        >
                                                          {moment(
                                                            item.date_time
                                                          ).format(
                                                            "dddd, MMM Do YYYY"
                                                          )}
                                                        </div>
                                                        |
                                                        <div className="times">
                                                          {moment(
                                                            item.start_time
                                                          ).format(
                                                            "hh:mm A"
                                                          )}{" "}
                                                          -{" "}
                                                          {moment(
                                                            item.end_time
                                                          ).format("hh:mm A")}
                                                        </div>
                                                      </div>
                                                      {/* <img className="card-img-top" src="..." alt="Card image cap" /> */}
                                                      <div className="card-body">
                                                        <div
                                                          className={`${style.description} card-text text-dark`}
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              item.description,
                                                          }}
                                                        ></div>
                                                        <div
                                                          className={`${style.event_location}`}
                                                        >
                                                          <div className="d-flex mr-3 align-items-start">
                                                            <div className="mr-1 mtminus">
                                                              <i className="mdi mdi-map-marker font20"></i>
                                                            </div>
                                                            <div
                                                              className={
                                                                style.locations
                                                              }
                                                            >
                                                              {item.location ||
                                                                "Online"}
                                                            </div>
                                                          </div>
                                                          {item.pdf_link ? (
                                                            <div className="d-flex mt-2 align-items-start">
                                                              <a
                                                                // href={`${process.env.REACT_APP_ASSET_URL}/${item.pdf_link}`}
                                                                // rel="noopener noreferrer"
                                                                // className="btn-text more_info"
                                                                // target="_blank"
                                                                onClick={() => handleLinkClick(item.pdf_link)}
                                                              >
                                                                More Info
                                                              </a>
                                                            </div>
                                                          ) : null}
                                                          {item.recurrence ? (
                                                            <div className="d-flex mr-3 mt-2 ml-1">
                                                              <div className="card-title mr-1">
                                                                <i className="mdi mdi-alarm"></i>
                                                              </div>
                                                              <div>
                                                                Held:{" "}
                                                                <span className="text-capitalize">
                                                                  {
                                                                    item?.recurrence_frequency
                                                                  }
                                                                </span>
                                                              </div>
                                                            </div>
                                                          ) : null}
                                                        </div>
                                                      </div>
                                                      {item.event_type ===
                                                        "workshop" ? (
                                                        <div className="[ info-card-details ] animate">
                                                          <div className="[ info-card-detail ]">
                                                            <div className="details">
                                                              {item.isRegistered &&
                                                                item.is_active !=
                                                                1 && (
                                                                  <div className="">
                                                                    <i
                                                                      className={`${style.deleteIcon} mdi mdi-delete  cursor-pointer `}
                                                                      style={{
                                                                        right:
                                                                          "-8px",
                                                                        color:
                                                                          "red",
                                                                      }}
                                                                    // onClick={() =>
                                                                    //   document
                                                                    //     .getElementById('deleteConfirmationButton')
                                                                    //     .click()
                                                                    // }
                                                                    ></i>
                                                                    {/* <DeleteConfirmation
                                            id={'deleteConfirmationButton'}
                                            confirm={() => this.deleteRegistered(item._id)}
                                          ></DeleteConfirmation> */}
                                                                  </div>
                                                                )}
                                                              {item.wishlisted &&
                                                                item.isActive !=
                                                                1 && (
                                                                  <div className="">
                                                                    <i
                                                                      className={`${style.deleteIcon} mdi mdi-delete  cursor-pointer `}
                                                                      style={{
                                                                        right:
                                                                          "-8px",
                                                                        color:
                                                                          "red",
                                                                      }}
                                                                      onClick={() =>
                                                                        document
                                                                          .getElementById(
                                                                            "deleteConfirmationButton2"
                                                                          )
                                                                          .click()
                                                                      }
                                                                    ></i>{" "}
                                                                    {/* <DeleteConfirmation
                                            id={'deleteConfirmationButton2'}
                                            //confirm={() => this.deleteWishlisted(item._id)}
                                          ></DeleteConfirmation> */}
                                                                  </div>
                                                                )}

                                                              <b className="text-left pl-3">
                                                                What You Will
                                                                Learn:
                                                              </b>
                                                              <div
                                                                className="text-left pl-3"
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    item.what_will_you_learn,
                                                                }}
                                                              ></div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                      <h6 className="text-left pl-3 text-dark mb-3">
                                                        <strong className="pr-2"></strong>
                                                        <Tag
                                                          color="blue"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            setEventModalVisisble(
                                                              true
                                                            );
                                                            setParticularEvent(
                                                              item
                                                            );
                                                          }}
                                                        >
                                                          Click for More
                                                          Details!
                                                        </Tag>
                                                      </h6>
                                                      {renderEventFooter(
                                                        index,
                                                        item
                                                      )}
                                                    </div>
                                                  </div>
                                                </React.Fragment>
                                              );
                                            }
                                          }
                                        )
                                        : noEvent && (
                                          <div className="col-md-12 mt-4 mb-4">
                                            <div
                                              style={{
                                                color: "rgba(0,0,0,.5)",
                                              }}
                                              className="text-center"
                                            >
                                              <h3
                                                style={{
                                                  color: "rgba(0,0,0,.5)",
                                                }}
                                              >
                                                No upcoming events
                                              </h3>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Past Events" key="2">
                  <Row>
                    <Col span={24}>
                      <Row>
                        <Col span={30}>
                          <div className="col-12">
                            <div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="">
                                    <div
                                      className={`${style.dashboard_eventcard} card`}
                                    >
                                      <div className="card-body">
                                        <h3
                                          className="mb-3"
                                          style={{ textAlign: "center" }}
                                        >
                                          Past Events
                                        </h3>
                                        <div
                                          className={`${style.groupofcard} row`}
                                        >
                                          {eventWorkShopList?.length
                                            ? eventWorkShopList.map(
                                              (item, index) => {
                                                if (
                                                  allowedUserToAttendEvent(
                                                    item
                                                  )
                                                ) {
                                                  return (
                                                    <React.Fragment
                                                      key={index}
                                                    >
                                                      <div className="col-md-4 mb-4" >
                                                        <div className="card eventcard">
                                                          {renderBanner(
                                                            index,
                                                            item
                                                          )}
                                                          <div
                                                            className={
                                                              style.datetimebox
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                style.date
                                                              }
                                                            >
                                                              {moment(
                                                                item.date_time
                                                              ).format(
                                                                "dddd, MMM Do YYYY"
                                                              )}
                                                            </div>
                                                            |
                                                            <div className="times">
                                                              {moment(
                                                                item.start_time
                                                              ).format(
                                                                "hh:mm A"
                                                              )}{" "}
                                                              -{" "}
                                                              {moment(
                                                                item.end_time
                                                              ).format(
                                                                "hh:mm A"
                                                              )}
                                                            </div>
                                                          </div>
                                                          {/* <img className="card-img-top" src="..." alt="Card image cap" /> */}
                                                          <div className="card-body">
                                                            <div
                                                              className={`${style.description} card-text text-dark`}
                                                              dangerouslySetInnerHTML={{
                                                                __html:
                                                                  item.description,
                                                              }}
                                                            ></div>
                                                            <div
                                                              className={`${style.event_location}`}
                                                            >
                                                              <div className="d-flex mr-3 align-items-start">
                                                                <div className="mr-1 mtminus">
                                                                  <i className="mdi mdi-map-marker font20"></i>
                                                                </div>
                                                                <div
                                                                  className={
                                                                    style.locations
                                                                  }
                                                                >
                                                                  {item.location ||
                                                                    "Online"}
                                                                </div>
                                                              </div>
                                                              {item.pdf_link ? (
                                                                <div className="d-flex mt-2 align-items-start">
                                                                  <a
                                                                    // href={`${process.env.REACT_APP_ASSET_URL}/${item.pdf_link}`}
                                                                    // rel="noopener noreferrer"
                                                                    // className="btn-text more_info"
                                                                    // target="_blank"
                                                                    onClick={() => handleLinkClick(item.pdf_link)}
                                                                  >
                                                                    More Info
                                                                  </a>
                                                                </div>
                                                              ) : null}
                                                              {item.recurrence ? (
                                                                <div className="d-flex mr-3 mt-2 ml-1">
                                                                  <div className="card-title mr-1">
                                                                    <i className="mdi mdi-alarm"></i>
                                                                  </div>
                                                                  <div>
                                                                    Held:{" "}
                                                                    <span className="text-capitalize">
                                                                      {
                                                                        item.recurrence_frequency
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              ) : null}
                                                            </div>
                                                          </div>
                                                          {item.event_type ===
                                                            "workshop" ? (
                                                            <div className="[ info-card-details ] animate">
                                                              <div className="[ info-card-detail ]">
                                                                <div className="details">
                                                                  {item.isRegistered &&
                                                                    item.is_active !=
                                                                    1 && (
                                                                      <div className="">
                                                                        <i
                                                                          className={`${style.deleteIcon} mdi mdi-delete  cursor-pointer `}
                                                                          style={{
                                                                            right:
                                                                              "-8px",
                                                                            color:
                                                                              "red",
                                                                          }}
                                                                        // onClick={() =>
                                                                        //   document
                                                                        //     .getElementById('deleteConfirmationButton')
                                                                        //     .click()
                                                                        // }
                                                                        ></i>
                                                                        {/* <DeleteConfirmation
                                            id={'deleteConfirmationButton'}
                                            confirm={() => this.deleteRegistered(item._id)}
                                          ></DeleteConfirmation> */}
                                                                      </div>
                                                                    )}
                                                                  {item.wishlisted &&
                                                                    item.isActive !=
                                                                    1 && (
                                                                      <div className="">
                                                                        <i
                                                                          className={`${style.deleteIcon} mdi mdi-delete  cursor-pointer `}
                                                                          style={{
                                                                            right:
                                                                              "-8px",
                                                                            color:
                                                                              "red",
                                                                          }}
                                                                          onClick={() =>
                                                                            document
                                                                              .getElementById(
                                                                                "deleteConfirmationButton2"
                                                                              )
                                                                              .click()
                                                                          }
                                                                        ></i>{" "}
                                                                        {/* <DeleteConfirmation
                                            id={'deleteConfirmationButton2'}
                                            //confirm={() => this.deleteWishlisted(item._id)}
                                          ></DeleteConfirmation> */}
                                                                      </div>
                                                                    )}

                                                                  <b className="text-left pl-3">
                                                                    What You
                                                                    Will
                                                                    Learn:
                                                                  </b>
                                                                  <div
                                                                    className="text-left pl-3"
                                                                    dangerouslySetInnerHTML={{
                                                                      __html:
                                                                        item.what_will_you_learn,
                                                                    }}
                                                                  ></div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : null}
                                                          <h6 className="text-left pl-3 text-dark mb-3">
                                                            <strong className="pr-2"></strong>
                                                            <Tag
                                                              color="blue"
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setEventModalVisisble(
                                                                  true
                                                                );
                                                                setParticularEvent(
                                                                  item
                                                                );
                                                              }}
                                                            >
                                                              Click for More
                                                              Details!
                                                            </Tag>
                                                          </h6>
                                                          {renderEventFooter(
                                                            index,
                                                            item
                                                          )}
                                                        </div>
                                                      </div>
                                                    </React.Fragment>
                                                  );
                                                }
                                              }
                                            )
                                            : noEvent && (
                                              <div className="col-md-12 mt-4 mb-4">
                                                <div
                                                  style={{
                                                    color: "rgba(0,0,0,.5)",
                                                  }}
                                                  className="text-center"
                                                >
                                                  <h3
                                                    style={{
                                                      color: "rgba(0,0,0,.5)",
                                                    }}
                                                  >
                                                    No upcoming events
                                                  </h3>
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Event Information"
        visible={eventModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={900}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            Ok
          </Button>,
        ]}
      >
        <div className="row">
          <div className="col-md-6">
            <div className="listinfo">
              <strong>Event Name :</strong>
              {particluarEvent?.name}
            </div>
          </div>
          <div className="col-md-2">
            <div className="listinfo">
              <strong>Date :</strong>
            </div>
              {/* {moment(particluarEvent?.start_time).utc().format("ll")}{} */}
              {moment(particluarEvent?.date_time).format("MMM D, YYYY")}
          </div>
          <div className="col-md-2">
            <div className="listinfo">
              <strong>Start Time :</strong>
              {moment(particluarEvent?.start_time).format("hh:mm A")}
            </div>
          </div>
          <div className="col-md-2">
            <div className="listinfo">
              <strong>End Time :</strong>
              {moment(particluarEvent?.end_time).format("hh:mm A")}
            </div>
          </div>
          <div className="col-md-12">
            {/* <div className="listinfo">
              <strong>Workshop Name :</strong>
              {particluarEvent?.name}
            </div> */}
          </div>
          <div className="col-md-12">
            <div className="listinfo">
              <strong>Description :</strong>
              <span
                dangerouslySetInnerHTML={{
                  __html: particluarEvent?.description,
                }}
              ></span>
            </div>
          </div>
          {/* <div className="col-md-12">
            <div className="listinfo">
              <strong>What will you learn :</strong>
              <span
                dangerouslySetInnerHTML={{
                  __html: particluarEvent?.what_will_you_learn,
                }}
              ></span>
            </div>
          </div> */}
          <div className="col-md-12">
            {/* <div className="listinfo">
              <strong>Recurrance :</strong>
              {particluarEvent?.recurrence_frequency?.toUpperCase() || ""}
            </div> */}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default connect()(ViewEventWorkShops);