import { TopBar } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEquals,
  faMapMarked,
  faMapMarkedAlt,
  faMapPin,
  faMapSigns,
  faMarker,
} from "@fortawesome/free-solid-svg-icons";
import DashboardSidebar from "../sidebar/sidebar";
import style from "./style.module.scss";
import { useEffect, useState } from "react";
import IMG01 from "../../../images/user.png";
// import SearchLocationInput from "../searchAddress/index";
import SearchLocationInput from "../../../components/searchAddress/index";
import { UPDATEDGET, UPDATEDPOST } from "../../../services/common.api";
import Footer from "components/footerFix";
import CountrysJson from "../../../jsonFiles/country.json";
import { find } from "lodash";
import pharmacyListStaticArray from "../../../components/addPrescription/pharmacyList.json";

import {
  Badge,
  Button,
  Cascader,
  Drawer,
  Form,
  Input,
  notification,
  Popconfirm,
  Rate,
  Select,
  Space,
  Spin,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory, Link } from "react-router-dom";
import { PanoramaFishEyeOutlined } from "@material-ui/icons";

import DatePicker from "react-datepicker";
import {
  AppointmentRequestType,
  setSelectedFamilyMember,
} from "redux/actions/userActions";
import MaskInput from "react-maskinput/lib";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "../../../graphql/subscriptions";
import { MessageOutlined } from "@ant-design/icons";
import { RINGER } from "../img";
import PageHeader from "components/pageHeader";
import { Card } from "react-bootstrap";
import { isMobile, isTablet, isBrowser } from 'react-device-detect';


const ViewProfile = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { Option } = Select;
  let config = useSelector((state: any) => state.AppConfig.config);
  const [selectCountry, setSelectCountry] = useState({} as any);
  const [countryJson, setCountryJson] = useState({} as any);
  const dispatch = useDispatch();
  const { Text } = Typography;
  const [familyDoc, setFamilyDoc] = useState({} as any);
  const [address, setAddress] = useState("");
  const [, setLat] = useState(null);
  const [, setLng] = useState(null);
  const [insuranceData, setInsuranceData] = useState([] as any);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [faxNumber, setFaxNumber] = useState("");
  const [loader, setLoader] = useState(false);

  const userData = useSelector((state: any) => state.userReducer.user);
  const [subscription, setSubscription] = useState(null);
  const [pharmacyList, setPharmacyList] = useState([]);
  const [selPharmacy, setSelPharmacy] = useState<any>("");

  const [userAgent, setUserAgent] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent;
    console.log('YSYSYSYSYSY',userAgent);
    setUserAgent(userAgent);
    }, []);

  // let config = useSelector((state: any) => state.AppConfig.config);
  // const audio = new Audio('https://onrx-dev-portal.s3.ca-central-1.amazonaws.com/message.mp3');
  // audio.crossOrigin="anonymous"

  const playAudio = () => {
    const audio = new Audio();
    // audio.crossOrigin = "anonymous";
    audio.src = "https://www.soundjay.com/phone/sounds/telephone-ring-01a.mp3";
    audio.play().catch((error) => {
      // Handle any playback errors
      console.log(error, "audio play error");
    });
  };

  useEffect(() => {
    if (userData?.id) {
      const subscribeToNotifications = async () => {
        if (subscription && subscription._cleanup !== undefined) {
          // If a subscription already exists, no need to create a new one
          return;
        }

        const newSubscription = (
          API.graphql(
            graphqlOperation(subscriptions.subscribeToNewNotification, {
              userId: userData?.id + "",
            })
          ) as any
        ).subscribe({
          next: (notificationData) => {
            const newNotification =
              notificationData.value.data.subscribeToNewNotification;
            console.log("New notification received:", newNotification);

            console.log(
              localStorage.getItem("chatHead"),
              "localStorage.getItem",
              newNotification?.conversationId
            );
            if (
              parseInt(newNotification?.conversationId) ==
              parseInt(localStorage.getItem("chatHead"))
            ) {
            } else {
              notification.open({
                message: "New Message",
                description: newNotification?.message,
                duration: 5,
                icon: <MessageOutlined style={{ color: "#1890ff" }} />,
                placement: "topRight",
              });
              // audio.play();
              playAudio();
            }
          },
          error: (error) => {
            console.error("Error subscribing to new notification:", error);
          },
        });

        setSubscription(newSubscription);
      };

      subscribeToNotifications();

      return () => {
        if (subscription) {
          subscription?.unsubscribe();
          setSubscription(null);
        }
      };
    }
  }, [subscription, userData?.id]);

  const getSelectdFamilyDoctor = async () => {

    

    try {
      const familyDoctor = await UPDATEDGET(`patient/familydoc/${userData.id}`);
      console.log(familyDoctor.data.body, "Family Doctor data");

      let rawLogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role":`${userData?.role}`,
          "createdate":new Date().toISOString()
          },
          "action": "ACCESS",
          "source": "View Profile",
           
          "userAgent": userAgent,
          "ipAddress": localStorage.getItem('ipAddress'),
          "apiendpoint": `patient/familydoc/${userData.id}`,
          "requestParameters": {
            
          },
          "responsestatus": `${familyDoctor?.status}`,
          // "response": JSON.stringify(response?.data)
      }
  
      let logBody = {
        // message: `User - ${userData.id} (${userData?.sub}) - Fetch Family Doctor - View Profile Page`,
        message: rawLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - View Profile`,
      }
  
      logEvents(logBody);

      setFamilyDoc(familyDoctor?.data?.body?.CompanyEmployee?.CompanyEmployee);
      console.log(
        familyDoctor?.data?.body?.CompanyEmployee?.CompanyEmployee.id,
        "iddididididiididididid"
      );
      // setSelectFamilyMemberID(familyDoctor?.data?.body?.CompanyEmployee?.CompanyEmployee.id);
    } catch (error) {
      console.log(error);
    }
  };

  const logEvents = async (data) => {
    console.log(data)
    try {
      let result = await UPDATEDPOST(`log`, data)
    } catch(e) {
      console.log(e, "ERRORRR")
    }
  }

  useEffect(() => {

    dispatch(setSelectedFamilyMember({}));
    dispatch(AppointmentRequestType("Own"));

    window.scrollTo(0, 0);
    getSelectdFamilyDoctor();
    console.log(userData, "profile view");
    console.log(familyDoc, "family Doctor********");
    getInsuranceInfo();

    setCountryJson(CountrysJson);
    let G = config?.country;
    var obj = CountrysJson?.countries?.filter((obj) => obj.country === G);
    console.log(obj, "call");
    setSelectCountry(obj[0]);
    getPharmacyList();
  }, []);

  const goEditProfile = () => {
    history.push("profile");
  };

  function onChangeCountry(value) {
    console.log(`selected ${value}`);
    console.log(CountrysJson);

    var obj = countryJson?.countries?.filter((obj) => obj.country == value);
    console.log(obj[0]);
    setSelectCountry(obj[0]);
  }

  const [sidebar, setSidebar] = useState(false);
  const [selectedDate, SetSelectedDate] = useState(new Date());
  const showSidebar = () => setSidebar(!sidebar);

  // const claimColumns = [
  //     // {
  //     //   title: 'Claim Id',
  //     //   dataIndex: 'claimid',
  //     //   key: 'no',
  //     //   fixed: 'center',
  //     //   width: '50'
  //     // },
  //     {
  //         title: 'Claim date',
  //         dataIndex: 'claimDate',
  //         key: 'no',
  //         fixed: 'center',
  //     },
  //     {
  //         title: 'Consultig Doctor',
  //         dataIndex: 'consultingdoctor',
  //         key: 'no',
  //         fixed: 'center',
  //     },
  //     {
  //         title: 'Cost Amount',
  //         dataIndex: 'costamount',
  //         key: 'no',
  //         fixed: 'center',
  //     },
  //     {
  //         title: 'Paid  Amount',
  //         dataIndex: 'paidamount',
  //         key: 'no',
  //         fixed: 'center',
  //     },
  //     {
  //         title: 'Payment Method',
  //         dataIndex: 'paymentStatus',
  //         key: 'no',
  //         fixed: 'center',
  //     },
  //     {
  //         title: 'Action',
  //         key: 'operation',
  //         // fixed: 'left',
  //         fixed: 'right',
  //         render: () => (
  //             <div>
  //                 <PanoramaFishEyeOutlined className="ml-3 mb-3 font-size-24" />
  //             </div>
  //         ),
  //     },
  // ]

  // const options = [
  //     {
  //         value: 'all',
  //         label: 'all',
  //     },
  // ]

  function onChange(value) {
    console.log(value);
  }

  const dataSource = [
    {
      key: "1",
      Claimdate: "03-jan-2022",
      ConsultigDoctor: "John Dow",
      CostAmount: "1500",
      PaidAmount: "2500",
      PaymentMethod: "Pay",
    },
    {
      key: "1",
      Claimdate: "03-jan-2022",
      ConsultigDoctor: "John Dow",
      CostAmount: "1500",
      PaidAmount: "2500",
      PaymentMethod: "Pay",
    },
    {
      key: "1",
      Claimdate: "03-jan-2022",
      ConsultigDoctor: "John Dow",
      CostAmount: "1500",
      PaidAmount: "2500",
      PaymentMethod: "Pay",
    },
    {
      key: "1",
      Claimdate: "03-jan-2022",
      ConsultigDoctor: "John Dow",
      CostAmount: "1500",
      PaidAmount: "2500",
      PaymentMethod: "Pay",
    },
    {
      key: "1",
      Claimdate: "03-jan-2022",
      ConsultigDoctor: "John Dow",
      CostAmount: "1500",
      PaidAmount: "2500",
      PaymentMethod: "Pay",
    },
    {
      key: "1",
      Claimdate: "03-jan-2022",
      ConsultigDoctor: "John Dow",
      CostAmount: "1500",
      PaidAmount: "2500",
      PaymentMethod: "Pay",
    },
  ];

  const columns = [
    {
      title: "Claim date",
      dataIndex: "Claimdate",
      key: "Claimdate",
    },
    {
      title: "Consultig Doctor",
      dataIndex: "ConsultigDoctor",
      key: "ConsultigDoctor",
    },
    {
      title: "Cost Amount",
      dataIndex: "CostAmount",
      key: "CostAmount",
    },
    {
      title: "Paid Amount",
      dataIndex: "PaidAmount",
      key: "PaidAmount",
    },
    {
      title: "Payment Method",
      dataIndex: "PaymentMethod",
      key: "PaymentMethod",
    },
  ];

  const InsuranceColumns = [
    {
      title: "Name of insurer",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text?.charAt(0).toUpperCase() + text?.slice(1)}</a>,
    },
    {
      title: "Type",
      dataIndex: "insurance_type",
      key: "insurance_type",
    },
    {
      title: "Numbers on card",
      dataIndex: "insurance_number",
      key: "insurance_number",
    },
    {
      title: "view",
      dataIndex: "view",
      key: "view",
      render: () => (

        <img src="Images/noteicon.png" alt="View note icon" />
      )
    },
    // {
    //     title: 'Version code',
    //     dataIndex: 'address',
    //     key: 'address',
    // },

    // {
    //     title: 'Action',
    //     key: 'action',
    //     render: (text, record) => (
    //         <Space size="middle">
    //             <Tooltip title="Delete" placement="bottom">
    //                 <Popconfirm
    //                     title="Are you sure?"
    //                     onConfirm={confirm}
    //                     onCancel={cancel}
    //                     okText="Yes"
    //                     cancelText="No"
    //                 >
    //                     <Button shape="circle" danger icon={<DeleteFilled />} />
    //                 </Popconfirm>
    //             </Tooltip>
    //         </Space>
    //     ),
    // },
  ];

  const data = [
    {
      key: "1",
      name: "1",
      age: 321234678,
      address: "123456789",
    },
  ];

  const getInsuranceInfo = async () => {

    


    try {
      const res = await UPDATEDGET(`insurance/${userData.id}`);
      console.log(res?.data, "getInsuranceInfo");

      let rawLogs = {
        "userIdentity": {
        "loggedinuser": `${userData?.Email}`,
        "role":`${userData?.role}`,
        "createdate":new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "View Profile",
         
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `insurance/${userData.id}`,
        "requestParameters": {
          
        },
        "responsestatus": `${res?.status}`,
        // "response": JSON.stringify(response?.data)
      }
  
      let logBody = {
        // message: `User - ${userData.id} (${userData?.sub}) - Fetch Insurance Detail - View Profile Page`,
        message: rawLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - View Profile`,
      }
  
      logEvents(logBody);

      setInsuranceData(res?.data);
    } catch (err) {
      console.log("error", err);
    }
  };

  const onFinish = async (values) => {
    console.log(values);

    let data = {
      accreditationNumber: values?.accreditationNumber,
      address1: address,
      address2: null,
      address: null,
      city: values?.City,
      faxNumber: values?.faxNumber.replaceAll("-", ""),
      pharmacyName: values?.PharmacyName,
      phoneNumber: values?.phoneNumber.replaceAll("-", ""),
      postalCode: values?.PostalCode,
      createDate: new Date(),
      createUserId: userData.id,
      accreditation_number: null,
    };

    console.log(data);
    // try {
    //   setLoader(true);
    //   POST("/pharmacy", data).then((response) => {
    //     setLoader(false);
    //     notification.success({
    //       message: 'Pharmacy has been saved successfully.',
    //     })
    //     form.resetFields();
    //     setAddress("")
    //   });
    // } catch (error) {
    //   setLoader(false);
    //   notification.error({
    //     message: 'Error while save Pharmacy.',
    //   })
    // }
  };

  const handleChange = (event) => {
    console.log(event, "event");
  };

  const addressSelect = (datee) => {
    console.log(datee, "eeeeeeee");
    setAddress(datee.formatted_address);

    if (datee && datee.address_components) {
      const city = find(datee.address_components, {
        types: ["locality"],
      });

      console.log(city.long_name, "city");
      if (city) {
        form.setFieldsValue({
          City: city?.long_name,
        });
      }

      const postal_code = find(datee.address_components, {
        types: ["postal_code"],
      });
      console.log(postal_code?.long_name, "postal_code");

      if (postal_code) {
        form.setFieldsValue({
          PostalCode: postal_code.long_name,
        });
      }
    }

    //  if (datee && datee.address_components) {
    //   const state = find(datee.address_components, {
    //     types: ['administrative_area_level_1'],
    //   })

    //   console.log(state?.long_name,"state");
    //   if (state) {
    //     form.setFieldsValue({
    //       province: state.long_name
    //     });
    //   }
    // }
  };
  const ClickToDayDate = () => {
    // let date = moment().format('YYYY-MM-DD')
    // SetSelectedDate(date);

    let date = new Date();

    SetSelectedDate(date);
  };

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const handleChangePhonenumber = (event) => {
    console.log(event.target.name, "jsdfhjsfhhdsfhfgjh ");
    console.log(event.target.value, "jsdfhjsfhhdsfhfgjh ");
    setPhoneNumber(event.target.value);
  };

  const handleChangeFaxNumber = (event) => {
    console.log(event.target.name, "jsdfhjsfhhdsfhfgjh ");
    console.log(event.target.value, "jsdfhjsfhhdsfhfgjh ");
    setFaxNumber(event.target.value);
  };


  const getPharmacyList = async () => {

    


    try {
      console.log(
        "pharmacyListStaticArray static ",
        pharmacyListStaticArray.pharmacyListStaticArray
      );

      const pharmacyListReponse = await UPDATEDGET(`company`);
      console.log(pharmacyListReponse, "response response");

      let rawLogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role":`${userData?.role}`,
          "createdate":new Date().toISOString()
          },
          "action": "ACCESS",
          "source": "View Profile",
           
          "userAgent": userAgent,
          "ipAddress": localStorage.getItem('ipAddress'),
          "apiendpoint": `company`,
          "requestParameters": {
            
          },
          "responsestatus": `${pharmacyListReponse?.status}`,
          // "response": JSON.stringify(response?.data)
      }
  
      let logBody = {
        // message: `User - ${userData.id} (${userData?.sub}) - Fetch Pharmacy List - View Profile Page`,
        message: rawLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - View Profile`,
      }
  
      logEvents(logBody);

      let tempArray = [];
      if (pharmacyListReponse?.data.length > 0) {
        pharmacyListReponse?.data?.forEach((element) => {
          if (element.type == "PHARMACY") {
            tempArray.push(element);
          }
        });
        const arr3 = tempArray.concat(
          pharmacyListStaticArray.pharmacyListStaticArray
        );

        arr3.forEach((element) => {
          if (element?.id == userData.preferred_pharmacy) {
            setSelPharmacy(element);
          }
        });

        setPharmacyList(arr3);
      } else {
        setPharmacyList(pharmacyListStaticArray.pharmacyListStaticArray);
      }
    } catch (error) {
      notification.error({
        message: error.message,
      });
    }
  };

  console.log("PharmacySelected--->", selPharmacy);



  return (
    <div>
      <TopBar onShow={showDrawer} />
      <div>
        <div
          className="content"
          style={{ backgroundColor: "#fff" }}
        >
          <div className={style.container} >
            <div className="row">
              <div
                className={`col-lg-4 col-xl-2 col-sm-12 d-none d-xl-block theiaStickySidebar pr-0`}
              >
                <DashboardSidebar />
              </div>
              <Drawer
                closable={false}
                width={300}
                placement="left"
                onClose={onClose}
                visible={visible}
                style={{ paddingTop: 0 }}
              >
                <div className="widget-profile pro-widget-content pt-0 pb-4">
                  <div className="profile-info-widget">
                    <img src="../Images/logo.png" height="50" alt="" />
                  </div>
                </div>
                <DashboardSidebar />
              </Drawer>

              {/* {sidebar ?
                                <div className={`d-block d-xl-none ${style.toggle}`}>
                                    <DashboardSidebar />
                                </div> : null
                            } */}
              <div className="col-lg-12 col-xl-10" style={{ height: "100%" }}>
                <PageHeader
                  items={[
                    { label: "Dashboard", link: "/dashboard" },
                    { label: "Profile", link: "" },
                  ]}
                  pageTitle="Profile"
                />
                <div className="row pb-5">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className={`${style.cardDiv} `}>
                      <div
                        className="row px-3 pb-3 pt-3"
                        style={{
                          borderBottom:
                            "1px solid var(--primary-light-border-color)",
                        }}
                      >
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <div className="d-flex justify-content-start align-items-center ">
                            {userData?.profilepicture != "" &&
                              userData?.profilepicture != null ? (
                              <img
                                className="mr-3 profpic"
                                src={`${process.env.REACT_APP_ASSET_URL}/${userData.profilepicture}`}
                                alt="User"
                              />
                            ) : (
                              <img
                                className="mr-3 profpic"
                                src="Images/profilePic.png"
                                alt="User"
                              />
                            )}

                            <div className="pl-2">
                              <div className="">
                                <span
                                  className="mr-auto fontColor"
                                  style={{
                                    margin: 0,
                                    fontSize: 20,
                                    color: "#000",
                                  }}
                                >
                                  {userData?.FirstName?.charAt(
                                    0
                                  ).toUpperCase() +
                                    userData?.FirstName?.slice(1)}{" "}
                                  {userData?.MiddleName !== null
                                    ? userData?.MiddleName?.charAt(
                                      0
                                    ).toUpperCase() +
                                    userData?.MiddleName?.slice(1)
                                    : ""}{" "}
                                  {userData?.LastName?.charAt(0).toUpperCase() +
                                    userData?.LastName?.slice(1)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <div className="d-flex h-100 justify-content-end align-items-center">
                            <span
                              onClick={() => goEditProfile()}
                              className={` ml-2 ${style.btnWithAddon}`}
                            >
                              {/* <img className="editIcon" style={{ cursor: 'pointer' }} src="Images/edit.png" alt="" /> */}
                              Edit
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row g-0 align-items-center"  >
                        <div
                          className={`col-md-4 col-sm-6 ${style.borderBottomRightCol}`}
                        >
                          <div className="p-4" style={{ fontSize: 16 }}>
                            <span>
                              {" "}
                              <strong> Primary Email *</strong>
                            </span>
                            <div>{userData?.Email}</div>
                          </div>
                        </div>
                        <div
                          className={`col-md-4 col-sm-6 ${style.borderBottomRightCol}`}
                          style={{
                            borderLeft:
                              "1px solid #CCCCCC",
                          }}>
                          <div className="p-4" style={{ fontSize: 16 }}>
                            <span>
                              <strong> Secondary Email </strong>
                            </span>
                            <div>
                              {userData?.Email2 ? userData?.Email2 : "N/A"}
                            </div>
                          </div>
                        </div>
                        <div
                          className={`col-md-4 col-sm-6 ${style.borderBottomRightCol}`}
                          style={{
                            borderLeft:
                              "1px solid #CCCCCC",
                          }}
                        >
                          <div className="p-4" style={{ fontSize: 16 }}>
                            <span>
                              {" "}
                              <strong>Phone Number * </strong>
                            </span>
                            <div>{userData?.phoneNumber}</div>
                          </div>
                        </div>
                        <div className={`d-block d-md-none col-sm-6  ${style.borderCol}`}></div>
                      </div>
                      <div className="row g-0  align-items-center"  >
                        <div
                          className={`col-md-4 col-sm-6 ${style.borderBottomRightCol}`}
                        >
                          <div className="p-4" style={{ fontSize: 16 }}>
                            <span>
                              {" "}
                              <strong> Date Of Birth *</strong>
                            </span>
                            <div>{moment(userData?.DOB).utc().format("YYYY-MM-DD")}</div>
                          </div>
                        </div>
                        <div
                          className={`col-md-8 col-sm-6 ${style.borderBottomRightCol}`}
                          style={{
                            borderLeft:
                              "1px solid #CCCCCC",
                          }}
                        >
                          <div className="p-4" style={{ fontSize: 16 }}>
                            <span>
                              <strong> Gender * </strong>
                            </span>
                            <div>{userData?.gender}</div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-0  align-items-center"  >
                        <div
                          className={`col-md-8  col-sm-6 ${style.borderBottomRightCol}`}
                        >
                          <div className="p-4" style={{ fontSize: 16 }}>
                            <span>
                              {" "}
                              <strong> Address </strong>
                            </span>
                            <div>
                              {userData?.Address1 ? userData?.Address1 : ""}{" "}
                            </div>
                          </div>
                        </div>
                        <div
                          className={`col-md-4 col-sm-6 ${style.borderBottomRightCol}`}
                          style={{
                            borderLeft:
                              "1px solid #CCCCCC",
                          }}
                        >
                          <div className="p-4" style={{ fontSize: 16 }}>
                            <span>
                              <strong> Country </strong>
                            </span>
                            <div>{userData?.Country}</div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-0  align-items-center" >
                        <div
                          className={`col-md-4 col-sm-6 ${style.borderBottomRightCol}`}
                        >
                          <div className="p-4" style={{ fontSize: 16 }}>
                            <span>
                              {" "}
                              <strong> State/Province *</strong>
                            </span>
                            <div>{userData?.State}</div>
                          </div>
                        </div>
                        <div
                          className={`col-md-4 col-sm-6 ${style.borderBottomRightCol}`}
                          style={{
                            borderLeft:
                              "1px solid #CCCCCC",
                          }}
                        >
                          <div className="p-4" style={{ fontSize: 16 }}>
                            <span>
                              <strong> City </strong>
                            </span>
                            <div>{userData?.City}</div>
                          </div>
                        </div>
                        <div
                          className={`col-md-4 col-sm-6 ${style.borderBottomRightCol}`}
                          style={{
                            borderLeft:
                              "1px solid #CCCCCC",
                          }}
                        >
                          <div className="p-4" style={{ fontSize: 16 }}>
                            <span>
                              {" "}
                              <strong> Zip Code * </strong>
                            </span>
                            <div>{userData?.PostalCode}</div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div className="row mt-4 pb-3">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className={`${style.cardDiv2}`}>
                          <div className="row g-0 align-items-center">
                            <div
                              className="col-sm-3 col-md-3 col-lg-3 col-xl-3"
                              style={{ borderRight: "1px solid #CCCCCC" }}
                            >
                              <div className="tx-16 d-flex justify-content-start align-items-center p-3  ">
                                <img
                                  className="mr-4 ml-4"
                                  src="Images/pharmacy.png"
                                  alt="User"
                                />
                                <div className={style.h5Text}>Pharmacy</div>
                              </div>
                            </div>
                            <div className="col-sm-9 col-md-9 col-lg-9 col-lg-9 p-3">
                              <div className="d-flex justify-content-start align-items-center">
                                <div className={style.h5Text}>
                                  {selPharmacy ?
                                    selPharmacy?.name : "No Pharmacy"
                                  }
                                </div>
                                <div> &nbsp; | &nbsp;</div>
                                <div style={{ fontSize: 16 }}>
                                  {
                                    selPharmacy ?
                                      selPharmacy?.address : ""
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-3">
                        <div className={style.h5Text}>Insurance</div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <Table

                          columns={InsuranceColumns}
                          dataSource={insuranceData}
                          pagination={{ pageSize: 4 }}
                          rowKey={(obj) => obj.id}
                        />
                      </div>
                    </div>

                    {/* <Badge.Ribbon text={`${userData?.role}`}>
                    <div className="card card-top card-top-primary pb-1 mb-2">
                      <div className="card-body p-2">
                        <div className="media align-items-center">
                          <div
                            style={{
                              position: "relative",
                              backgroundColor: "white",
                            }}
                          >
                            {userData?.profilepicture != "" &&
                            userData?.profilepicture != null ? (
                              <img
                                className="mr-3 profpic"
                                src={`${process.env.REACT_APP_ASSET_URL}/${userData.profilepicture}`}
                                alt="User"
                              />
                            ) : (
                              <img
                                className="mr-3 profpic"
                                src={IMG01}
                                alt="User"
                              />
                            )}
                          </div>
                          <div className="media-body">
                            <div className="pl-2">
                              <div className="">
                                <span
                                  className="mr-auto fontColor"
                                  style={{
                                    margin: 0,
                                    fontSize: 15,
                                    color: "#134f85",
                                  }}
                                >
                                  {userData?.FirstName?.charAt(
                                    0
                                  ).toUpperCase() +
                                    userData?.FirstName?.slice(1)}{" "}
                                  {userData?.MiddleName !== null
                                    ? userData?.MiddleName?.charAt(
                                        0
                                      ).toUpperCase() +
                                      userData?.MiddleName?.slice(1)
                                    : ""}{" "}
                                  {userData?.LastName?.charAt(0).toUpperCase() +
                                    userData?.LastName?.slice(1)}
                                </span>
                              </div>
                              <div className="font-size-12 mb-1">
                                {userData?.Email}
                              </div>
                            </div>


                            <span
                              onClick={() => goEditProfile()}
                              className={`btn btn-primary ml-2 ${style.btnWithAddon}`}
                            >
                              Edit Profile
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Badge.Ribbon> */}
                  </div>

                  {/* <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5"> */}
                  {/* <div className="card pb-1 mb-3">
                                            <div className="card-body text-white rounded" style={{ backgroundColor: '#ffff' }}>
                                                <div>
                                                    <div className="d-flex mb-1">
                                                        <div className="text-uppercase mr-auto fontColor">Balance</div>
                                                        <div className="fontColor">TOTAL</div>
                                                    </div>
                                                    <div className="d-flex mb-2">
                                                        <div className="font-size-24 font-weight-bold mr-auto fontColor">{config?.currency_symbol} 3,000</div>
                                                        <div className="font-size-24 fontColor"> {config?.currency_symbol} 5,000</div>
                                                    </div>
                                                    <div className="progress">
                                                        <div
                                                            className="progress-bar fontColor"
                                                            style={{
                                                                width: '60%',
                                                                backgroundColor: '#194f85'
                                                            }}
                                                            role="progressbar"
                                                            aria-valuenow={60}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                  {/* </div> */}
                  {/* <div className="col-sm-12 col-md-0 col-lg-0 col-xl-2" /> */}
                </div>

                {/* <div className="card pb-1 mb-3" style={{ paddingBottom: 10 }}>
                <div className="card-body m-2">
                  <div className="row"> */}
                {/* <div className="col-md-4 col-sm-6">
                                                <div className="form-group">
                                                    <span>Name</span>
                                                    <div>
                                                        <strong>{userData?.FirstName} {userData?.MiddleName} {userData?.LastName}</strong>
                                                    </div>
                                                </div>
                                            </div> */}
                {/* <div className="col-md-4 col-sm-6">
                      <div className="form-group">
                        <span>Address</span>
                        <div>
                          <strong>
                            {userData?.Address1 ? userData?.Address1 : ""}{" "}
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="form-group">
                        <span>Email</span>
                        <div>
                          <strong> {userData?.Email}</strong>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="form-group">
                        <span>Phone</span>
                        <div>
                          <strong>{userData?.phoneNumber}</strong>
                        </div>
                      </div>
                    </div> */}
                {/* <div className="col-md-4 col-sm-6">
                                                <div className="form-group">
                                                    <span>Speciality</span>
                                                    <div>
                                                        <strong>{userData?.Speciality}</strong>
                                                    </div>
                                                </div>
                                            </div> */}
                {/* <div className="col-md-4 col-sm-6">
                      <div className="form-group">
                        <span>Languages</span>
                        <div>
                          <strong className="text-capitalize">
                            {userData?.Languages ? userData.Languages : ""}
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="form-group">
                        <span>City</span>
                        <div>
                          <strong>{userData?.City}</strong>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="form-group">
                        <span>State</span>
                        <div>
                          <strong>{userData?.State}</strong>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="form-group">
                        <span>Postal Code</span>
                        <div>
                          <strong>{userData?.PostalCode}</strong>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="form-group">
                        <span>Country</span>
                        <div>
                          <strong>{userData?.Country}</strong>
                        </div>
                      </div>
                    </div> */}
                {/* <div className="col-md-4 col-sm-6">
                                                <div className="form-group">
                                                    <span>Department</span>
                                                    <div>
                                                        <strong>{userData?.Department}</strong>
                                                    </div>
                                                </div>
                                            </div> */}
                {/* <div className="col-md-4 col-sm-6">
                                                <div className="form-group">
                                                    <span>Fax</span>
                                                    <div>
                                                        <strong>1234567890</strong>
                                                    </div>
                                                </div>
                                            </div> */}
                {/* </div>
                </div>
              </div> */}

                {/* <div className="col-lg-12 col-md-12 p-0 m-0"> */}
                {/* <div
                  className={`${style.Cardheader} card card-top card-top-primary pb-1 mb-3`}
                > */}
                {/* <div className="p-4"> */}
                {/* <Tabs
                      defaultActiveKey="1"
                      className={` ${style.tabsW} vb-tabs-bold`}
                    > */}
                {/* <Tabs.TabPane tab="Claim History" key="1">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-xl-9 col-lg-8 col-md-8">
                                                                <div className="row">
                                                                    <div
                                                                        className="col-xl-4 col-lg-4 col-md-4 col-sm-12 pr-0 pb-4"
                                                                        style={{
                                                                            width: '100%',
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'flex-start',
                                                                        }}
                                                                    >
                                                                        <Text className="pr-3">Filters: </Text>

                                                                        <DatePicker dateFormat="MM-dd-yyyy" selected={selectedDate} onChange={(date) => SetSelectedDate(date)} /> */}

                {/* {
                                                                            selectedDate == "" ?
                                                                                <DatePicker onChange={onChangeDate} style={{ width: '100%' }} /> :
                                                                                <DatePicker onChange={onChangeDate} value={moment(selectedDate, "YYYY-MM-DD")} style={{ width: '100%' }} />

                                                                        } */}

                {/* <DatePicker onChange={onChangeDate} defaultValue={moment(selectedDate, "YYYY-MM-DD")} style={{ width: '100%' }} /> */}
                {/* </div>
                                                                    <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12 pr-0 pb-4">
                                                                        <Button onClick={() => ClickToDayDate()}
                                                                            style={{ width: '100%', height: '100%' }}
                                                                        >
                                                                            <Text>Today</Text>
                                                                        </Button>
                                                                    </div> */}
                {/* <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 pr-0 pb-4">
                                                                        <Cascader
                                                                            options={options}
                                                                            onChange={onChange}
                                                                            placeholder="All"
                                                                            style={{ width: '100%' }}
                                                                        />
                                                                    </div> */}
                {/* <div className="col-xl-2 col-lg-0 col-md-0" />
                                                                </div>
                                                            </div>
                                                        </div> */}
                {/* <Table

                                                            className="text-center"
                                                            columns={columns}
                                                            dataSource={dataSource}
                                                            pagination={{ pageSize: 3 }}
                                                        /> */}
                {/* </div>
                                                </Tabs.TabPane> */}
                {/* <Tabs.TabPane tab="Insurance Provider" key="1">
                        <Table
                          columns={InsuranceColumns}
                          dataSource={insuranceData}
                          pagination={{ pageSize: 4 }}
                          rowKey={(obj) => obj.id}
                        />
                      </Tabs.TabPane> */}
                {/* <Tabs.TabPane tab="Pharmacy" key="2">
                          <Form
                            layout="vertical"
                            name="basic"
                            form={form}
                            onFinish={onFinish}
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="row">
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <Form.Item
                                        name="PharmacyName"
                                        label="Pharmacy Name"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Please Enter Pharmacy Name",
                                          },
                                        ]}
                                      >
                                        <input
                                          className={`${style.inputbox}`}
                                          placeholder="Pharmacy Name"
                                          name="PharmacyName"
                                          onChange={handleChange}
                                        />
                                      </Form.Item>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="form-group">
                                      <label> Address</label>
                                      <Form.Item>
                                        <SearchLocationInput
                                          styleProps={{ minHeight: 30 }}
                                          name="address"
                                          address={address}
                                          setlat={(e) => setLat(e)}
                                          setlng={(e) => setLng(e)}
                                          setAddress={(e) => addressSelect(e)}
                                          onBlur={(e) => setAddress(e)}
                                        />
                                      </Form.Item>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6">
                                    <div className="form-group">
                                      <Form.Item
                                        name="PostalCode"
                                        label="Zip Code"
                                        rules={[
                                          {
                                            required: true,
                                            message: "PostalCode is not valid!",
                                          },
                                        ]}
                                      >
                                        <input
                                          type="text"
                                          className={`${style.inputbox}`}
                                          name="PostalCode"
                                          onChange={handleChange}
                                        />
                                      </Form.Item>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className={`form-group `}>
                                      <Form.Item
                                        name="City"
                                        label="City"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Please Enter your City!",
                                          },
                                        ]}
                                      >
                                        <input
                                          type="text"
                                          className={`${style.inputbox}`}
                                          onChange={handleChange}
                                        />
                                      </Form.Item>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6">
                                    <div className={`form-group `}>
                                      <Form.Item
                                        name="faxNumber"
                                        label="Fax Number"
                                        rules={[
                                          {
                                            required: false,
                                            message: "Please Enter Fax Number!",
                                          },
                                          {
                                            max: 12,
                                            message:
                                              "must be maximum 12 characters.",
                                          },
                                        ]}
                                      >
                                        <MaskInput
                                          alwaysShowMask
                                          maskChar="_"
                                          onChange={handleChangeFaxNumber}
                                          mask="000-000-0000"
                                        />
                                      </Form.Item>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className={`form-group `}>
                                      <Form.Item
                                        name="phoneNumber"
                                        label="Phone Number"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Please Enter phone number!",
                                          },
                                          {
                                            max: 12,
                                            message:
                                              "must be maximum 12 characters.",
                                          },
                                        ]}
                                      >
                                        <MaskInput
                                          alwaysShowMask
                                          maskChar="_"
                                          onChange={handleChangePhonenumber}
                                          mask="000-000-0000"
                                        />
                                      </Form.Item>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6">
                                    <div className={`form-group `}>
                                      <Form.Item
                                        name="accreditationNumber"
                                        label="Accredition Number"
                                        rules={[
                                          {
                                            required: false,
                                            message:
                                              "Please Enter Accredition number!",
                                          },
                                          {
                                            max: 10,
                                            message:
                                              "must be maximum 10 characters.",
                                          },
                                        ]}
                                      >
                                        <input
                                          type="text"
                                          className={`${style.inputbox}`}
                                          onChange={handleChange}
                                        />
                                      </Form.Item>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  {loader ? (
                                    <div className="spinner">
                                      <Spin tip="Validating Pharmacy) ..."></Spin>
                                    </div>
                                  ) : (
                                    <button type="submit" className="btn">
                                      Submit
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Form>
                        </Tabs.TabPane> */}
                {/* </Tabs> */}
                {/* </div> */}
                {/* </div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default ViewProfile;
