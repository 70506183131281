import { useEffect, useState } from "react";
import Footer from "components/footer";
import { Radio, Steps, Spin, Popover } from 'antd';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProblem } from "../../redux/actions/userActions";
import style from "./style.module.css";
import crypto from 'crypto';

const SelectedType = () => {
    const { Step } = Steps;
    const dispatch = useDispatch();
    const [radioVal, setRadioVal] = useState("");
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [problems, setProblems] = useState([] as any);

    useEffect(() => {
        getProblems();
    }, []);

    const content = (res) => (
        <div style={{ width: 500 }}>
            <div dangerouslySetInnerHTML={{ __html: res }}></div>
        </div>
    );

    const getProblems = async () => {
        console.log("ok")
        // try {
        //     setLoader(true);
        //     const problemsData = await GET(`problems`);
        //     console.log(problemsData, 'DoctorProfile')
        //     setLoader(false);
        //     if (problemsData.data.statusCode == 200) {
        //         setProblems(problemsData.data.body)
        //         console.log(problems, "problemsssss")
        //     }
        // } catch (error) {
        //     console.log(error)
        //     setLoader(false);
        // }
    }
    // const continuee = () => {
    //     if (radioVal != "") {
    //         console.log(radioVal)
    //         localStorage.setItem("problem_id", radioVal);
    //         history.push("/appoiment-type");
    //     } else {
    //         notification.error({
    //             message: 'Error',
    //             duration: 2,
    //             description:
    //                 'Please Select Problem.',
    //             onClick: () => {
    //                 console.log('Notification Clicked!');
    //             },
    //         });
    //     }
    // };
    const goToBack = () => {
        history.goBack()
    };

    const onChangeRadio = e => {
        console.log('radio checked', e.target);
        setRadioVal(e.target.value)
        dispatch(setProblem(e.target.value));
    };

    return (
        <div>
            {/* <TopBar /> */}
            <div className="box">
                <p className="pageInfo">Problems</p>
                <h5 className="h5New">Dashboard / Select Problem</h5>
            </div>
            <div className="content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-sm-0 col-md-1 col-xl-2"></div>
                        <div className="col-sm-12 col-md-10 col-xl-8">
                            <div className="stepDiv d-none d-sm-block">
                                <Steps size="small">
                                    <Step status="finish" title="Payment" />
                                    <Step status="finish" title="Problems" />
                                    <Step status="wait" title="Select Type" />
                                    <Step status="wait" title="Select Doctor" />
                                    <Step status="wait" title="Select Slot" />
                                    <Step status="wait" title="Book Appointment" />
                                </Steps>
                            </div>
                        </div>
                        <div className="col-sm-0 col-md-1 col-xl-2"></div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-12">
                            {loader ? (
                                <div className="spinner">
                                    <Spin tip="Loading..."></Spin>
                                </div>
                            ) : (
                                <Radio.Group style={{ width: "100%" }} onChange={onChangeRadio}>
                                    <div className={`${style.cards} row text-left`}>
                                        <div className="col-sm-0 col-md-0 col-xl-0"></div>
                                        {problems.map((problem) => (
                                            <div className="col-sm-12 col-md-6 col-xl-6" key={crypto.randomBytes(16).toString('hex')}>
                                                <div className={style.card_problems}>
                                                    {
                                                        problem.details !== null ?
                                                            <Popover content={() => content(problem.details)} title="Details description" placement="bottom">
                                                                <img src="Images/problems/eye.png" alt="" className={style.iconEye} />
                                                            </Popover> : null
                                                    }

                                                    <div className={style.card_body_problems}>
                                                        <Radio value={problem.id}>
                                                            <div className="doctor-widget">
                                                                <div className="doc-info-left" style={{ paddingRight: 20 }}>
                                                                    {problem.iconurl != null ?
                                                                        <img src={problem.iconurl} alt="" className={style.imgeAllProblem} />
                                                                        :
                                                                        <img src="Images/problems/prescription.png" alt="" className={style.imgeAllProblem} />}
                                                                </div>
                                                                <div className={`${style.info} doc-left-info`}>
                                                                    <h4>{problem.problem}</h4>
                                                                    <h6>{problem.description}</h6>
                                                                </div>
                                                            </div>
                                                        </Radio>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="col-sm-0 col-md-0 col-xl-0"></div>
                                    </div>

                                    {/* <div className="row" style={{ width: "100%" }}> */}
                                    <div className="row text-left" style={{ paddingLeft: 80, paddingRight: 80, paddingBottom: 150 }}>

                                        {/* <div className="col-sm-12 col-md-6 col-xl-6" key={crypto.randomBytes(16).toString('hex')}> */}

                                        {/* <div className="col-sm-0 col-md-0 col-lg-2 col-xl-2 p-0 m-0"></div>
                                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 p-0 m-0"></div>
                                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 p-0 m-0">
                                    <div className="div_btn mt-3">
                                        <Button type="default" className="btnBg1 mr-3" onClick={goToBack}  >
                                            BACK
                                        </Button>
                                        <Button type="primary" className="btn" onClick={continuee}  >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                                <div className="col-sm-0 col-md-0 col-lg-2 col-xl-2"></div> */}
                                        {/* <div className="col-sm-0 col-md-0 col-xl-0"></div>
                                    <div className="col-sm-12 col-md-6 col-xl-6"></div>
                                    <div className="col-sm-12 col-md-6 col-xl-6">
                                        <div className="div_btn mt-3">
                                            <Button type="default" className="btnBg1 mr-3" onClick={goToBack}  >
                                                BACK
                                            </Button>
                                            <Button type="primary" className="btn" onClick={continuee}  >
                                                Continue
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="col-sm-0 col-md-0 col-xl-0"></div> */}
                                    </div>
                                </Radio.Group>
                            )}

                            {/* <Radio.Group style={{ width: "100%" }} onChange={onChangeRadio}>
                                <div className="row text-left">
                                    <div className="col-sm-0 col-md-0 col-xl-2"></div>
                                    <div className="col-sm-12 col-md-6 col-xl-4">
                                        <div className="card_problems">
                                            <div className="card-body_problems">
                                                <Radio value="Prescription Refill">
                                                    <div className="doctor-widget">
                                                        <div className="doc-info-left" style={{ paddingRight: 20 }}>
                                                            <img src="Images/problems/prescription.png" alt="" className="imgeAll" />
                                                        </div>
                                                        <div className="doc-info-right">
                                                            <h4>Prescription Refill</h4>
                                                            <h6>Short-term/emergency refills, No controlled substances (narcotics etc.)</h6>
                                                        </div>
                                                    </div>
                                                </Radio>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-xl-4">
                                        <div className="card_problems">
                                            <div className="card-body_problems">
                                                <Radio value="Illness/Infection">
                                                    <div className="doctor-widget">
                                                        <div className="doc-info-left" style={{ paddingRight: 20 }}>
                                                            <img src="Images/problems/infection.png" alt="" className="imgeAll" />
                                                        </div>
                                                        <div className="doc-info-right">
                                                            <h4>Illness/Infection</h4>
                                                            <h6>Short-term/emergency refills, No controlled substances (narcotics etc.)</h6>
                                                        </div>
                                                    </div>
                                                </Radio>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-sm-0 col-md-0 col-xl-2"></div>
                                </div>
                                <div className="row text-left">
                                    <div className="col-sm-0 col-md-0 col-xl-2"></div>
                                    <div className="col-sm-12 col-md-6 col-xl-4">
                                        <div className="card_problems">
                                            <div className="card-body_problems">
                                                <Radio value="Pain">
                                                    <div className="doctor-widget">
                                                        <div className="doc-info-left" style={{ paddingRight: 20 }}>
                                                            <img src="Images/problems/pain.png" alt="" className="imgeAll" />
                                                        </div>
                                                        <div className="doc-info-right">
                                                            <h4>Pain</h4>
                                                            <h6>Headache/Migraine, Joint pain, Other acute pain.</h6>
                                                        </div>
                                                    </div>
                                                </Radio>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-xl-4">
                                        <div className="card_problems">
                                            <div className="card-body_problems">
                                                <Radio value="Injury">
                                                    <div className="doctor-widget">
                                                        <div className="doc-info-left" style={{ paddingRight: 20 }}>
                                                            <img src="Images/problems/injury.png" alt="" className="imgeAll" />
                                                        </div>
                                                        <div className="doc-info-right">
                                                            <h4>Injury</h4>
                                                            <h6>Sprains, Bruises, Cuts, Burns.</h6>
                                                        </div>
                                                    </div>
                                                </Radio>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-0 col-md-0 col-xl-2"></div>
                                </div>
                                <div className="row text-left">
                                    <div className="col-sm-0 col-md-0 col-xl-2"></div>
                                    <div className="col-sm-12 col-md-6 col-xl-4">
                                        <div className="card_problems">
                                            <div className="card-body_problems">
                                                <Radio value="Allergies">
                                                    <div className="doctor-widget">
                                                        <div className="doc-info-left" style={{ paddingRight: 20 }}>
                                                            <img src="Images/problems/allergy.png" alt="" className="imgeAll" />
                                                        </div>
                                                        <div className="doc-info-right">
                                                            <h4>Allergies</h4>
                                                            <h6>Seasonal Allergies, Food/Other Allergies, Allergic Reactions/Hives.</h6>
                                                        </div>
                                                    </div>
                                                </Radio>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-xl-4">
                                        <div className="card_problems">
                                            <div className="card-body_problems">
                                                <Radio value="Skin Problems">
                                                    <div className="doctor-widget">
                                                        <div className="doc-info-left" style={{ paddingRight: 20 }}>
                                                            <img src="Images/problems/skin.png" alt="" className="imgeAll" />
                                                        </div>
                                                        <div className="doc-info-right">
                                                            <h4>Skin Problems</h4>
                                                            <h6>Rash, infection, Eczema</h6>
                                                        </div>
                                                    </div>
                                                </Radio>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-0 col-md-0 col-xl-2"></div>
                                </div>
                                <div className="row text-left">
                                    <div className="col-sm-0 col-md-0 col-xl-2"></div>
                                    <div className="col-sm-12 col-md-6 col-xl-4">
                                        <div className="card_problems">
                                            <div className="card-body_problems">
                                                <Radio value="Screenings">
                                                    <div className="doctor-widget">
                                                        <div className="doc-info-left" style={{ paddingRight: 20 }}>
                                                            <img src="Images/problems/screening.png" alt="" className="imgeAll" />
                                                        </div>
                                                        <div className="doc-info-right">
                                                            <h4>Screenings</h4>
                                                            <h6>Quick Assessment of Symptom Severity/Care Triage, COVID-19 Screenings.</h6>
                                                        </div>
                                                    </div>
                                                </Radio>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-xl-4">
                                        <div className="card_problems">
                                            <div className="card-body_problems">
                                                <Radio value="Other">
                                                    <div className="doctor-widget">
                                                        <div className="doc-info-left" style={{ paddingRight: 20 }}>
                                                            <img src="Images/problems/other.png" alt="" className="imgeAll" />
                                                        </div>
                                                        <div className="doc-info-right">
                                                            <h4>Other</h4>
                                                            <h6>Cough/Sore Throat, Fever, Nausea/Vomiting/Diarrhea, Breathing/Asthma.</h6>
                                                        </div>
                                                    </div>
                                                </Radio>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-0 col-md-0 col-xl-2"></div>
                                </div>



                                <div className="row" style={{ width: "100%" }}>
                                    <div className="col-sm-0 col-md-0 col-lg-2 col-xl-2 p-0 m-0"></div>
                                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 p-0 m-0"></div>
                                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 p-0 m-0">
                                        <div className="div_btn mt-3">
                                            <Button type="default" className="btnBg1 mr-3" onClick={goToBack}  >
                                                BACK
                                            </Button>
                                            <Button type="primary" className="btn" onClick={continuee}  >
                                                Continue
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="col-sm-0 col-md-0 col-lg-2 col-xl-2"></div>
                                </div>
                            </Radio.Group> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <Footer
                    location="appoiment-type"
                    value={radioVal}
                    pageName="problems"
                />
            </div>
        </div >
    );
};

export default SelectedType;