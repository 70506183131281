import React, { useState } from "react";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import { Checkbox, Modal, notification, Spin } from "antd";
import { POST, OpenPOST } from "../../services/common.api";
import * as AuthService from "../../services/auth";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setProfileComplate, setUserToken } from "redux/actions/userActions";
import SweetAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import PrivacyPolicy from "components/privacyPolicy";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

const RegisterUHC = (pros) => {
  const dispatch = useDispatch();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [hideAlert, setHideAlert] = useState(false);
  const [visible, setVisibility] = useState(true);
  const [visibleConfirm, setVisibleConfirm] = useState(true);
  const [term, setTerm] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);
  const [serviceAgreement, setServiceAgreement] = useState(false);
  const [privacyModel, setPrivacyModel] = useState(false);
  const [userAgreementModal, setUserAgreementModal] = useState(false);
  const [serviceAgreementModel, setServiceAgreementModel] = useState(false);
  const [usrexists, setusrexists] = useState(false);

  const registerUser = async (values) => {
    console.log(values);

    try {
      setLoader(true);
      const user = await Auth.signUp({
        username: values.email,
        password: values.newPasword,
        attributes: {
          email: values.email,
          "custom:userType": "7",
          // 'phone_number': '+34' + phoneNumber,
          phone_number: "+" + values.phone,
          name: values.firstname + " " + values.lastname,
        },
      });
      console.log(user);
      console.log(user.userSub, "userrererer");

      if (user.userSub) {

        let data = {
          from: 'portal',
          firstname: values.firstname,
          middlename: values.middlename,
          lastname: values.lastname,
          email: values.email,
          address1: '',
          address2: '',
          gender: values.gender,
          state: '',
          postalcode: '',
          country: '',
          city: '',
          DOB: '',
          languages: '',
          type: 'PATIENT',
          role: 'PATIENT',
          companyid: values.company,
          phonenumber: values?.phone,
          phone: values?.phone,
          profilepicture: '',
          name: `${values.firstname} ${values.lastname}`,
          group: 'Client',
          phonelogin: 0,
          medconcent: 0,
          cognitoid: user.userSub,
        }

        // const data = {
        //   Type: "PATIENT",
        //   FirstName: values.firstname,
        //   LastName: values.lastname,
        //   Email: values.email,
        //   Phone: values.phone,
        //   createDate: moment().utc(),
        //   cognitoid: user.userSub,
        //   gender: values.gender,
        //   // privacy: values.privacy,
        //   // userAgreement: values.userAgreement,
        //   // serviceAgreement: values.serviceAgreement
        // };
        console.log(data, "dataaaaaaaa");
        // const response = await OpenPOST("user/patient", data);
        const response = await OpenPOST("companyemployee/import", data);
        console.log(response);
        dispatch(setProfileComplate("false"));
      
      }

      notification.success({
        message: "Registration Successfull",
        duration: 2,
        description: "Registration Successfull",
        onClick: () => {
          console.log("Notification Clicked!");
        },
      });
      setLoader(false);
      setHideAlert(true);
    } catch (error) {
      console.log(error);
      setusrexists(true)
      notification.error({
        message: error.message,
        duration: 2,
        // description: error.message,
        onClick: () => {
          console.log("Notification Clicked!");
        },
      });
      setLoader(false);
    }
  };

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Please Enter a valid Email id !";
    }
    return error;
  }

  const handleBack = () => {
    setHideAlert(false);
    history.push("/");
  };

  const onCancel = () => {
    setHideAlert(false);
  };

  // const termsAndConditions = (event: any) => {
  //   console.log(event.target.checked);
  //   setTerm(event.target.checked);
  //   setUserAgreement(event.target.checked);
  //   setServiceAgreement(event.target.checked);
  // };

  const privacyPolicy = (event: any) => {
    console.log(event.target.checked);
    setTerm(event.target.checked);
  };

  const agreement = (event: any) => {
    console.log(event.target.checked);
    setUserAgreement(event.target.checked);
  };

  const serviceProviderAgreement = (event: any) => {
    console.log(event.target.checked);
    setServiceAgreement(event.target.checked);
  };

  const privacyModelClose = () => {
    setPrivacyModel(false);
  };

  const phoneRegExp = /^(\+?[1-9]{1,4}[ \-]*)?(\([0-9]{2,3}\)[ \-]*)?([0-9]{2,4}[ \-]*)?[0-9]{3,4}[ \-]*[0-9]{3,4}$/;

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          firstname: "",
          lastname: "",
          email: "",
          newPasword: "",
          confirmPassword: "",
          phone: "",
          gender: "",
          company:'20'
          // privacy: "",
          // userAgreement: "",
          // serviceAgreement: "",
        }}
        validationSchema={Yup.object().shape({
          firstname: Yup.string().required("Please Enter First Name !"),
          lastname: Yup.string().required("Please Enter Last Name !"),
          email: Yup.string().required("Please Enter Valid Email !"),
          // phone: Yup.string().required(
          //   "Please enter Phone!"
          // ),
          phone: Yup.string()
            .required("Please Enter Valid Mobile Number")
            .matches(phoneRegExp, "Mobile Number is Not Valid")
            .min(10, "Please Enter Valid Mobile Number")
            .max(10, "Please Enter Valid Mobile Number"),
          // gender: Yup.string().required("Please Enter Gender!"),
          gender: Yup.string().required("Please Select Gender!"),
          newPasword: Yup.string()
            .required("Please Enter password !")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
              "Please choose a stronger password. Try a mix of letters, numbers, and symbols"
            ),
          confirmPassword: Yup.string().oneOf(
            [Yup.ref("newPasword"), null],
            "Passwords must match"
          ),
          // privacy: Yup.string().required("please select checkbox"),
          // userAgreement: Yup.string().required("please select checkbox"),
          // serviceAgreement: Yup.string().required("please select checkbox"),
        })}
        onSubmit={(formData, { setStatus, setSubmitting }) => {
          console.log("formData: ", formData);
          registerUser(formData);
          // setSubmitting(true);
        }}
      >
        {({
          errors,
          status,
          touched,
          isSubmitting,
          setFieldValue,
          values,
          handleChange,
          handleBlur,
        }) => {
          return (
            <>
              {hideAlert == true ? (
                <SweetAlert
                  success
                  title="Signup successful!"
                  onConfirm={handleBack}
                  onCancel={onCancel}
                  confirmBtnText="Go Login"
                >
                  Please check your Email and Validate your Account
                </SweetAlert>
              ) : null}

              <Form>
                <div className="row pt-5">
                  <div className="col-sm-6">
                    <div className="form-group required">
                      <Field
                        name="firstname"
                        type="text"
                        placeholder="First Name *"
                        autoComplete="off"
                        className={
                          "form-control" +
                          (errors.firstname && touched.firstname
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="firstname"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group required">
                      <Field
                        name="lastname"
                        type="text"
                        placeholder="Last Name *"
                        autoComplete="off"
                        className={
                          "form-control" +
                          (errors.firstname && touched.firstname
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="lastname"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group required">
                  <Field
                    name="email"
                    type="text"
                    placeholder="Email *"
                    autoComplete="off"
                    validate={validateEmail}
                    className={
                      "form-control" +
                      (errors.email && touched.email ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group required" >
                  <div style={{ position: "relative" }}>
                    <Field
                      name="newPasword"
                      type={visible ? "password" : "text"}
                      autoComplete="off"
                      placeholder="New Pasword *"
                      className={
                        "form-control" +
                        (errors.newPasword && touched.newPasword
                          ? " is-invalid"
                          : "")
                      }
                    />
                    {!visible ?
                      <FontAwesomeIcon className={(errors.newPasword && touched.newPasword ? "validMainEye" : "mainEye")} icon={faEye} onClick={() => setVisibility(!visible)} /> :
                      <FontAwesomeIcon className={(errors.newPasword && touched.newPasword ? "validMainEye" : "mainEye")} icon={faEyeSlash} onClick={() => setVisibility(!visible)} />}

                    <ErrorMessage
                      name="newPasword"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>

                <div className="form-group required">
                  <div style={{ position: "relative" }}>
                    <Field
                      name="confirmPassword"
                      type={visibleConfirm ? "password" : "text"}
                      autoComplete="off"
                      placeholder="Confirm Password *"
                      className={
                        "form-control" +
                        (errors.confirmPassword && touched.confirmPassword
                          ? " is-invalid"
                          : "")
                      }
                    />
                    {!visibleConfirm ?
                      <FontAwesomeIcon className={(errors.confirmPassword && touched.confirmPassword ? "validMainConfirmEye" : "mainConfirmEye")} icon={faEye} onClick={() => setVisibleConfirm(!visibleConfirm)} /> :
                      <FontAwesomeIcon className={(errors.confirmPassword && touched.confirmPassword ? "validMainConfirmEye" : "mainConfirmEye")} icon={faEyeSlash} onClick={() => setVisibleConfirm(!visibleConfirm)} />}

                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>

                <div className="form-group required">
                  <Field
                    name="phone"
                    type="text"
                    autoComplete="off"
                    placeholder="Mobile *"
                    className={
                      "form-control" +
                      (errors.phone && touched.phone ? " is-invalid" : "")
                    }
                  />

                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="invalid-feedback"
                  />

                  {/* <PhoneInput
                    country={"ca"}
                    value="phone"
                  // onChange={(phone) => handleChange(phone)}
                  /> */}

                </div>
                <div className="form-group required">
                  <Field
                    as="select"
                    name="gender"
                    className={
                      "form-control" +
                      (errors.gender && touched.gender ? " is-invalid" : "")
                    }
                  >
                    <option> Select gender </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </Field>
                  <ErrorMessage
                    name="gender"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group required">
                  <Field
                    as="select"
                    name="company"
                    className={
                      "form-control" +
                      (errors.company && touched.company ? " is-invalid" : "")
                    }
                  >
                    <option> Select Company </option>
                    <option value="20">UHC</option>
                    <option value="west">WEST</option>
                  </Field>
                  <ErrorMessage
                    name="gender"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div>
                <Checkbox className="pr-2" onChange={privacyPolicy}>
                  {/* <span className="Info" style={{display: "block"}}>I understand and agree with the <a style={{color: "#1a6ab1"}} hCref="https://www.onhealthalliance.com/privacy">Privacy & Policy</a></span>  */}
                  <span className="Info" style={{display: "block"}}>I understand and agree with the <a style={{color: "#1a6ab1"}} onClick={() => setPrivacyModel(true)}>Privacy & Policy</a></span> 
                </Checkbox>
                {/* <ErrorMessage
                  name="privacy"
                  component="div"
                  className="invalid-feedback"
                /> */}
                </div>
                <div>
                <Checkbox className="pr-2" onChange={agreement}>
                  <span className="Info" style={{display: "block"}}>I understand and agree with the <a style={{color: "#1a6ab1"}} onClick={() => setUserAgreementModal(true)}>User Agreement</a></span> 
                </Checkbox>
                {/* <ErrorMessage
                  name="userAgreement"
                  component="div"
                  className="invalid-feedback"
                /> */}
                </div>
                <div>
                <Checkbox className="pr-2" onChange={serviceProviderAgreement}>
                  <span className="Info" style={{display: "block"}}>I understand and agree with the <a style={{color: "#1a6ab1"}} onClick={() => setServiceAgreementModel(true)}>Service Provider Agreement</a></span> 
                </Checkbox>
                {/* <ErrorMessage
                  name="serviceAgreement"
                  component="div"
                  className="invalid-feedback"
                /> */}
                </div>

                <div className="form-group text-center mb-0 m-t-15 pb-3">
                  <div className="offset-md-3 col-md-6 col-xs-12 d-flex justify-content-center w-100">
                    {loader ? (
                      <Spin />
                    ) : (
                      <button
                        type="submit"
                        // disabled={isSubmitting}
                        className="btn btn-primary btn-lg btn-block text-uppercase pt-2 "
                        style={{
                          width: "200px",
                          fontSize: 14,
                          marginTop: "10px",
                        }}
                        disabled={!term || !userAgreement || !serviceAgreement}
                      >
                        Continue
                      </button>
                    )}
                  </div>
                  {usrexists ? (
                    <div className="alert alert-primary text-center">
                      <p> An account with this email already exists, please log in with your email! </p>
                     
                    </div>) : ''}
                </div>
              </Form>
            </>
          );
        }}
      </Formik>


      {privacyModel ? (
        <PrivacyPolicy
          title="Privacy Policy"
          open={privacyModel}
          close={privacyModelClose}
        />
      ) : null}


      <Modal
        centered
        visible={userAgreementModal}
        width={1000}
        onOk={() => setUserAgreementModal(false)}
        onCancel={() => setUserAgreementModal(false)}
        footer={null}
      >
        <div className="pt-4">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <Viewer
              fileUrl='https://cf2c9230-6450-4364-9252-7d656ea6986d.filesusr.com/ugd/911847_05eee2ae00b84bd9a94262e36b211932.pdf'
              plugins={[
                defaultLayoutPluginInstance,
              ]}
            />
          </Worker>
        </div>
      </Modal>

      <Modal
        centered
        visible={serviceAgreementModel}
        width={1000}
        onOk={() => setServiceAgreementModel(false)}
        onCancel={() => setServiceAgreementModel(false)}
        footer={null}
      >
        <div className="pt-4">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <Viewer
              fileUrl='https://cf2c9230-6450-4364-9252-7d656ea6986d.filesusr.com/ugd/911847_3ae83e80f76447f6a43ce649bef5d3e6.pdf'
              plugins={[
                defaultLayoutPluginInstance,
              ]}
            />
          </Worker>
        </div>
      </Modal>


    </div>
  );
};

export default RegisterUHC;
