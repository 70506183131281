import React, { useState, useEffect, useRef } from "react";
import { TopBar } from "components";
import {
  Steps,
  Card,
  Input,
  Cascader,
  Typography,
  Radio,
  notification,
  Checkbox,
  Row,
  Col,
  Button,
  Space,
  List,
  DatePicker,
  Table,
  Tag,
  Spin,
  Popconfirm,
  Drawer,
} from "antd";
import moment from "moment";
import { Formik, FieldArray, Form, Field } from "formik";
import CanvasDraw from "react-canvas-draw";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";
import PATIENT_DATA from "../../../jsonFiles/patientHealthHistory.json";
import PERSONAL_HEALTH_DATA from "../../../jsonFiles/personalHealth.json";
import FAMILY_HEALTH_DATA from "../../../jsonFiles/familyHealth.json";
import OTHER_HEALTH_DATA from "../../../jsonFiles/otherProblem.json";

import "primereact/resources/primereact.min.css";
import {
  relationShip,
  province,
  doctorNames,
  patientGender,
} from "../../../constants/patientProfile.constant";
import { v4 as uuid } from "uuid";
import {  GET_WITHOUT_AUTH,  POST_WITHOUT_AUTH } from "../../../services/common.api";

import DashboardSidebar from "../sidebar/sidebar";
import style from "./style.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faSave,
  faUndo,
  faEquals,
} from "@fortawesome/free-solid-svg-icons";
import { s3Upload } from "../../../services/s3fileUpload/index";
import { Link, useHistory } from "react-router-dom";
import Footer from "components/footerFix";
import { AppointmentRequestType, setSelectedFamilyMember } from "redux/actions/userActions";
import SweetAlert from "react-bootstrap-sweetalert";
import config from "config";
import crypto from 'crypto';

const MedicaldDetails = () => {
  const { Step } = Steps;
  const { Title, Text } = Typography;
  const { TextArea } = Input;
  const CheckboxGroup = Checkbox.Group;
  const checkBoxOptions = [
    "Measles",
    "Mumps",
    "Rubella",
    "Chickenpox",
    "Rheumatic Fever",
    "Polio",
  ];
  const defaultCheckedList = ["Measles", "Mumps"];
  const dispatch = useDispatch();
  const history = useHistory();
  const [healthHistoryModule, setHealthHistoryModule] = useState(null);
  const [loading, setLoading] = useState(false);
  const [clinicId, setClientId] = useState(60);
  const [signature, setSignature] = useState("");
  const [signatureUrl, setSignatureUrl] = useState(null);
  const [current, setCurrent] = useState(0);
  const _sketch = useRef({} as any);
  const [hideHead, setHideHead] = useState(false);
  const [image, setImage] = useState(null);
  const [canvass, setCanvass] = useState([] as any);
  const [edit, setEdit] = useState(false);
  const [errorSignature, setErrorSignature] = useState(false);
  const userData = useSelector((state: any) => state.userReducer.user);
  const userType = useSelector((state: any) => state.curentAppointment?.appointmentRequestType);
  const familyMemeberData = useSelector((state: any) => state.curentAppointment?.familyMember);
  const [selectedPayload, setSelectedPayload] = useState(null);
  const [selectedKey, setSelectedKey] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const onChange = (current) => {
    setCurrent(current);
  };

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const id = params.get('id');
  const token = params.get('token')
  console.log(id, "id avyuuuu")
  // console.log(token, "token avyuuuu")

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (clinicId) {
      getHealthHistory();
    }
  }, [clinicId]);

  const getHealthHistory = async () => {
    console.log(userType, "userTypeuserTypeuserTypeuserType")
    // if (userType == "familyMember") {
    //   try {
    //     const {
    //       data: {
    //         data: { Item },
    //       },
    //     } = await GET_WITHOUT_AUTH(`healthhistory/${60}/${familyMemeberData?.familymemberid}`);
    //     console.log(Item, "health historyuy family member data dataaa");
    //     setImage(Item?.consentForm?.consentForm?.signatureUrl);
    //     setHealthHistoryModule(Item);
    //   } catch (err) {
    //     console.log("err: ", err);
    //   }
    // } else {
      try {
        const {
          data: {
            data: { Item },
          },
        } = await GET_WITHOUT_AUTH(`healthhistory/${60}/${id}`, token);
        console.log(Item, "health historyuy dataaa");
        setImage(Item?.consentForm?.consentForm?.signatureUrl);
        setHealthHistoryModule(Item);
      } catch (err) {
        console.log("err: ", err);
      }
    // }
  };

  const saveDetail = () => {

    // if (userType == "familyMember") {
    //   // const clinicId = 60;
    //   const patientId = familyMemeberData?.familymemberid;
    //   const healthHistory = {
    //     clinicId,
    //     patientId,
    //     createdBy: "abcc",
    //     createdById: patientId,
    //     ...selectedPayload,
    //   };
    //   console.log("health History for family member Module", healthHistoryModule);
    //   const mergeHealthHistory = Object.assign(
    //     {},
    //     healthHistoryModule,
    //     healthHistory
    //   );
    //   setHealthHistoryModule(mergeHealthHistory);
    // } else {
      const patientId = parseInt(id);
      const healthHistory = {
        clinicId,
        patientId,
        createdBy: "abcc",
        createdById: patientId,
        ...selectedPayload,
      };
      console.log("health History Module", healthHistoryModule);
      const mergeHealthHistory = Object.assign(
        {},
        healthHistoryModule,
        healthHistory
      );
      setHealthHistoryModule(mergeHealthHistory);
    // }

    setOpenModal(false);
    setCurrent(current + 1);
    setImage(null);
  }

  const onCancel = () => {
    setOpenModal(false);
    setCurrent(current + 1);
  }

  const addPatientHealthHistory = async (key, payload) => {
    console.log("helllooo", payload, key)
    delete payload.values;

    if (key == 'Consent form') {
      setOpenModal(false);

      if (image != null) {
        setErrorSignature(false);
        // if (userType == "familyMember") {
        //   // const clinicId = 60;
        //   const patientId = familyMemeberData?.familymemberid;
        //   const healthHistory = {
        //     clinicId,
        //     patientId,
        //     createdBy: "abcc",
        //     createdById: patientId,
        //     ...payload,
        //   };
        //   console.log("health History for family member Module", healthHistoryModule);
        //   const mergeHealthHistory = Object.assign(
        //     {},
        //     healthHistoryModule,
        //     healthHistory
        //   );
        //   setHealthHistoryModule(mergeHealthHistory);
        //   saveMedicalHistory(mergeHealthHistory, key);
        // } else {
          const patientId = parseInt(id);
          const healthHistory = {
            clinicId,
            patientId,
            createdBy: "abcc",
            createdById: patientId,
            ...payload,
          };
          console.log("health History Module", healthHistoryModule);
          const mergeHealthHistory = Object.assign(
            {},
            healthHistoryModule,
            healthHistory
          );
          setHealthHistoryModule(mergeHealthHistory);
          saveMedicalHistory(mergeHealthHistory, key);
        // }
      } else {
        setErrorSignature(true);
      }
    } else {
      setSelectedPayload(payload)
      setSelectedKey(key)

      if (key == 'Medical Health History') {
        console.log(healthHistoryModule?.medicalHistory, "healthHistoryModule?.medicalHistory")
        if (healthHistoryModule?.medicalHistory != undefined) {
          if (JSON.stringify(payload?.medicalHistory) === JSON.stringify(healthHistoryModule?.medicalHistory)) {
            setCurrent(current + 1);
          } else {
            if (!openModal) {
              setOpenModal(true)
            }
          }
        } else {
          console.log(PATIENT_DATA?.medicalHistory, "dfjksafaff")
          console.log(payload?.medicalHistory)
          if (JSON.stringify(payload?.medicalHistory) === JSON.stringify(PATIENT_DATA?.medicalHistory)) {
            setCurrent(current + 1);
          } else {
            if (!openModal) {
              setOpenModal(true)
            }
          }
        }
      } else if (key == 'Personal Health History') {
        console.log("avv", payload?.personalHealthHistory)
        console.log("avv", healthHistoryModule?.personalHealthHistory)
        if (healthHistoryModule?.personalHealthHistory != undefined) {
          if (JSON.stringify(payload?.personalHealthHistory) === JSON.stringify(healthHistoryModule?.personalHealthHistory)) {
            setCurrent(current + 1);
          } else {
            if (!openModal) {
              setOpenModal(true)
            }
          }
        } else {
          if (JSON.stringify(payload?.personalHealthHistory) === JSON.stringify(PERSONAL_HEALTH_DATA?.personalHealthHistory)) {
            setCurrent(current + 1);
          } else {
            if (!openModal) {
              setOpenModal(true)
            }
          }
        }

      } else if (key == 'Family Health History') {
        if (healthHistoryModule?.familyHealthHistory != undefined) {
          if (JSON.stringify(payload?.familyHealthHistory) === JSON.stringify(healthHistoryModule?.familyHealthHistory)) {
            setCurrent(current + 1);
          } else {
            if (!openModal) {
              setOpenModal(true)
            }
          }
        } else {
          if (JSON.stringify(payload?.familyHealthHistory) === JSON.stringify(FAMILY_HEALTH_DATA?.familyHealthHistory)) {
            setCurrent(current + 1);
          } else {
            if (!openModal) {
              setOpenModal(true)
            }
          }
        }
      } else if (key == 'Other Problems History') {

        if (healthHistoryModule?.otherProblemsHistory != undefined) {
          if (JSON.stringify(payload?.otherProblemsHistory) === JSON.stringify(healthHistoryModule?.otherProblemsHistory)) {
            setCurrent(current + 1);
          } else {
            if (!openModal) {
              setOpenModal(true)
            }
          }
        } else {
          if (JSON.stringify(payload?.otherProblemsHistory) === JSON.stringify(OTHER_HEALTH_DATA?.otherProblemsHistory)) {
            setCurrent(current + 1);
          } else {
            if (!openModal) {
              setOpenModal(true)
            }
          }
        }
      }
    }
  };

  const saveMedicalHistory = async (mergeHealthHistory, key) => {
    setOpenModal(false);

    // if (userType == "familyMember") {
    //   // const clinicId = 60;
    //   const patientId = familyMemeberData?.familymemberid;
    //   const healthHistory = {
    //     clinicId,
    //     patientId,
    //     createdBy: "abcc",
    //     createdById: patientId,
    //     ...payload,
    //   };
    //   console.log("health History for family member Module", healthHistoryModule);
    //   const mergeHealthHistory = Object.assign(
    //     {},
    //     healthHistoryModule,
    //     healthHistory
    //   );
    //   setHealthHistoryModule(mergeHealthHistory);
    //   try {
    //     await POST(`healthhistory/${clinicId}`, mergeHealthHistory);
    //     console.log(current, "currentttt")

    //     if (current === 4) {
    //       dispatch(setSelectedFamilyMember({}));
    //       dispatch(AppointmentRequestType("Own"));
    //       history.push("/")
    //     }

    //     notification.success({ message: `${key} added successfully` });
    //   } catch (err) {
    //     console.log("err: ", err);
    //   }
    // } else {
    // const clinicId = 60;


    // const patientId = userData?.id;
    // const healthHistory = {
    //   clinicId,
    //   patientId,
    //   createdBy: "abcc",
    //   createdById: patientId,
    //   ...payload,
    // };
    // console.log("health History Module", healthHistoryModule);
    // const mergeHealthHistory = Object.assign(
    //   {},
    //   healthHistoryModule,
    //   healthHistory
    // );
    // setHealthHistoryModule(mergeHealthHistory);
    try {
      await POST_WITHOUT_AUTH(`healthhistory/${clinicId}`, mergeHealthHistory, token);

      if (current === 4) {
        // setCurrent(0);
        // history.push("/")
        getHealthHistory();
      } else {
        setCurrent(current + 1);
      }
      if(current < 4){
        notification.success({ message: `${key} added successfully` });
      }else{
        notification.success({ message: `Medical histroy successfully submitted` });
      }
    } catch (err) {
      console.log("err: ", err);
    }
    // }
  }

  useEffect(() => {
    saveWhileUrlChange();
  }, [canvass]);

  const saveWhileUrlChange = async () => {
    let localImages = [];
    for (let c of canvass) {
      localImages.push(c?.toDataURL());
    }

    if (localImages.length != 0) {
      const dataUrl = localImages[1];
      console.log("saved data", dataUrl);
      // this.setState({ saving: true })
      const name = `userSignature/${uuid()}.` + `png`;
      console.log("name::", name);
      let file: any;
      const arr = dataUrl.split(",");

      // const mime = arr[0].match(/:([^;]+?);/)[1];
      const mimeMatch = arr[0].match(/:([a-zA-Z0-9-+/.]+);/);
      if (!mimeMatch || mimeMatch.length < 2) {
        console.error('Invalid dataUrl format or MIME type missing.');
        return;  // Handle the error appropriately
      }
      const mime = mimeMatch[1];

      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      file = new Blob([u8arr], { type: mime });
      console.log("......file", file);

      const data = {
        userId: 148,
        type: mime,
        fileName: name,
        formId: "ConsentForm",
      };
      setLoading(true);
      console.log("data: ", data);
      console.log(data.fileName, file, "** file dataaaa");

      try {
        const uplodadedImageKey = await s3Upload(data.fileName, file);
        console.log(uplodadedImageKey, "uplodadedImageKey");
        if (uplodadedImageKey) {
          setEdit(false);
          setSignatureUrl(uplodadedImageKey);
          console.log(uplodadedImageKey, "check img key");
          
          // let d = `https://onrx-portal.s3.ca-central-1.amazonaws.com/${uplodadedImageKey}`;
          let d = `${config.assetUrl}/${uplodadedImageKey}`;
          console.log("d: ", d);
          setImage(d);
          // setEdit(false)
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        console.log("err: ", err);
      }
    }
  };

  const _save = async () => {
    console.log("_Save");
    console.log("_save", document.getElementsByTagName("canvas"));
    setCanvass(document.getElementsByTagName("canvas"));
    saveWhileUrlChange();
    setEdit(true);
  };

  // const [sidebar, setSidebar] = useState(false);
  // const showSidebar = () => setSidebar(!sidebar);

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  function cancel(e) {
    console.log(e);
  }

  return (
    <div>
      {/* <TopBar /> */}
      {/* <div className="box">
        <div className={`${style.toggebtn} d-block d-xl-none`}>
          <i>
            <FontAwesomeIcon
              className="mr-1"
              icon={faEquals}
              onClick={showDrawer}
            />
          </i>
        </div>
        <div className="d-block d-xl-none">
          <p className="pageInfoM"> Medical History For Mobile</p>
          <h5 className="h5NewM">Dashboard / Medical History </h5>
        </div>
        <div className="d-none d-xl-block">
          <p className="pageInfo">Medical History For Mobile</p>
          <h5 className="h5New">
            <Link style={{ color: "white" }} to="/dashboard">
              Dashboard
            </Link>{" "}
            / Medical History For Mobile{" "}
          </h5>
        </div>
      </div> */}
      <div>
        <div
          className="content"
          style={{ backgroundColor: "#f1f5f9", paddingTop: 15 }}
        >
          <div className={style.container}>
            <div className="row pb-5 ">
              {/* <div
                className={`col-lg-4 col-xl-2 col-sm-12 d-none d-xl-block theiaStickySidebar pr-0 `}
              >
                <DashboardSidebar />
              </div> */}
              {/* <Drawer
                closable={false}
                width={300}
                placement="left"
                onClose={onClose}
                visible={visible}
                style={{ paddingTop: 0 }}
              >
                <div className="widget-profile pro-widget-content pt-0 pb-4">
                  <div className="profile-info-widget">
                    <img src="../Images/logo.png" height="50" alt="" />
                  </div>
                </div>
                <DashboardSidebar />
              </Drawer> */}

              {/* {sidebar ? (
                <div className={`d-block d-xl-none ${style.toggle}`}>
                  <DashboardSidebar />
                </div>
              ) : null} */}
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <Card className={style.Sidebar_div}>
                  <Steps
                    current={current}
                    onChange={onChange}
                    responsive={true}
                    size="small"
                  >
                    <Step
                      title="Medical History"
                    // description="Patient Medical History."
                    />
                    <Step
                      title="Personal Health"
                    // description="Patient Personal Health History"
                    />
                    <Step
                      title="Family Health"
                    // description="Patient Family Health History"
                    />
                    <Step
                      title="Other Problems"
                    // description="Patient Other Problems"
                    />
                    <Step
                      title="Consent Form"
                    // description="Patient Consent Form"
                    />
                  </Steps>

                  <>
                    {current === 0 ? (
                      <Card title="Medical History" className="mt-4 mb-0 pb-0">
                        <div className="col-sm-12 col-md-12">
                          <div className="row">
                            {/* formik start */}
                            <Formik
                              enableReinitialize
                              initialValues={
                                healthHistoryModule?.medicalHistory
                                  ? {
                                    medicalHistory:
                                      healthHistoryModule?.medicalHistory,
                                  }
                                  : {
                                    medicalHistory:
                                      PATIENT_DATA.medicalHistory,
                                  }
                              }
                              onSubmit={(values) => {
                                values.medicalHistory[5].signatureUrl = `${signatureUrl}`;
                                addPatientHealthHistory(
                                  "Medical Health History",
                                  values
                                );
                                console.log(signatureUrl, "sign 1");
                              }}
                              render={({ values, setFieldValue }) => {
                                return (
                                  <Form>
                                    <FieldArray
                                      name="medicalHistory"
                                      render={(arrayHelpers) => (
                                        <>
                                          {values?.medicalHistory?.map(
                                            (renderTemplate, index1) => {
                                              if (
                                                renderTemplate.type ===
                                                "checkboxes" &&
                                                index1 === renderTemplate.index
                                              ) {
                                                return (
                                                  <div key={crypto.randomBytes(16).toString('hex')}>
                                                    <FieldArray
                                                      name={`medicalHistory[${index1}].checkboxes`}
                                                      render={(
                                                        arrayHelpers2
                                                      ) => (
                                                        <div className="row">
                                                          {renderTemplate.checkboxes.map(
                                                            (
                                                              checkBoxes,
                                                              index2
                                                            ) => (
                                                              <>
                                                                <div
                                                                  className="col-4 col-sm-6 col-md-2 col-lg-3 mt-3 mb-4"
                                                                  key={crypto.randomBytes(16).toString('hex')}
                                                                >
                                                                  <Text
                                                                    className={
                                                                      // style.text
                                                                      style.textNormal
                                                                    }
                                                                    style={{
                                                                      textTransform: "lowercase",
                                                                    }}
                                                                  >
                                                                    {
                                                                      checkBoxes.label
                                                                    }
                                                                  </Text>
                                                                </div>

                                                                <div className="col-8 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                  <Radio.Group
                                                                    className="ml-2"
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      arrayHelpers2.replace(
                                                                        index2,
                                                                        {
                                                                          ...checkBoxes,
                                                                          answer:
                                                                            e
                                                                              .target
                                                                              .value,
                                                                        }
                                                                      );
                                                                    }}
                                                                  >
                                                                    <Radio.Button
                                                                      style={
                                                                        checkBoxes.answer ===
                                                                          "yes"
                                                                          ? {
                                                                            backgroundColor:
                                                                              "red",
                                                                            color:
                                                                              "#ffff",
                                                                          }
                                                                          : {}
                                                                      }
                                                                      value="yes"
                                                                    >
                                                                      Yes
                                                                    </Radio.Button>
                                                                    <Radio.Button
                                                                      style={
                                                                        checkBoxes.answer ===
                                                                          "no"
                                                                          ? {
                                                                            backgroundColor:
                                                                              "#689f38",
                                                                            color:
                                                                              "#ffff",
                                                                          }
                                                                          : {}
                                                                      }
                                                                      value="no"
                                                                    >
                                                                      No
                                                                    </Radio.Button>
                                                                  </Radio.Group>
                                                                </div>
                                                                <hr />
                                                              </>
                                                            )
                                                          )}
                                                        </div>
                                                      )}
                                                    />
                                                  </div>
                                                );
                                              }
                                              if (
                                                renderTemplate.type ===
                                                "textArea" &&
                                                index1 === 1
                                              ) {
                                                return (
                                                  <div key={crypto.randomBytes(16).toString('hex')}>
                                                    <div className="col-md-12 ml-n4 mt-5">
                                                      <div className="row">
                                                        <div className="col-md-3">
                                                          <Text
                                                            className={
                                                              style.textNormal
                                                            }
                                                          >
                                                            {
                                                              renderTemplate
                                                                .textArea.label
                                                            }
                                                          </Text>
                                                        </div>
                                                        <div className="col-md-9">
                                                          <TextArea
                                                            className="textArea"
                                                            defaultValue={
                                                              renderTemplate
                                                                .textArea.answer
                                                            }
                                                            onBlur={(e) => {
                                                              const modifyTextArea =
                                                              {
                                                                ...renderTemplate,
                                                              };
                                                              modifyTextArea.textArea.answer =
                                                                e.target.value;
                                                              arrayHelpers.replace(
                                                                index1,
                                                                modifyTextArea
                                                              );

                                                              console.log(
                                                                modifyTextArea,
                                                                "andar"
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                              if (
                                                renderTemplate.type ===
                                                "radio" &&
                                                index1 === 2
                                              ) {
                                                return (
                                                  <div
                                                    className="row"
                                                    key={crypto.randomBytes(16).toString('hex')}
                                                  >
                                                    <FieldArray
                                                      name={`medicalHistory[${index1}].radio`}
                                                      render={(
                                                        arrayHelpers3
                                                      ) => (
                                                        <>
                                                          {renderTemplate.radio.map(
                                                            (radio, index4) => (
                                                              <>
                                                                <div className="col-6 pt-4 ">
                                                                  <div
                                                                    className="row"
                                                                    key={crypto.randomBytes(16).toString('hex')}
                                                                  >
                                                                    <div className="col-6 ">
                                                                      <Text
                                                                        className={`mr-1 ${style.textNormal}`}
                                                                      >
                                                                        {
                                                                          radio.label
                                                                        }
                                                                      </Text>
                                                                    </div>
                                                                    <div className="col-6">
                                                                      <Radio.Group
                                                                        className=""
                                                                        style={{
                                                                          paddingLeft: 10,
                                                                        }}
                                                                        onChange={(
                                                                          e
                                                                        ) => {
                                                                          arrayHelpers3.replace(
                                                                            index4,
                                                                            {
                                                                              ...radio,
                                                                              answer:
                                                                                e
                                                                                  .target
                                                                                  .value,
                                                                            }
                                                                          );
                                                                        }}
                                                                      >
                                                                        <Radio.Button
                                                                          style={
                                                                            radio.answer ===
                                                                              "yes"
                                                                              ? {
                                                                                backgroundColor:
                                                                                  "red",
                                                                                color:
                                                                                  "#ffff",
                                                                              }
                                                                              : {}
                                                                          }
                                                                          value="yes"
                                                                        >
                                                                          Yes
                                                                        </Radio.Button>
                                                                        <Radio.Button
                                                                          style={
                                                                            radio.answer ===
                                                                              "no"
                                                                              ? {
                                                                                backgroundColor:
                                                                                  "#689f38",
                                                                                color:
                                                                                  "#ffff",
                                                                              }
                                                                              : {}
                                                                          }
                                                                          value="no"
                                                                        >
                                                                          No
                                                                        </Radio.Button>
                                                                      </Radio.Group>
                                                                    </div>
                                                                    <hr />
                                                                  </div>
                                                                </div>
                                                              </>
                                                            )
                                                          )}
                                                        </>
                                                      )}
                                                    />
                                                  </div>
                                                );
                                              }
                                              if (
                                                renderTemplate.type ===
                                                "checkboxQuestions" &&
                                                index1 === 3
                                              ) {
                                                return (
                                                  <div
                                                    className="row"
                                                    key={crypto.randomBytes(16).toString('hex')}
                                                  >
                                                    {console.log(
                                                      "I am checkbox Questions"
                                                    )}
                                                    {renderTemplate.checkboxQuestions.map(
                                                      (checkboxLabel) => (
                                                        <div
                                                          key={crypto.randomBytes(16).toString('hex')}
                                                        >
                                                          <div className="col-md-3 mt-5">
                                                            <Text>
                                                              {
                                                                checkboxLabel.label
                                                              }
                                                            </Text>
                                                          </div>
                                                          <div className="col-md-3 mt-5">
                                                            <Radio.Group className="ml-5">
                                                              <Radio.Button value="large">
                                                                Yes
                                                              </Radio.Button>
                                                              <Radio.Button value="default">
                                                                No
                                                              </Radio.Button>
                                                            </Radio.Group>
                                                          </div>
                                                          <hr />
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                );
                                              }

                                              if (
                                                renderTemplate.type ===
                                                "textArea" &&
                                                index1 === 4
                                              ) {
                                                return (
                                                  <div
                                                    className="row mt-4"
                                                    key={crypto.randomBytes(16).toString('hex')}
                                                  >
                                                    <div className="col-md-4">
                                                      <Text
                                                        className={
                                                          style.textNormal
                                                        }
                                                      >
                                                        {
                                                          renderTemplate
                                                            .textArea.label
                                                        }
                                                      </Text>
                                                    </div>
                                                    <div className="col-md-8">
                                                      <TextArea
                                                        className="textArea"
                                                        defaultValue={
                                                          renderTemplate
                                                            .textArea.answer
                                                        }
                                                        onBlur={(e) => {
                                                          const modifyTextArea =
                                                          {
                                                            ...renderTemplate,
                                                          };
                                                          modifyTextArea.textArea.answer =
                                                            e.target.value;
                                                          arrayHelpers.replace(
                                                            index1,
                                                            modifyTextArea
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                );
                                              }
                                              return null;
                                            }
                                          )}
                                        </>
                                      )}
                                    />

                                    {/* <div className=" mt-4 rounded">
                                      <Text className={style.textNormal}>
                                        Signature Of Responsible Party
                                      </Text>

                                      {(image == "" ||
                                        image == null ||
                                        image == false) &&
                                        !hideHead ? (
                                        <div>
                                          <div
                                            style={{
                                              overflow: "hidden",
                                              width: "250px",
                                            }}
                                            className="ml-0 border border-primary border-1 text-left"
                                          >
                                            <div
                                              // id={`unique500`}
                                              style={{
                                                overflow: "hidden",
                                                width: "300px",
                                              }}
                                            >
                                              <CanvasDraw
                                                id="unique500"
                                                width="250px"
                                                ref={_sketch}
                                                brushColor="black"
                                                brushRadius={2}
                                                lazyRadius={2}
                                                canvasWidth={250}
                                                canvasHeight={150}
                                              // done={this.props.done(this.state.key)}
                                              />
                                            </div>
                                          </div>

                                          <div
                                            style={{
                                              backgroundColor: "#246ec63b",
                                              width: 250,
                                              padding: 7,
                                            }}
                                          >
                                            <Button
                                              style={{ borderRadius: "100%" }}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                if (_sketch.current) {
                                                  _sketch.current?.undo();
                                                  // console.log(_sketch.current);
                                                  setCanvass([]);
                                                }
                                              }}
                                            >
                                              <FontAwesomeIcon icon={faUndo} />{" "}
                                            </Button>
                                            <div className="float-right">
                                              <Button
                                                style={{ borderRadius: "100%" }}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  _save();
                                                }}
                                              >
                                                {loading ? (
                                                  <Spin />
                                                ) : (
                                                  <FontAwesomeIcon
                                                    icon={faSave}
                                                  />
                                                )}
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div>
                                          <div
                                            style={{
                                              overflow: "hidden",
                                              width: "250px",
                                            }}
                                            className="ml-0 border border-primary border-1 text-left"
                                          >
                                            <div
                                              style={{
                                                overflow: "hidden",
                                                width: "250px",
                                                height: "150px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              {loading ? (
                                                <Spin />
                                              ) : (
                                                <img
                                                  src={image || ""}
                                                  width=" "
                                                  alt=""
                                                />
                                              )}
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor: "#246ec63b",
                                              width: 250,
                                              padding: 7,
                                            }}
                                          >
                                            <div>
                                              <Button
                                                style={{ borderRadius: "100%" }}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  // this.edit()
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faEdit}
                                                />{" "}
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-md-6">
                                      <div className="row mt-4">
                                        <div className="col-md-3 col-lg-2 mt-1">
                                          <Text>Date:</Text>
                                          { }
                                        </div>
                                        <div className="col-md-5 col-lg-5">
                                          {values?.medicalHistory?.signDate ==
                                            null ? (
                                            <DatePicker
                                              onChange={(date, dateString) => {
                                                values.medicalHistory.signDate =
                                                  moment(dateString).format(
                                                    "x"
                                                  );
                                                setFieldValue(
                                                  `values.medicalHistory.signDate`,
                                                  values
                                                );
                                              }}
                                              style={{ width: "100%" }}
                                            />
                                          ) : (
                                            <DatePicker
                                              onChange={(date, dateString) => {
                                                values.medicalHistory.signDate =
                                                  moment(dateString).format(
                                                    "x"
                                                  );
                                                setFieldValue(
                                                  `values.medicalHistory.signDate`,
                                                  values
                                                );
                                              }}
                                              defaultValue={moment(
                                                values?.medicalHistory?.signDate
                                              )}
                                              style={{ width: "100%" }}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div> */}
                                    <hr />
                                    <div className="row pb-5">
                                      <Button
                                        size="large"
                                        style={{ width: 110 }}
                                        className="mt-4 ml-2 mr-3"
                                        // onClick={() => history.push("/")}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                        style={{ width: 110 }}
                                        className="mt-4 ml-n1"
                                      >
                                        Next
                                      </Button>

                                    </div>
                                  </Form>
                                );
                              }}
                            />
                          </div>
                        </div>
                      </Card>
                    ) : current === 1 ? (
                      <Card title="Personal Health" className="mt-4">
                        <Formik
                          enableReinitialize
                          initialValues={
                            healthHistoryModule?.personalHealthHistory
                              ? {
                                personalHealthHistory:
                                  healthHistoryModule?.personalHealthHistory,
                              }
                              : // : { personalHealthHistory: PATIENT_DATA.personalHealthHistory }
                              {
                                personalHealthHistory:
                                  PERSONAL_HEALTH_DATA?.personalHealthHistory,
                              }
                          }
                          onSubmit={(values) => {
                            addPatientHealthHistory(
                              "Personal Health History",
                              values
                            );
                          }}
                          render={({ values, setFieldValue }) => (
                            <Form>
                              <FieldArray
                                name="personalHealthHistory"
                                render={(personalHealthHistoryHelper) => (
                                  <div key={crypto.randomBytes(16).toString('hex')}>
                                    {values.personalHealthHistory?.map(
                                      (personalHealthHistoryData, index) => {
                                        if (
                                          personalHealthHistoryData.type ===
                                          "checkboxes"
                                        ) {
                                          return (
                                            <div>
                                              <Title level={4}>
                                                {
                                                  personalHealthHistoryData.heading
                                                }
                                              </Title>
                                              <FieldArray
                                                name={`personalHealthHistory[${index}].checkboxes`}
                                                render={(arrayHelpers) => (
                                                  <>
                                                    <div className="row">
                                                      {personalHealthHistoryData.checkboxes.map(
                                                        (
                                                          checkboxData,
                                                          checkBoxIndex
                                                        ) => (
                                                          <>
                                                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                              <Checkbox
                                                                value={
                                                                  checkboxData.label
                                                                }
                                                                checked={
                                                                  checkboxData.isActive
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  if (
                                                                    e.target
                                                                      .checked
                                                                  ) {
                                                                    arrayHelpers.replace(
                                                                      checkBoxIndex,
                                                                      {
                                                                        ...checkboxData,
                                                                        isActive:
                                                                          true,
                                                                      }
                                                                    );
                                                                  } else {
                                                                    arrayHelpers.replace(
                                                                      checkBoxIndex,
                                                                      {
                                                                        ...checkboxData,
                                                                        isActive:
                                                                          false,
                                                                      }
                                                                    );
                                                                  }
                                                                }}
                                                              >
                                                                <Text
                                                                  className={
                                                                    style.textNormal
                                                                  }
                                                                >
                                                                  {
                                                                    checkboxData.label
                                                                  }
                                                                </Text>
                                                              </Checkbox>
                                                            </div>
                                                          </>
                                                        )
                                                      )}
                                                    </div>
                                                  </>
                                                )}
                                              />
                                            </div>
                                          );
                                        }
                                        if (
                                          personalHealthHistoryData.type ===
                                          "checkboxdate"
                                        ) {
                                          return (
                                            <div key={crypto.randomBytes(16).toString('hex')}>
                                              <Title className="pt-3" level={4}>
                                                {
                                                  personalHealthHistoryData.heading
                                                }{" "}
                                              </Title>
                                              <FieldArray
                                                name={`personalHealthHistory[${index}].checkboxdate`}
                                                render={(arrayHelpers1) => (
                                                  <>
                                                    <Row>
                                                      <div className="col-md-12 ml-n3">
                                                        <div className="row">
                                                          {personalHealthHistoryData.checkboxdate.map(
                                                            (
                                                              checkboxData,
                                                              checkBoxDateIndex
                                                            ) => (
                                                              <>
                                                                <div className="col-md-3 mt-3">
                                                                  <Checkbox
                                                                    style={{
                                                                      borderRadius:
                                                                        "0%",
                                                                    }}
                                                                    checked={
                                                                      checkboxData.isActive
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      if (
                                                                        e.target
                                                                          .checked
                                                                      ) {
                                                                        arrayHelpers1.replace(
                                                                          checkBoxDateIndex,
                                                                          {
                                                                            ...checkboxData,
                                                                            isActive:
                                                                              true,
                                                                          }
                                                                        );
                                                                      } else {
                                                                        arrayHelpers1.replace(
                                                                          checkBoxDateIndex,
                                                                          {
                                                                            ...checkboxData,
                                                                            isActive:
                                                                              false,
                                                                          }
                                                                        );
                                                                      }
                                                                    }}
                                                                  >
                                                                    <Text
                                                                      className={
                                                                        style.textNormal
                                                                      }
                                                                    >
                                                                      {
                                                                        checkboxData.label
                                                                      }
                                                                    </Text>
                                                                  </Checkbox>
                                                                </div>
                                                                <div className="col-md-3 mt-3">
                                                                  <DatePicker
                                                                    disabledDate={(
                                                                      current
                                                                    ) => {
                                                                      let customDate =
                                                                        moment().format(
                                                                          "YYYY-MM-DD"
                                                                        );
                                                                      return (
                                                                        current &&
                                                                        current >
                                                                        moment(
                                                                          customDate,
                                                                          "YYYY-MM-DD"
                                                                        )
                                                                      );
                                                                    }}
                                                                    value={
                                                                      checkboxData.checkboxDate
                                                                        ? moment(
                                                                          checkboxData.checkboxDate
                                                                        )
                                                                        : null
                                                                    }
                                                                    onChange={(
                                                                      date,
                                                                      dateString
                                                                    ) => {
                                                                      arrayHelpers1.replace(
                                                                        checkBoxDateIndex,
                                                                        {
                                                                          ...checkboxData,
                                                                          checkboxDate:
                                                                            date,
                                                                        }
                                                                      );
                                                                    }}
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                    }}
                                                                  />
                                                                </div>
                                                              </>
                                                            )
                                                          )}
                                                        </div>
                                                      </div>
                                                    </Row>
                                                  </>
                                                )}
                                              />
                                            </div>
                                          );
                                        }
                                        if (
                                          personalHealthHistoryData.type ===
                                          "textarea"
                                        ) {
                                          return (
                                            <div key={crypto.randomBytes(16).toString('hex')}>
                                              <div className="col-md-12 mt-5 ml-n3">
                                                <div className="row">
                                                  <Title
                                                    level={4}
                                                    className="ml-3"
                                                  >
                                                    {
                                                      personalHealthHistoryData.heading
                                                    }
                                                  </Title>
                                                  <div className="col-md-12">
                                                    <TextArea
                                                      className="textArea"
                                                      defaultValue={
                                                        personalHealthHistoryData.answer
                                                      }
                                                      onBlur={(e) => {
                                                        personalHealthHistoryHelper.replace(
                                                          index,
                                                          {
                                                            ...personalHealthHistoryData,
                                                            answer:
                                                              e.target.value,
                                                          }
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        if (
                                          personalHealthHistoryData.type ===
                                          "table" &&
                                          personalHealthHistoryData.uniqueId ===
                                          "surgeriesTable"
                                        ) {
                                          return (
                                            <div key={crypto.randomBytes(16).toString('hex')}>
                                              <div className="col-md-12 mt-3 ml-n3">
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <Title
                                                      level={4}
                                                      className="mt-4"
                                                    >
                                                      {
                                                        personalHealthHistoryData.heading
                                                      }
                                                    </Title>
                                                  </div>
                                                  <div className="col-md-12">
                                                    <FieldArray
                                                      name={`personalHealthHistory[${index}].table.answer`}
                                                      render={(
                                                        arrayHelpersOfPersonalHealth
                                                      ) => (
                                                        <>
                                                          <table className="table table-striped">
                                                            <thead>
                                                              <tr>
                                                                <th scope="col">
                                                                  Year
                                                                </th>
                                                                <th scope="col">
                                                                  Reason
                                                                </th>
                                                                <th scope="col">
                                                                  Hospital
                                                                </th>
                                                                <th scope="col">
                                                                  Action
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {personalHealthHistoryData.table.answer.map(
                                                                (
                                                                  tableData,
                                                                  tableIndex
                                                                ) => (

                                                                  <tr
                                                                    key={tableIndex}
                                                                  >
                                                                    <td>
                                                                      <Input
                                                                        placeholder="Year"
                                                                        onBlur={(
                                                                          e
                                                                        ) => {
                                                                          arrayHelpersOfPersonalHealth.replace(
                                                                            tableIndex,
                                                                            {
                                                                              ...tableData,
                                                                              year: e
                                                                                .target
                                                                                .value,
                                                                            }
                                                                          );
                                                                        }}
                                                                        defaultValue={
                                                                          tableData?.year
                                                                        }
                                                                      />
                                                                    </td>
                                                                    <td>
                                                                      <Input
                                                                        placeholder="Reason"
                                                                        onBlur={(
                                                                          e
                                                                        ) => {
                                                                          arrayHelpersOfPersonalHealth.replace(
                                                                            tableIndex,
                                                                            {
                                                                              ...tableData,
                                                                              reason:
                                                                                e
                                                                                  .target
                                                                                  .value,
                                                                            }
                                                                          );
                                                                        }}
                                                                        defaultValue={
                                                                          tableData?.reason
                                                                        }
                                                                      />
                                                                    </td>
                                                                    <td>
                                                                      <Input
                                                                        placeholder="Hospital"
                                                                        onBlur={(
                                                                          e
                                                                        ) => {
                                                                          arrayHelpersOfPersonalHealth.replace(
                                                                            tableIndex,
                                                                            {
                                                                              ...tableData,
                                                                              hospital:
                                                                                e
                                                                                  .target
                                                                                  .value,
                                                                            }
                                                                          );
                                                                        }}
                                                                        defaultValue={
                                                                          tableData?.hospital
                                                                        }
                                                                      />
                                                                    </td>
                                                                    <td>
                                                                      <Popconfirm
                                                                        title="Are you sure ?"
                                                                        onConfirm={() =>
                                                                          arrayHelpersOfPersonalHealth.remove(
                                                                            tableIndex
                                                                          )
                                                                        }
                                                                        onCancel={
                                                                          cancel
                                                                        }
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                      >
                                                                        <Button
                                                                          icon={
                                                                            <DeleteOutlined
                                                                              className={
                                                                                style.delIconInner
                                                                              }
                                                                              style={{
                                                                                fontSize:
                                                                                  "16px",
                                                                                color:
                                                                                  "red",
                                                                              }}
                                                                            // onClick={() => {
                                                                            //   arrayHelpersOfPersonalHealth.remove(tableIndex);
                                                                            // }}
                                                                            />
                                                                          }
                                                                          size="middle"
                                                                          onClick={() => {
                                                                            console.log();
                                                                          }}
                                                                          className="mr-2 mb-n5"
                                                                        />
                                                                      </Popconfirm>
                                                                    </td>
                                                                  </tr>

                                                                )
                                                              )}
                                                            </tbody>
                                                          </table>
                                                          <Button
                                                            type="dashed"
                                                            className="mt-2"
                                                            onClick={() => {
                                                              arrayHelpersOfPersonalHealth.push(
                                                                {
                                                                  year: "",
                                                                  reason: "",
                                                                  hospital: "",
                                                                }
                                                              );
                                                            }}
                                                          >
                                                            Add New
                                                          </Button>
                                                        </>
                                                      )}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        if (
                                          personalHealthHistoryData.type ===
                                          "table" &&
                                          personalHealthHistoryData.uniqueId ===
                                          "otherHospitalizationTable"
                                        ) {
                                          return (
                                            <div key={crypto.randomBytes(16).toString('hex')}>
                                              <div className="col-md-12 mt-3 ml-n3">
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <Title
                                                      level={4}
                                                      className="mt-4"
                                                    >
                                                      {
                                                        personalHealthHistoryData.heading
                                                      }
                                                    </Title>
                                                  </div>
                                                  <div className="col-md-12">
                                                    <FieldArray
                                                      name={`personalHealthHistory[${index}].table.answer`}
                                                      render={(
                                                        arrayHelpersOfOtherPersonalization
                                                      ) => (
                                                        <>
                                                          <table className="table table-striped">
                                                            <thead>
                                                              <tr>
                                                                <th scope="col">
                                                                  Year
                                                                </th>
                                                                <th scope="col">
                                                                  Reason
                                                                </th>
                                                                <th scope="col">
                                                                  Hospital
                                                                </th>
                                                                <th scope="col">
                                                                  Action
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {personalHealthHistoryData.table.answer.map(
                                                                (
                                                                  tableData,
                                                                  tableIndex
                                                                ) => (
                                                                  <>
                                                                    <tr
                                                                      key={crypto.randomBytes(16).toString('hex')}
                                                                    >
                                                                      <td>
                                                                        <Input
                                                                          placeholder="Year"
                                                                          onBlur={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpersOfOtherPersonalization.replace(
                                                                              tableIndex,
                                                                              {
                                                                                ...tableData,
                                                                                year: e
                                                                                  .target
                                                                                  .value,
                                                                              }
                                                                            );
                                                                          }}
                                                                          defaultValue={
                                                                            tableData.year
                                                                          }
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Input
                                                                          placeholder="Reason"
                                                                          onBlur={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpersOfOtherPersonalization.replace(
                                                                              tableIndex,
                                                                              {
                                                                                ...tableData,
                                                                                reason:
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                              }
                                                                            );
                                                                          }}
                                                                          defaultValue={
                                                                            tableData.reason
                                                                          }
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Input
                                                                          placeholder="Hospital"
                                                                          onBlur={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpersOfOtherPersonalization.replace(
                                                                              tableIndex,
                                                                              {
                                                                                ...tableData,
                                                                                hospital:
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                              }
                                                                            );
                                                                          }}
                                                                          defaultValue={
                                                                            tableData.hospital
                                                                          }
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Popconfirm
                                                                          title="Are you sure ?"
                                                                          onConfirm={() =>
                                                                            arrayHelpersOfOtherPersonalization.remove(
                                                                              tableIndex
                                                                            )
                                                                          }
                                                                          onCancel={
                                                                            cancel
                                                                          }
                                                                          okText="Yes"
                                                                          cancelText="No"
                                                                        >
                                                                          <Button
                                                                            icon={
                                                                              <DeleteOutlined
                                                                                className={
                                                                                  style.delIconInner
                                                                                }
                                                                                style={{
                                                                                  fontSize:
                                                                                    "16px",
                                                                                  color:
                                                                                    "red",
                                                                                }}
                                                                              />
                                                                            }
                                                                            size="middle"
                                                                            onClick={() => {
                                                                              console.log();
                                                                            }}
                                                                            className="mr-2 mb-n5"
                                                                          />
                                                                        </Popconfirm>
                                                                      </td>
                                                                    </tr>
                                                                  </>
                                                                )
                                                              )}
                                                            </tbody>
                                                          </table>
                                                          <Button
                                                            type="dashed"
                                                            className="mt-3"
                                                            onClick={() => {
                                                              arrayHelpersOfOtherPersonalization.push(
                                                                {
                                                                  year: "",
                                                                  reason: "",
                                                                  hospital: "",
                                                                }
                                                              );
                                                            }}
                                                          >
                                                            Add New
                                                          </Button>
                                                        </>
                                                      )}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        if (
                                          personalHealthHistoryData.type ===
                                          "table" &&
                                          personalHealthHistoryData.uniqueId ===
                                          "prescribedDrugsTable"
                                        ) {
                                          return (
                                            <div key={crypto.randomBytes(16).toString('hex')}>
                                              <div className="col-md-12 mt-3 ml-n3">
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <Title
                                                      level={4}
                                                      className="mt-4"
                                                    >
                                                      {
                                                        personalHealthHistoryData.heading
                                                      }
                                                    </Title>
                                                  </div>
                                                  <div className="col-md-12">
                                                    <FieldArray
                                                      name={`personalHealthHistory[${index}].table.answer`}
                                                      render={(
                                                        arrayHelpersOfDrugs
                                                      ) => (
                                                        <>
                                                          <table className="table table-striped">
                                                            <thead>
                                                              <tr>
                                                                <th scope="col">
                                                                  Name the drug
                                                                </th>
                                                                <th scope="col">
                                                                  Strength
                                                                </th>
                                                                <th scope="col">
                                                                  Frequency
                                                                  taken
                                                                </th>
                                                                <th scope="col">
                                                                  Action
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {personalHealthHistoryData.table.answer.map(
                                                                (
                                                                  tableData,
                                                                  tableIndex
                                                                ) => (
                                                                  <>
                                                                    <tr
                                                                      key={crypto.randomBytes(16).toString('hex')}
                                                                    >
                                                                      <td>
                                                                        <Input
                                                                          placeholder="Name the drug"
                                                                          onBlur={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpersOfDrugs.replace(
                                                                              tableIndex,
                                                                              {
                                                                                ...tableData,
                                                                                nametheDrug:
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                              }
                                                                            );
                                                                          }}
                                                                          defaultValue={
                                                                            tableData.nametheDrug
                                                                          }
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Input
                                                                          placeholder="Strength"
                                                                          onBlur={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpersOfDrugs.replace(
                                                                              tableIndex,
                                                                              {
                                                                                ...tableData,
                                                                                strength:
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                              }
                                                                            );
                                                                          }}
                                                                          defaultValue={
                                                                            tableData.strength
                                                                          }
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Input
                                                                          placeholder="Frequency taken"
                                                                          onBlur={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpersOfDrugs.replace(
                                                                              tableIndex,
                                                                              {
                                                                                ...tableData,
                                                                                frequencyTaken:
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                              }
                                                                            );
                                                                          }}
                                                                          defaultValue={
                                                                            tableData.frequencyTaken
                                                                          }
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Popconfirm
                                                                          title="Are you sure ?"
                                                                          onConfirm={() =>
                                                                            arrayHelpersOfDrugs.remove(
                                                                              tableIndex
                                                                            )
                                                                          }
                                                                          onCancel={
                                                                            cancel
                                                                          }
                                                                          okText="Yes"
                                                                          cancelText="No"
                                                                        >
                                                                          <Button
                                                                            icon={
                                                                              <DeleteOutlined
                                                                                className={
                                                                                  style.delIconInner
                                                                                }
                                                                                style={{
                                                                                  fontSize:
                                                                                    "16px",
                                                                                  color:
                                                                                    "red",
                                                                                }}
                                                                              />
                                                                            }
                                                                            size="middle"
                                                                            onClick={() => {
                                                                              console.log();
                                                                            }}
                                                                            className="mr-2 mb-n5"
                                                                          />
                                                                        </Popconfirm>
                                                                      </td>
                                                                    </tr>
                                                                  </>
                                                                )
                                                              )}
                                                            </tbody>
                                                          </table>
                                                          <Button
                                                            type="dashed"
                                                            className="mt-3"
                                                            onClick={() => {
                                                              arrayHelpersOfDrugs.push(
                                                                {
                                                                  nametheDrug:
                                                                    "",
                                                                  strength: "",
                                                                  frequencyTaken:
                                                                    "",
                                                                }
                                                              );
                                                            }}
                                                          >
                                                            Add New
                                                          </Button>
                                                        </>
                                                      )}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        if (
                                          personalHealthHistoryData.type ===
                                          "table" &&
                                          personalHealthHistoryData.uniqueId ===
                                          "allergiesTable"
                                        ) {
                                          return (
                                            <div key={crypto.randomBytes(16).toString('hex')}>
                                              <div className="col-md-12 mt-3 ml-n3">
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <Title
                                                      level={4}
                                                      className="mt-4"
                                                    >
                                                      {
                                                        personalHealthHistoryData.heading
                                                      }
                                                    </Title>
                                                  </div>
                                                  <div className="col-md-12">
                                                    <FieldArray
                                                      name={`personalHealthHistory[${index}].table.answer`}
                                                      render={(
                                                        arrayHelpersOfDrugs
                                                      ) => (
                                                        <>
                                                          <table className="table table-striped">
                                                            <thead>
                                                              <tr>
                                                                <th scope="col">
                                                                  Name the drug
                                                                </th>
                                                                <th scope="col">
                                                                  Reaction you
                                                                  had
                                                                </th>
                                                                <th scope="col">
                                                                  Action
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {personalHealthHistoryData.table.answer.map(
                                                                (
                                                                  tableData,
                                                                  tableIndex
                                                                ) => (
                                                                  <>
                                                                    <tr
                                                                      key={crypto.randomBytes(16).toString('hex')}
                                                                    >
                                                                      <td>
                                                                        <Input
                                                                          placeholder="Name the drug"
                                                                          onBlur={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpersOfDrugs.replace(
                                                                              tableIndex,
                                                                              {
                                                                                ...tableData,
                                                                                nametheDrug:
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                              }
                                                                            );
                                                                          }}
                                                                          defaultValue={
                                                                            tableData.nametheDrug
                                                                          }
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Input
                                                                          placeholder="Reaction you had"
                                                                          onBlur={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpersOfDrugs.replace(
                                                                              tableIndex,
                                                                              {
                                                                                ...tableData,
                                                                                reactionYouHad:
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                              }
                                                                            );
                                                                          }}
                                                                          defaultValue={
                                                                            tableData.reactionYouHad
                                                                          }
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Popconfirm
                                                                          title="Are you sure ?"
                                                                          onConfirm={() =>
                                                                            arrayHelpersOfDrugs.remove(
                                                                              tableIndex
                                                                            )
                                                                          }
                                                                          onCancel={
                                                                            cancel
                                                                          }
                                                                          okText="Yes"
                                                                          cancelText="No"
                                                                        >
                                                                          <Button
                                                                            icon={
                                                                              <DeleteOutlined
                                                                                className={
                                                                                  style.delIconInner
                                                                                }
                                                                                style={{
                                                                                  fontSize:
                                                                                    "16px",
                                                                                  color:
                                                                                    "red",
                                                                                }}
                                                                              />
                                                                            }
                                                                            size="middle"
                                                                            onClick={() => {
                                                                              console.log();
                                                                            }}
                                                                            className="mr-2 mb-n5"
                                                                          />
                                                                        </Popconfirm>
                                                                      </td>
                                                                    </tr>
                                                                  </>
                                                                )
                                                              )}
                                                            </tbody>
                                                          </table>
                                                          <Button
                                                            type="dashed"
                                                            className="mb-5  mt-3"
                                                            onClick={() => {
                                                              arrayHelpersOfDrugs.push(
                                                                {
                                                                  nametheDrug:
                                                                    "",
                                                                  reactionYouHad:
                                                                    "",
                                                                }
                                                              );
                                                            }}
                                                          >
                                                            Add New
                                                          </Button>
                                                        </>
                                                      )}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        return "";
                                      }
                                    )}
                                  </div>
                                )}
                              />
                              <div className="row ml-1 pb-5">
                                <Button
                                  size="large"
                                  style={{ width: 110 }}
                                  htmlType="button"
                                  className="ml-2 mr-3"
                                  onClick={() => setCurrent(0)}
                                >
                                  Previous
                                </Button>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  size="large"
                                  style={{ width: 110 }}
                                  className="ml-n1"
                                >
                                  Next
                                </Button>

                              </div>
                            </Form>
                          )}
                        />
                      </Card>
                    ) : current === 2 ? (
                      <Card title="Family Health" className="mt-4">
                        <Formik
                          enableReinitialize
                          initialValues={
                            healthHistoryModule?.familyHealthHistory
                              ? {
                                familyHealthHistory:
                                  healthHistoryModule?.familyHealthHistory,
                              }
                              : {
                                familyHealthHistory:
                                  FAMILY_HEALTH_DATA?.familyHealthHistory,
                              }
                          }
                          onSubmit={(values) => {
                            addPatientHealthHistory(
                              "Family Health History",
                              values
                            );
                          }}
                          render={({ values, setFieldValue }) => (
                            <Form>
                              {console.log("values", values)}
                              <FieldArray
                                name="familyHealthHistory"
                                render={(familyHealthHistoryHelper) => (
                                  <>
                                    {values.familyHealthHistory?.map(
                                      (familyHealthHistoryData, index) => {
                                        console.log(
                                          "familyHealthHistoryData: ",
                                          familyHealthHistoryData
                                        );
                                        if (
                                          familyHealthHistoryData.type ===
                                          "tableWithInput"
                                        ) {
                                          return (
                                            <>
                                              <div className="col-md-12 mt-3 ml-n3">
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <Title level={4}>
                                                      Family Details
                                                    </Title>
                                                  </div>
                                                  <div className="col-md-12">
                                                    <FieldArray
                                                      name={`familyHealthHistory[${index}].relationTable.answer`}
                                                      render={(
                                                        familyHealthHistoryRelationTable
                                                      ) => (
                                                        <>
                                                          <table className="table table-striped">
                                                            <thead>
                                                              <tr>
                                                                <th scope="col">
                                                                  Relation
                                                                </th>
                                                                <th scope="col">
                                                                  Age
                                                                </th>
                                                                <th
                                                                  scope="col"
                                                                  className={
                                                                    style.textNormal
                                                                  }
                                                                >
                                                                  Significant
                                                                  Health
                                                                  Problems
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {familyHealthHistoryData.relationTable.answer.map(
                                                                (
                                                                  relation,
                                                                  indexOfRelationArray
                                                                ) => (
                                                                  <>
                                                                    <tr
                                                                      key={
                                                                        indexOfRelationArray
                                                                      }
                                                                    >
                                                                      <td>
                                                                        {
                                                                          relation.relationName
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        <Field
                                                                          placeholder="Age"
                                                                          type="text"
                                                                          maxLength={3}
                                                                          onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                              event.preventDefault();
                                                                            }
                                                                          }}
                                                                          onKeyDown={ (evt) => (evt.key === '-' || evt.key === 'e') && evt.preventDefault() }
                                                                          name={`familyHealthHistory[${index}].relationTable.answer[${indexOfRelationArray}].age`}
                                                                          />
                                                                      </td>
                                                                      <td>
                                                                        <Field
                                                                          placeholder="Significant health problems"
                                                                          name={`familyHealthHistory[${index}].relationTable.answer[${indexOfRelationArray}].significantHealth`}
                                                                        />
                                                                      </td>
                                                                    </tr>
                                                                  </>
                                                                )
                                                              )}
                                                            </tbody>
                                                          </table>
                                                        </>
                                                      )}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                        if (
                                          familyHealthHistoryData.type ===
                                          "table" &&
                                          familyHealthHistoryData.heading ===
                                          "Children"
                                        ) {
                                          return (
                                            <>
                                              <div className="col-md-12 mt-3 ml-n3">
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <Title
                                                      level={4}
                                                      className="mt-4"
                                                    >
                                                      {
                                                        familyHealthHistoryData.heading
                                                      }
                                                    </Title>
                                                  </div>
                                                  <div className="col-md-12">
                                                    <FieldArray
                                                      name={`familyHealthHistory[${index}].table.answer`}
                                                      render={(
                                                        arrayHelpersOfFamilyHealth
                                                      ) => (
                                                        <>
                                                          <table className="table table-striped">
                                                            <thead>
                                                              <tr>
                                                                <th scope="col">
                                                                  Gender
                                                                </th>
                                                                <th scope="col">
                                                                  Age
                                                                </th>
                                                                <th
                                                                  scope="col"
                                                                  className={
                                                                    style.textNormal
                                                                  }
                                                                >
                                                                  Significant
                                                                  Health
                                                                  Problems
                                                                </th>
                                                                <th scope="col">
                                                                  Action
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {familyHealthHistoryData.table.answer.map(
                                                                (
                                                                  tableData,
                                                                  tableIndex
                                                                ) => (
                                                                  <>
                                                                    <tr
                                                                      key={
                                                                        tableIndex
                                                                      }
                                                                    >
                                                                      <td>
                                                                        <Radio.Group
                                                                          value={
                                                                            tableData.Gender
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpersOfFamilyHealth.replace(
                                                                              tableIndex,
                                                                              {
                                                                                ...tableData,
                                                                                Gender:
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                              }
                                                                            );
                                                                          }}
                                                                        >
                                                                          <Radio value="male">
                                                                            Male
                                                                          </Radio>
                                                                          <Radio value="female">
                                                                            Female
                                                                          </Radio>
                                                                        </Radio.Group>
                                                                      </td>
                                                                      <td>
                                                                        <Field
                                                                        placeholder="Age"
                                                                          type="text"
                                                                          maxLength={3}
                                                                          onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                              event.preventDefault();
                                                                            }
                                                                          }}
                                                                          onKeyDown={ (evt) => (evt.key === '-' || evt.key === 'e') && evt.preventDefault() }
                                                                          name={`familyHealthHistory[${index}].table.answer[${tableIndex}].Age`}
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Field
                                                                          placeholder="Significant health problems"
                                                                          name={`familyHealthHistory[${index}].table.answer[${tableIndex}].SignificantHealthProblems`}
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Popconfirm
                                                                          title="Are you sure ?"
                                                                          onConfirm={() =>
                                                                            arrayHelpersOfFamilyHealth.remove(
                                                                              tableIndex
                                                                            )
                                                                          }
                                                                          onCancel={
                                                                            cancel
                                                                          }
                                                                          okText="Yes"
                                                                          cancelText="No"
                                                                        >
                                                                          <Button
                                                                            icon={
                                                                              <DeleteOutlined
                                                                                className={
                                                                                  style.delIconInner
                                                                                }
                                                                                style={{
                                                                                  fontSize:
                                                                                    "16px",
                                                                                  color:
                                                                                    "red",
                                                                                }}
                                                                              />
                                                                            }
                                                                            size="middle"
                                                                            onClick={() => {
                                                                              console.log();
                                                                            }}
                                                                            className="mr-2 mb-n5"
                                                                          />
                                                                        </Popconfirm>
                                                                      </td>
                                                                    </tr>
                                                                  </>
                                                                )
                                                              )}
                                                            </tbody>
                                                          </table>
                                                          <Button
                                                            type="dashed"
                                                            className="mt-3"
                                                            onClick={() => {
                                                              arrayHelpersOfFamilyHealth.push(
                                                                {
                                                                  Age: "",
                                                                  SignificantHealthProblems:
                                                                    "",
                                                                  Gender: "",
                                                                }
                                                              );
                                                            }}
                                                          >
                                                            Add New
                                                          </Button>
                                                        </>
                                                      )}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                        if (
                                          familyHealthHistoryData.type ===
                                          "table" &&
                                          familyHealthHistoryData.heading ===
                                          "Sibling"
                                        ) {
                                          return (
                                            <>
                                              <div className="col-md-12 mt-3 ml-n3">
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <Title
                                                      level={4}
                                                      className="mt-4"
                                                    >
                                                      {
                                                        familyHealthHistoryData.heading
                                                      }
                                                    </Title>
                                                  </div>
                                                  <div className="col-md-12">
                                                    <FieldArray
                                                      name={`familyHealthHistory[${index}].table.answer`}
                                                      render={(
                                                        arrayHelpersOfFamilyHealth
                                                      ) => (
                                                        <>
                                                          <table className="table table-striped">
                                                            <thead>
                                                              <tr>
                                                                <th scope="col">
                                                                  Gender
                                                                </th>
                                                                <th scope="col">
                                                                  Age
                                                                </th>
                                                                <th
                                                                  scope="col"
                                                                  className={
                                                                    style.textNormal
                                                                  }
                                                                >
                                                                  Significant
                                                                  Health
                                                                  Problems
                                                                </th>
                                                                <th scope="col">
                                                                  Action
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {familyHealthHistoryData.table.answer.map(
                                                                (
                                                                  tableData,
                                                                  tableIndex
                                                                ) => (
                                                                  <>
                                                                    <tr
                                                                      key={
                                                                        tableIndex
                                                                      }
                                                                    >
                                                                      <td>
                                                                        <Radio.Group
                                                                          value={
                                                                            tableData?.Gender
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpersOfFamilyHealth.replace(
                                                                              tableIndex,
                                                                              {
                                                                                ...tableData,
                                                                                Gender:
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                              }
                                                                            );
                                                                          }}
                                                                        >
                                                                          <Radio value="male">
                                                                            Male
                                                                          </Radio>
                                                                          <Radio value="female">
                                                                            Female
                                                                          </Radio>
                                                                        </Radio.Group>
                                                                      </td>
                                                                      <td>
                                                                        <Field
                                                                          placeholder="Age"
                                                                          type="text"
                                                                          maxLength={3}
                                                                          onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                              event.preventDefault();
                                                                            }
                                                                          }}
                                                                          onKeyDown={ (evt) => (evt.key === '-' || evt.key === 'e') && evt.preventDefault() }
                                                                          name={`familyHealthHistory[${index}].table.answer[${tableIndex}].Age`}
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Field
                                                                          placeholder="Significant health problems"
                                                                          name={`familyHealthHistory[${index}].table.answer[${tableIndex}].SignificantHealthProblems`}
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Popconfirm
                                                                          title="Are you sure ?"
                                                                          onConfirm={() =>
                                                                            arrayHelpersOfFamilyHealth.remove(
                                                                              tableIndex
                                                                            )
                                                                          }
                                                                          onCancel={
                                                                            cancel
                                                                          }
                                                                          okText="Yes"
                                                                          cancelText="No"
                                                                        >
                                                                          <Button
                                                                            icon={
                                                                              <DeleteOutlined
                                                                                className={
                                                                                  style.delIconInner
                                                                                }
                                                                                style={{
                                                                                  fontSize:
                                                                                    "16px",
                                                                                  color:
                                                                                    "red",
                                                                                }}
                                                                              />
                                                                            }
                                                                            size="middle"
                                                                            onClick={() => {
                                                                              console.log();
                                                                            }}
                                                                            className="mr-2 mb-n5"
                                                                          />
                                                                        </Popconfirm>
                                                                      </td>
                                                                    </tr>
                                                                  </>
                                                                )
                                                              )}
                                                            </tbody>
                                                          </table>
                                                          <Button
                                                            type="dashed"
                                                            className="mt-3"
                                                            onClick={() => {
                                                              arrayHelpersOfFamilyHealth.push(
                                                                {
                                                                  Age: "",
                                                                  SignificantHealthProblems:
                                                                    "",
                                                                  Gender: "",
                                                                }
                                                              );
                                                            }}
                                                          >
                                                            Add New
                                                          </Button>
                                                        </>
                                                      )}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                        return "";
                                      }
                                    )}
                                  </>
                                )}
                              />
                              <div className="row ml-1 mt-5 ml-n2 pb-5">
                                <Button
                                  size="large"
                                  style={{ width: 110 }}
                                  htmlType="button"
                                  className="ml-2 mr-3"
                                  onClick={() => setCurrent(1)}
                                >
                                  Previous
                                </Button>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  size="large"
                                  style={{ width: 110 }}
                                  className="ml-n1"
                                >
                                  Next
                                </Button>

                              </div>
                            </Form>
                          )}
                        />
                      </Card>
                    ) : current === 3 ? (
                      <Card title="Other Problems" className="mt-4">
                        <div className="col-md-12 mt-3 ml-n3">
                          <div className="row">
                            <div className="col-md-12">
                              <Title style={{ paddingBottom: 20 }} level={4}>
                                Mental Health
                              </Title>
                            </div>
                          </div>
                        </div>
                        <Formik
                          enableReinitialize
                          initialValues={
                            healthHistoryModule?.otherProblemsHistory
                              ? {
                                otherProblemsHistory:
                                  healthHistoryModule?.otherProblemsHistory,
                              }
                              : {
                                otherProblemsHistory:
                                  OTHER_HEALTH_DATA?.otherProblemsHistory,
                              }
                          }
                          onSubmit={(values) => {
                            addPatientHealthHistory(
                              "Other Problems History",
                              values
                            );
                          }}
                          render={({ values, setFieldValue }) => (
                            <Form>
                              {console.log("values", values)}
                              <FieldArray
                                name="otherProblemsHistory"
                                render={(familyHealthHistoryHelper) => (
                                  <>
                                    {values.otherProblemsHistory.map(
                                      (otherProblemsHistoryData, index) => {
                                        if (
                                          otherProblemsHistoryData.type ===
                                          "checkboxes"
                                        ) {
                                          return (
                                            <FieldArray
                                              name={`otherProblemsHistory[${index}].checkboxes`}
                                              render={(
                                                otherProblemsHistoryHelper
                                              ) => (
                                                <>
                                                  {otherProblemsHistoryData.checkboxes.map(
                                                    (
                                                      checkboxData,
                                                      checkBoxIndex
                                                    ) => (
                                                      <>
                                                        <div
                                                          className="row"
                                                          style={{
                                                            display: "flex",
                                                            alignContent:
                                                              "center",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                        >
                                                          <div className="col-12 col-sm-6 col-md-8 col-lg-9 col-xl-9 mt-2">
                                                            <Text>
                                                              {
                                                                checkboxData.label
                                                              }
                                                            </Text>
                                                          </div>
                                                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3  mt-2">
                                                            <Radio.Group
                                                              className=""
                                                              onChange={(e) => {
                                                                console.log(
                                                                  "e: ",
                                                                  e.target.value
                                                                );
                                                                e.preventDefault();
                                                                otherProblemsHistoryHelper.replace(
                                                                  checkBoxIndex,
                                                                  {
                                                                    ...checkboxData,
                                                                    answer:
                                                                      e.target
                                                                        .value,
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              <Radio.Button
                                                                style={
                                                                  checkboxData.answer ===
                                                                    "yes"
                                                                    ? {
                                                                      backgroundColor:
                                                                        "red",
                                                                      color:
                                                                        "#ffff",
                                                                    }
                                                                    : {}
                                                                }
                                                                value="yes"
                                                              >
                                                                Yes
                                                              </Radio.Button>
                                                              <Radio.Button
                                                                style={
                                                                  checkboxData.answer ===
                                                                    "no"
                                                                    ? {
                                                                      backgroundColor:
                                                                        "#689f38",
                                                                      color:
                                                                        "#ffff",
                                                                    }
                                                                    : {}
                                                                }
                                                                value="no"
                                                              >
                                                                No
                                                              </Radio.Button>
                                                            </Radio.Group>
                                                          </div>
                                                        </div>
                                                        <hr />
                                                      </>
                                                    )
                                                  )}
                                                </>
                                              )}
                                            />
                                          );
                                        }
                                        return "";
                                      }
                                    )}
                                  </>
                                )}
                              />

                              <div className="row ml-1 pb-5">
                                <Button
                                  size="large"
                                  style={{ width: 110 }}
                                  htmlType="button"
                                  className="ml-2 mr-3"
                                  onClick={() => setCurrent(2)}
                                >
                                  Previous
                                </Button>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  size="large"
                                  style={{ width: 110 }}
                                  className="ml-n1"
                                >
                                  Next
                                </Button>

                              </div>
                            </Form>
                          )}
                        />
                      </Card>
                    ) : (
                      <Card title="Consent Form" className="mt-4">

                        <Card type="inner" title="Introduction">
                          <p>
                            Telehealth involves the use of electronic
                            communications to enable providers at different
                            locations to share individual client information
                            for the purpose of improving client care.
                            Providers may include primary care practitioners,
                            specialists, and/or subspecialists. The
                            information may be used for diagnosis, therapy,
                            follow-up and/or education, and may include any of
                            the following:
                          </p>
                          <ul>
                            <li>Client health records</li>
                            <li>Live two-way audio and video</li>
                            <li>
                              Output data from health devices, sound and video
                              files
                            </li>
                          </ul>
                          <p>
                            Electronic systems used will incorporate network
                            and software security protocols to protect the
                            confidentiality of client identification and
                            imaging data and will include measures to
                            safeguard the data and to ensure its integrity
                            against intentional or unintentional corruption.{" "}
                          </p>
                        </Card>
                        <Card
                          style={{ marginTop: 16 }}
                          type="inner"
                          title="Expected Benefits"
                        >
                          <ul>
                            <li>
                              Improved access to care by enabling a client to
                              remain in his/her provider's office (or at a
                              remote site) while the providers obtains test
                              results and consults from practitioners at
                              distant/other sites.
                            </li>
                            <li>
                              More efficient client evaluation and management.
                            </li>
                            <li>
                              Obtaining expertise of a distant specialist.
                            </li>
                          </ul>
                        </Card>
                        <Card
                          style={{ marginTop: 16 }}
                          type="inner"
                          title="Possible Risks"
                        >
                          <p>
                            There are potential risks associated with the use
                            of telehealth. These risks include, but may not be
                            limited to:{" "}
                          </p>
                          <ul>
                            <li>
                              Improved access to care by enabling a client to
                              remain in his/her provider's office (or at a
                              remote site) while the providers obtains test
                              results and consults from practitioners at
                              distant/other sites.
                            </li>
                            <li>
                              Delays in evaluation and treatment could occur
                              due to deficiencies or failures of the equipment
                            </li>
                            <li>
                              In very rare instances, security protocols could
                              fail, causing a breach of privacy of personal
                              health information.
                            </li>
                            <li>
                              In rare cases, a lack of access to complete
                              health records may result in interactions or
                              allergic reactions or other judgment errors.
                            </li>
                          </ul>
                        </Card>
                        <Card
                          style={{ marginTop: 16 }}
                          type="inner"
                          title="By signing this form, I understand the following"
                        >
                          <ol>
                            <li>
                              I understand that the laws that protect privacy
                              and the confidentiality of health information
                              also apply to telehealth, and that no
                              information obtained in the use of telehealth
                              which identifies me will be disclosed to
                              researchers or other entities without my
                              consent.
                            </li>
                            <li>
                              I understand that I have the right to withhold
                              or withdraw my consent to the use of telehealth
                              in the course of my care at any time, without
                              affecting my right to future care or treatment.
                            </li>
                            <li>
                              I understand that I have the right to inspect
                              all information obtained and recorded in the
                              course of a telehealth interaction, and may
                              receive copies of this information for a
                              reasonable fee.
                            </li>
                            <li>
                              I understand that a variety of alternative
                              methods of health care may be available to me,
                              and that I may choose one or more of these at
                              any time. My provider has explained the
                              alternatives to my satisfaction.
                            </li>
                            <li>
                              I understand that telehealth may involve
                              electronic communication of my personal health
                              information to other practitioners who may be
                              located in other areas, including out of state.
                            </li>
                            <li>
                              I understand that it is my duty to inform my
                              provider of electronic interactions regarding my
                              care that I may have with other healthcare
                              providers.
                            </li>
                            <li>
                              I understand that I may expect the anticipated
                              benefits from the use of telehealth in my care,
                              but that no results can be guaranteed or
                              assured.
                            </li>
                          </ol>
                        </Card>
                        <Card
                          style={{ marginTop: 16 }}
                          type="inner"
                          title="Patient consent to the use of telehealth"
                        >
                          {userType == "familyMember" ? 
                          <p>
                            I, {familyMemeberData?.userName} have read and understand
                            the information provided above regarding
                            telehealth, have discussed it with my provider or
                            such assistants as may be designated, and all of
                            my questions have been answered to my
                            satisfaction. I hereby give my informed consent
                            for the use of telehealth in my care.{" "}
                          </p> : 
                           <p>
                           I, {userData?.FirstName} {userData?.LastName}, have read and understand
                           the information provided above regarding
                           telehealth, have discussed it with my provider or
                           such assistants as may be designated, and all of
                           my questions have been answered to my
                           satisfaction. I hereby give my informed consent
                           for the use of telehealth in my care.{" "}
                         </p> }


                          {errorSignature ?
                            <span className={style.errorMessage}>Signature is Required</span> :
                            null}
                            
                          <Formik
                            initialValues={
                              
                              healthHistoryModule?.consentForm?.consentForm
                                ? {
                                  // consentForm:
                                  //   // healthHistoryModule?.consentForm,
                                  //   healthHistoryModule?.consentForm?.consentForm,
                                  // consentForm:{
                                  //   consentForm:{
                                      signatureUrl: healthHistoryModule?.consentForm?.consentForm?.signatureUrl,
                                      signatureDate: healthHistoryModule?.consentForm?.consentForm?.signatureDate,
                                      authorizedPerson: healthHistoryModule?.consentForm?.consentForm?.authorizedPerson,
                                  //   }
                                  // }

                                }
                                : {
                                  signatureDate: "",
                                  authorizedPerson: "",
                                }
                                
                            }
                            onSubmit={(values) => {
                              console.log(values,"vallluueeess")
                              values = {
                                // consentForm: {
                                //   consentForm: {
                                    signatureUrl: image,
                                    signatureDate: moment().format("YYYY-MM-DD"),
                                    authorizedPerson: values.authorizedPerson
                                //   }
                                // },
                              };

                              let dattt = {
                                 consentForm: {
                                  consentForm: {
                                    signatureUrl: values.signatureUrl,
                                    signatureDate: values.signatureDate,
                                    authorizedPerson: values.authorizedPerson
                                  }
                                 }
                              }
                              addPatientHealthHistory("Consent form", dattt);
                              console.log(image, "check imag");
                            }}
                            render={({
                              values,
                              setFieldValue,
                              handleChange,
                            }) => (
                              <Form>
                                <div>
                                  <div
                                    style={{
                                      overflow: "hidden",
                                      width: "250px",
                                    }}
                                    className="ml-0 border border-primary border-1 text-left"
                                  >
                                    <div
                                      // id={`unique500`}
                                      style={{
                                        overflow: "hidden",
                                        width: "300px",
                                      }}
                                    >
                                      {((values?.signatureUrl ===
                                        "" ||
                                        values?.signatureUrl ===
                                        null) || image == null)
                                        || edit

                                        ? (
                                          <CanvasDraw
                                            id="unique500"
                                            width="250px"
                                            ref={_sketch}
                                            brushColor="black"
                                            brushRadius={2}
                                            lazyRadius={2}
                                            canvasWidth={250}
                                            canvasHeight={150}
                                          />
                                        ) : (
                                          <div
                                            style={{
                                              overflow: "hidden",
                                              width: "250px",
                                              height: "150px",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {console.log(
                                              "consentForm ***",
                                              image
                                            )}
                                            <img
                                              src={
                                                image
                                                  ? image
                                                  : values?.signatureUrl || ""
                                              }
                                              width=" "
                                              alt=""
                                            />
                                          </div>
                                        )}
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      backgroundColor: "#246ec63b",
                                      width: 250,
                                      padding: 7,
                                    }}
                                  >
                                    <Button
                                      style={{ borderRadius: "100%" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (_sketch?.current) {
                                          _sketch?.current?.undo();
                                          // console.log(_sketch.current);
                                          setCanvass([]);
                                        }
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faUndo} />{" "}
                                    </Button>
                                    <div className="float-right">
                                      <Button
                                        style={{ borderRadius: "100%" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          _save();
                                        }}
                                      >
                                        {loading ? (
                                          <Spin />
                                        ) : (
                                          <FontAwesomeIcon icon={faSave} />
                                        )}
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  {/* <div className="col-sm-4">
                                    <div className="pt-2">
                                      <Text>Date:</Text>
                                      <DatePicker
                                        disabledDate={(current) => {
                                          let customDate =
                                            moment().format("YYYY-MM-DD");
                                          return (
                                            current &&
                                            current <
                                            moment(customDate, "YYYY-MM-DD")
                                          );
                                        }}
                                        className="w-100"
                                        name="signatureDate"
                                        onChange={(date, dateString) => {
                                          console.log(
                                            "dateString: ",
                                            dateString
                                          );
                                          setFieldValue(
                                            "signatureDate",
                                            dateString
                                          );
                                        }}
                                        value={moment(moment(values.consentForm.signatureDate).format('YYYY-MM-DD'))}
                                      />
                                    </div>
                                  </div> */}
                                  
                                  <div className="col-sm-12">
                                    <div className="w-100 pt-2">
                                      <Text>
                                        If authorized Signature, relationship
                                        to client:
                                      </Text>
                                    </div>
                                    
                                    <div className="form-group">
                                      <Field
                                        as="select"
                                        name="authorizedPerson"
                                        className="form-control"
                                      >
                                        <option value="Select Relationship" key="Select Relationship">
                                          Select Relationship
                                        </option>

                                        {relationShip.map((relation) => (
                                        <option value={relation.name}>
                                          {relation.name}
                                        </option>
                                      ))}
                                      </Field>
                                    </div>

                                    {/* <div>
                                      <Input
                                        placeholder="Relationship"
                                        name="authorizedPerson"
                                        onChange={handleChange}
                                      // value={
                                      //   values?.consentForm?.clientSignature
                                      // }
                                      />
                                    </div> */}
                                  </div>
                                  <div className="col-sm-2"></div>
                                </div>
                                <div className="row">
                                  <Button
                                    size="large"
                                    style={{ width: 110 }}
                                    htmlType="button"
                                    className="mt-5 ml-2 mr-2"
                                    onClick={() => setCurrent(3)}
                                  >
                                    Previous
                                  </Button>
                                  <Button
                                    disabled={image == null}
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    style={{ width: 110 }}
                                    className="mt-5 ml-2"
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </Form>
                            )}
                          />
                        </Card>

                      </Card>
                    )}
                  </>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>

      {openModal && (
        <SweetAlert
          warning
          title="do you want to save changes?"
          onConfirm={saveDetail}
          onCancel={onCancel}
          showCancel={true}
          // focusCancelBtn={true}
          // confirmBtnBsStyle="danger"
          confirmBtnText="Yes"
          cancelBtnText="No"
        />
      )}
      {/* <div className="footer">
        <Footer />
      </div> */}
    </div>
  );
};

export default MedicaldDetails;
